import "./HiringProcess.scss";
export const HiringProcess = ({ ComponentData }) => {
  return (
    <div className="hiring-container">
      <h2 className="title">{ComponentData?.title}</h2>
      <div className="step-cards-container">
        {ComponentData?.data?.map((item, index) => (
          <StepsCard key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

const StepsCard = ({ data }) => {
  const { title, description, image } = data;

  return (
    <div className="step-card">
      <div className="image-content">
        <img src={image} alt={title} className="step-image" />
      </div>
      <h3 className="title">{title}</h3>
      <p className="description">{description}</p>
    </div>
  );
};
