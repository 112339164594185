import Popup from "../popUp/popUp";
import crosssIcon from "../../../Assets/SVG/Close.svg";
import multipleTabSwitchWarning from "../../../Assets/SVG/multipleTabSwitchWarning.svg";
import tabSwitchWarning from "../../../Assets/SVG/tabSwitchWarning.svg";

const multipleTabSwitch = {
  title: "Termination",
  desc: "You seemed to have gone out of the our view multiple times Contact support team for a comeback plan.",
  button: "Okay",
};

const tabSwitch = {
  title: "Warning!",
  desc: " Whoa there, tab-hopper!!",
  desc2:
    " Stick to the test stage or risk a detour— quiz continuation at stake.",
  button: "Okay",
};

export const MultipleTabSwitchError = ({ setmultipleSwitchError }) => {
  const handleClick = () => {
    window.location.href = "/assessment/home";
  };
  return (
    <Popup>
      <div className="tabswitch-wrapper">
        {/* <div className="close" onClick={() => setmultipleSwitchError(false)}>
          <img src={crosssIcon} alt="" />
        </div> */}
        <div className="alert">
          <img src={multipleTabSwitchWarning} alt="" />
        </div>
        <div className="alert">{multipleTabSwitch?.title}</div>
        <div className="desc2">{multipleTabSwitch?.desc}</div>
        <div className="gotIt">
          <button onClick={() => handleClick(true)}>
            {multipleTabSwitch?.button}
          </button>
        </div>
      </div>
    </Popup>
  );
};

export const TabSwitchUi = ({ setViewTabSwitch }) => {
  return (
    <Popup>
      <div className="tabswitch-wrapper">
        <div className="close" onClick={() => setViewTabSwitch(false)}>
          <img src={crosssIcon} alt="" />
        </div>
        <div className="warning">
          <img src={tabSwitchWarning} alt="" />
        </div>
        <div className="alert">{tabSwitch?.title}</div>
        <div className="alert2">{tabSwitch?.desc}</div>
        <div className="desc">{tabSwitch?.desc2}</div>
        <div className="gotIt">
          <button onClick={() => setViewTabSwitch(false)}>
            {tabSwitch?.button}
          </button>
        </div>
      </div>
    </Popup>
  );
};
