import React from 'react';
import { SvgIcon } from '@mui/material';

const withGradient = (IconComponent) => (props) => {
  const gradientId = `grad-${Math.random()}`; // Generate unique gradient ID

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="15%" style={{ stopColor: '#062B6E', stopOpacity: 1 }} />
          <stop offset="60%" style={{ stopColor: '#0B52D4', stopOpacity: 1 }} />
          <stop offset="90%" style={{ stopColor: '#4859F3', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      {/* Pass the gradient fill directly to the path */}
      <IconComponent sx={{ fill: `url(#${gradientId})` }} />
    </SvgIcon>
  );
};

export default withGradient;
