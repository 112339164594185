export const candidateSubHeaderData = {
  topHeader: [
    { key: "Round1", value: "Technical Fundamental Test" },
    {
      key: "Round2",
      value: "Coding",
    },
    {
      key: "Round3",
      value: "HR Discussion",
    },
    {
      key: "Round4",
      value: "Practical Assignment",
      value2: "Case Study",
    },
    {
      key: "Round5",
      value: "Expert Discussion",
    },
  ],
};
