// import { useNavigate } from "react-router-dom";
import "./weOfferPage3.scss";
import circularArrow from "../../Assets/SVG/circle_and_arrow.svg";
import { useState } from "react";

export const WeOfferPage3 = ({ data }) => {
  // const navigate = useNavigate();

  // const handleRedirect = (link) => {
  //   navigate(link);
  // };

  return (
    <div className="we-offer-3">
      <div className="wrapper">
        <div className="heading">{data.header}</div>
        <div className="item">
          {data?.data?.map((item, index) => (
            <EachBoxContainer key={index} item={item} index={index} />
          ))}
        </div>
        {/* <div className="button-wrapper">
          <button onClick={() => handleRedirect(data?.button?.link)}>
            {data?.button?.text}
          </button>
        </div> */}
      </div>
    </div>
  );
};

const EachBoxContainer = ({ item, index }) => {
  const [isItemVisible, setIsItemVisible] = useState(false);

  const handleViewDesc = (descId, descStyle) => {
    let ele = document.getElementById(descId);
    ele.style.display = descStyle;
  };

  return (
    <div className="each-item" key={index}>
      <div className="item-img">
        <img src={item?.imgLink} alt="" />
      </div>
      <div className="title-and-arrow">
        <div className="item-title">{item?.title}</div>
        {isItemVisible && (
          <div
            className="view-arrow-main"
            onClick={() => {
              setIsItemVisible(false);
              handleViewDesc(`item-desc${index}`, "none");
            }}
          >
            <img src={circularArrow} alt="" />
          </div>
        )}
      </div>
      <div id={`item-desc${index}`} className="item-desc">
        {item?.desc}
      </div>
      {!isItemVisible && (
        <div
          className="view-arrow"
          onClick={() => {
            setIsItemVisible(true);
            handleViewDesc(`item-desc${index}`, "block");
          }}
        >
          <img src={circularArrow} alt="" />
        </div>
      )}
    </div>
  );
};
