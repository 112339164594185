import { Height } from '@mui/icons-material'
import { Stack,Card ,Box, Typography} from '@mui/material'
import React from 'react'
import starImage from "../../../Assets/client_tour/ClientStar.svg"
const cardsData=[
    {
        content:'Looking for a frontend developer specializing in Javascript.',

    },

    {
        content:'Looking for a backend developer specializing in Java. Candidates should have a solid grasp of  Spring boot',

    },
    {
        content:'Looking for a full stack developer specializing in MySql, AngularJs. Candidates should have a solid grasp of  SQl.'
    }
]
const ClientSuggestionCards = ({handleSearchGetResponse}) => {
  const itemClicked=(item)=>{
    handleSearchGetResponse(item);
  }
  return (
    <Stack sx={{marginTop:'40px',display:'flex',flexDirection:'row',gap:'20px'}}>

        {
            cardsData.map((card)=>(
              <Card 
              onClick={()=>{
                itemClicked(card?.content || "")
              }}
              sx={{
                height: '153px', 
                width: '214px', 
                borderRadius: '10px', 
                padding: '0px 0px 24px 24px',
                cursor: 'pointer', 
                position: 'relative',
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'space-between' // Ensure space between content and image
              }}>
                <Box sx={{ position: 'relative', flexGrow: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                      margin: '20px 10px 0px 0px', // Avoid text overlap with the image
                      overflow: 'hidden', // Prevent overflow of text
                      textOverflow: 'ellipsis', // Add ellipsis for overflowed text
                    }}
                  >
                    {card?.content || ""}
                  </Typography>
              
                  {/* Image in the top-right corner */}
                  <img
                    src={starImage}
                    alt="Your Image"
                    style={{
                      height: '30px',
                      width: '36px',
                      position: 'absolute',
                      top: '5px',
                      right: '0px', // Ensure it's in the top-right corner
                    }}
                  />
                </Box>
              </Card>
              

            ))
        }


    </Stack>
  )
}

export default ClientSuggestionCards