import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./BackButton.scss";

export const BackButton = ({ handleBackButton, name }) => {
  return (
    <span className="back-button" onClick={handleBackButton}>
      <ArrowBackIosIcon style={{ width: 24, height: 24 }}></ArrowBackIosIcon>
      <span style={{ display: "flex" }}>{name}</span>
    </span>
  );
};
