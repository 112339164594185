import Popup from "../Common/popUp/popUp";
import "./HoldOnR2.scss";
// import timer from "../../Assets/SVG/timer.svg";
// import holdIcon from "../../Assets/SVG/round2Hold.svg";
import holdIconSearch from "../../Assets/SVG/Search_hold_r2.gif";

export const HoldOnR2 = () => {
  return (
    <Popup>
      <div className="holdonWrapper">
        <div className="first">
          <img src={holdIconSearch} alt="" />
        </div>
        <div className="title">Hold On...</div>
        <div className="desc">
          {/* It is taking time to evaluate your results,
          <span> please wait for some time.. </span> */}
          Hold tight! Our digital wizards are decoding your results spell.
          Almost there!
        </div>
        {/* <div className="okay">
          <button onClick={() => (window.location.href = "/assessment/home")}>
            Okay
          </button>
        </div> */}
      </div>
    </Popup>
  );
};
