import { useNavigate } from "react-router-dom";
import "./topLogo.scss";

export const TopLogo = () => {
  const navigate = useNavigate();
  return (
    <div className="topLogoContainer">
      <div className="topLogoWrapper">
        <img
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          src="./images/zeero-logo.svg"
          alt="zeero"
        />
      </div>
    </div>
  );
};
