// educationData.js

export const education = [
    {
      degree: "B-Tech in Computer Science & Engineering",
      institution: "Ajay Kumar Garg Engineering College",
      startDate: "",
      endDate: "",
      location: "",
      percentage: "",
      cgpa: "9.0"
    },
    {
        degree: "B-Tech in Computer Science & Engineering",
        institution: "Ajay Kumar Garg Engineering College",
        startDate: "",
        endDate: "",
        location: "",
        percentage: "",
        cgpa: "9.0"
      },
      {
        degree: "B-Tech in Computer Science & Engineering",
        institution: "Ajay Kumar Garg Engineering College",
        startDate: "",
        endDate: "",
        location: "",
        percentage: "",
        cgpa: "9.0"
      }
  ];
  