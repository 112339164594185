export const clientQues = [
  {
    ques: "What is the hiring process at Zeero?",
    ans: "At Zeero, a candidate goes through an extensive vetting process which includes technical and interpersonal skills evaluation using automated tests and one on one interviews.",
  },
  {
    ques: "Why should I hire remote developers from Zeero?",
    ans: "Developers at Zeero go through an extensive evaluation process before they are offered to the clients. We ensure that we offer high-quality, cost-effective, and super-productive software developers that are ready to be deployed in the project.",
  },
  {
    ques: "How long does it take to hire a Zeero developer?",
    ans: "Typically, it takes less than two weeks to onboard a zeero developer.",
  },
  {
    ques: "What types of engagements does Zeero offer (full-time, contract, etc.)?",
    ans: "We offer all types of engagement according to our client's needs.",
  },
];
