// import { useNavigate } from "react-router-dom";
import "./BackToHome.jsx";
import blurLeftArrow from "../../Assets/SVG/blueLeftArrow.svg";
import "./BackToHome.scss";

export const BackToHome = () => {
  // const navigate = useNavigate();
  return (
    // <div className="back-to-home" onClick={() => navigate("/assessment/home")}>
    <div
      className="back-to-home"
      onClick={() => (window.location.href = "/assessment/home")}
    >
      <div>
        <img src={blurLeftArrow} alt="" />
      </div>{" "}
      <div>Back to Home</div>
    </div>
  );
};
