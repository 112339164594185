// src/assets/imageMapping.js
import ProductTourIllustration1 from '../../../Assets/client_tour/ProductTourIllustration1.svg';

import ProductTourIllustration3 from '../../../Assets/client_tour/ProductTourIllustration3.svg';
import ProductTourIllustration4 from '../../../Assets/client_tour/ProductTourIllustration4.svg';
import ProductTourIllustration2 from "../../../Assets/client_tour/ProductTourIllustration2.svg"
const imageMapping = {
  'ProductTourIllustration1.svg': ProductTourIllustration1,
  'ProductTourIllustration2.svg': ProductTourIllustration2,
  'ProductTourIllustration3.svg': ProductTourIllustration3,
  'ProductTourIllustration4.svg': ProductTourIllustration4,
};

export default imageMapping;
