import React, { useEffect, useRef, useState } from 'react';
import "./CandidateProfilesCardMobile.scss";
import mask_matched_profiles from './../CandidateCard/CandidateCardListWithBlur/CandidateCardListData';
import CandidateClientPageCard from './CandidateClientPageCard';

const BACKGROUNDS = [
  "linear-gradient(177deg, rgba(145, 199, 141, 0.56) -13.49%, rgba(6, 188, 1, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(154, 169, 219, 0.56) -13.49%, rgba(33, 60, 152, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(156, 44, 243, 0.56) -13.49%, rgba(58, 73, 249, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(207, 188, 139, 0.56) -13.49%, rgba(178, 133, 11, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(253, 148, 255, 0.56) -13.49%, rgba(157, 3, 160, 0.56) 109.75%)",
];

const CandidateProfilesCardMobile = () => {
  const [position, setPosition] = useState(-100); // Start at the first original card
  const [transition, setTransition] = useState(true);

  // Cloned array for seamless circular behavior
  const clonedProfiles = [
    mask_matched_profiles[mask_matched_profiles.length - 1],
    ...mask_matched_profiles,
    mask_matched_profiles[0]
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition(prevPosition => {
        const nextPosition = prevPosition - 100;

        // Reset to original first card without transition if reaching the cloned last card
        if (nextPosition === -100 * (clonedProfiles.length - 1)) {
          setTimeout(() => {
            setTransition(false);
            setPosition(-100);
          }, 500); // Wait for the transition to finish
          return nextPosition;
        }

        // Normal sliding
        setTransition(true);
        return nextPosition;
      });
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [clonedProfiles.length]);

  // Reset transition after jumping back to the first card
  useEffect(() => {
    if (!transition) {
      setTimeout(() => setTransition(true), 50); // Restore transition after a small delay
    }
  }, [transition]);

  return (
    <div className="carousel-container">
      <div
        className="carousel"
        style={{
          transform: `translateX(${position}%)`,
          transition: transition ? 'transform 0.5s ease-in-out' : 'none',
        }}
      >
        {clonedProfiles.map((candidate, index) => (
          <div key={index} className="carousel-card">
            <CandidateClientPageCard
              candidate={candidate}
              background={BACKGROUNDS[index % BACKGROUNDS.length]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CandidateProfilesCardMobile;
