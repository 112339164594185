// ImageSet.js

import image1 from "../Assets/SVG/round2/percentage/Image1.svg";
import image2 from "../Assets/SVG/round2/percentage/Image2.svg";
import image3 from "../Assets/SVG/round2/percentage/Image3.svg";
import image4 from "../Assets/SVG/round2/percentage/Image4.svg";
import image5 from "../Assets/SVG/round2/percentage/Image5.svg";
import image6 from "../Assets/SVG/round2/percentage/Image6.svg";

export const percentageImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
];
