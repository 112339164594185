import { useNavigate } from "react-router-dom";
import "./RightImage.scss";

export const RightImage = ({ ComponentData, background = "" }) => {
  const navigate = useNavigate();

  const handleClick = (buttonUrl) => {
    navigate(buttonUrl);
  };
  // console.log("background=>", background);
  return (
    <div
      className="right-container small-right-container"
      style={{
        backgroundColor: background ? background : "",
      }}
    >
      {ComponentData?.heading && (
        <div className="page-heading">{ComponentData?.heading}</div>
      )}
      {ComponentData?.data?.map((content, index) => (
        <div className="wrapper" key={index}>
          <div className="left-side">
            <div
              className="tagline"
              dangerouslySetInnerHTML={{ __html: content?.about }}
            />
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: content?.title }}
            />
            <div className="desc">{content?.desc}</div>
            <div className="button-wrapper">
              {content?.buttons.map((ele, index) => (
                <button
                  style={{
                    background: ele?.backgroundColor
                      ? ele?.backgroundColor
                      : "",
                  }}
                  key={index}
                  onClick={() => handleClick(ele?.link)}
                >
                  {ele?.title}
                </button>
              ))}
            </div>
          </div>
          <div className="right-image">
            <img src={content?.imgLink} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};
