import "./WhatweDoNew.scss";
import blurLeftArrow from "../../Assets/SVG/blueLeftArrow.svg";
import { useState } from "react";

export const WhatweDoNew = ({ ComponentData }) => {
  const [readMore, setReadmore] = useState(false);

  const handleReadmore = () => {
    let ele = document.getElementById("itemDesc");
    ele.style.height = "fit-content";
    setReadmore(true);
  };
  return (
    <div className="WhatweDoNew">
      <div className="heading">{ComponentData?.heading}</div>
      <div
        className="desc"
        dangerouslySetInnerHTML={{ __html: ComponentData?.desc }}
      />
      <div className="content-container">
        <div
          className="background-image-div"
          style={{ backgroundImage: `url(${ComponentData?.backgroundImage})` }}
        >
          {" "}
        </div>
        <div className="details">
          <div
            id="itemDesc"
            className="desc"
            dangerouslySetInnerHTML={{ __html: ComponentData?.desc2 }}
          />
          {!readMore && (
            <div className="read-more" onClick={() => handleReadmore()}>
              <div>Read More</div>
              <div>
                <img src={blurLeftArrow} alt="" />
              </div>
            </div>
          )}
          {ComponentData?.illustration && (
            <div className="illustration">
              <img src={ComponentData?.illustration} alt="" />
            </div>
          )}
        </div>
        <div className="right-filler">
          <img src={ComponentData?.rightFiller} alt="" />
        </div>
        <div className="right-filler-mobile">
          <img src={ComponentData?.rightFillerMobile} alt="" />
        </div>
      </div>
    </div>
  );
};
