import Popup from "../Common/popUp/popUp";
import "./ReferJob.scss";
import referImage from "../../Assets/images/refer-friend.png";
import close from "../../Assets/SVG/Close.svg";
import speaker from "../../Assets/SVG/speaker.svg";
import { DropDownBoxCustom } from "../DropDownCustom/DropDownCustom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { isNumber } from "lodash";
import axios from "axios";

export const ReferJob = ({ selectedJob, handleHideRefer }) => {
  const backgroundStyle = {
    backgroundImage: `url(${referImage})`,
    backgroundSize: "cover", // or 'contain' depending on your need
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%", // Adjust the width as needed
    height: "216px", // Adjust the height as needed
    position: "sticky",
    top: 0,
  };

  return (
    <Popup>
      <div className="refer-job-main">
        <div className="main-img" style={backgroundStyle}>
          {/* <img src={referImage} alt="" /> */}
          <div className="close" onClick={() => handleHideRefer(false)}>
            <img src={close} alt="" />
          </div>
          <div className="title">
            <div className="desc">Refer a friend</div>
            <div>
              <img src={speaker} alt="" />
            </div>
          </div>
        </div>

        <ReferDetails
          selectedJob={selectedJob}
          handleHideRefer={handleHideRefer}
        />
      </div>
    </Popup>
  );
};

const initReferDetails = {
  candidateName: "",
  email: "",
  countryCode: "+91",
  mobile: "",
  role: "",
  //   referredJobId: "",
  //   referrerEmailId: "",
};

const ReferDetails = ({ selectedJob, handleHideRefer }) => {
  const [jobRoles, setJobRoles] = useState([
    "Frontend",
    "Backend",
    "FullStack",
  ]);

  const [countryCodes, setCountryCodes] = useState([
    "+1",
    "+91",
    "+92",
    "+93",
    "+94",
    "+95",
  ]);

  const [formDetails, setFormDetails] = useState({ ...initReferDetails });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const endpoint = "/candidate/offers/refer";
      const data = {
        candidateName: formDetails.candidateName,
        email: formDetails.email,
        mobile: formDetails.countryCode + formDetails.mobile,
        role: formDetails.role,
        referredJobId: selectedJob,
        referrerEmailId: localStorage.getItem("email"),
      };
      //   console.log(data);
      const res = await axios.post(endpoint, data);
      if (res) {
        toast("Your referal was successfully submitted");
        handleHideRefer();
      }
    } catch (err) {
      console.error("handleSubmit ferer==>", err);
    }
  };

  const handleFormChange = (value, name) => {
    if (name === "mobile") {
      if (!isNaN(value) && value.length <= 10) {
      } else return;
    }

    setFormDetails({ ...formDetails, [name]: value });
  };

  const handleSubmitButton = () => {
    const checkItems = ["email", "candidateName", "role"];

    // Check if any required fields are empty
    const isAnyFieldEmpty = checkItems.some((item) => !formDetails[item]);

    if (isAnyFieldEmpty) {
      setIsDisabled(true);
      return;
    }

    // Check for phone number validity
    const isPhoneNumberInvalid =
      !formDetails.mobile || formDetails.mobile.length < 10;

    if (isPhoneNumberInvalid) {
      setIsDisabled(true);
      return;
    }

    // Enable the submit button if all conditions are met
    setIsDisabled(false);
  };

  useEffect(() => {
    handleSubmitButton();
  }, [formDetails]);

  return (
    <div className="refer-details">
      <form onSubmit={handleSubmit}>
        <div className="each-line">
          <div className="input-container">
            <label>Name</label>
            <input
              type="text"
              name="candidateName"
              placeholder="Name"
              value={formDetails?.candidateName}
              onChange={(e) => handleFormChange(e.target.value, e.target.name)}
            />
          </div>
          <div className="input-container">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email Id"
              value={formDetails?.email}
              onChange={(e) => handleFormChange(e.target.value, e.target.name)}
            />
          </div>
        </div>
        <div className="each-line">
          <div className="input-container codes">
            <label>Mobile Number</label>
            <div className="phone">
              <DropDownBoxCustom
                options={countryCodes}
                value={formDetails?.countryCode}
                handleChange={(val) => handleFormChange(val, "countryCode")}
              />
              <input
                type="number"
                placeholder="Phone"
                name="mobile"
                value={formDetails?.mobile}
                onChange={(e) =>
                  handleFormChange(e.target.value, e.target.name)
                }
              />
            </div>
          </div>
          <div className="input-container">
            <label>Role</label>
            <DropDownBoxCustom
              options={jobRoles}
              placeholder="Role"
              value={formDetails?.role}
              handleChange={(val) => handleFormChange(val, "role")}
            />
          </div>
        </div>
        <div className="submit">
          <input
            type="submit"
            disabled={isDisabled}
            value="Submit"
            style={{
              opacity: isDisabled ? 0.7 : 1,
              cursor: isDisabled ? "not-allowed" : "",
            }}
          />
        </div>
      </form>
    </div>
  );
};
