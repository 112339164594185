export const CustomerSupportData = {
  title: "Frequently Asked Questions",
  Subtitles: [
    {
      heading: "General",
    },
    {
      heading: "Assessment",
      options: ["Round 1", "Round 2", "Round 3", "Round 4", "Round 5"],
    },
  ],
};
