import "./ProfessionalDetails.scss";
import { useEffect, useState } from "react";
import deleteIcon from "../../../Assets/SVG/delete.svg";
import { toast } from "react-toastify";
import { DropDownBox } from "./ProfessionalDetails2";
import exclamationSquare from "../../../Assets/SVG/exclamation-square.svg";
import axios from "axios";
import { getAllSkillsData } from "../../../Common/getAllSkillsData";

export const FrontEndField = ({
  disableOptions,
  setSkillsForm,
  skillsForm,
  skillPageData,
  yearsOption,
  errorFields,
}) => {
  const [frontEndSkills, setFrontEndSkills] = useState(() => {
    // Access the nested properties safely and provide an empty object as a fallback
    // return skillPageData?.[0]?.subfield?.[0]?.dev?.[0] || {};
  });

  const [languagesAvailable, setLanguagesAvailable] = useState([]);

  const [addLanguagesOption, setAddLanguagesOption] = useState([0]);
  const [framweworkOptions1, setFrameworkOptions1] = useState([]);
  const [addframeworksOption1, setAddFrameworksOption1] = useState([]);

  const handleLanguages = () => {
    let temp = frontEndSkills?.languages?.map((item) => item?.languages) || [];
    setLanguagesAvailable([...temp]);
  };

  const handleAddDeleteLanguage = () => {
    let temp = { ...skillsForm };

    let tempFrontEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];
    if (tempFrontEndLanguages?.length > 1) tempFrontEndLanguages.pop();

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Development",
        subfield: "Frontend",
        skillsdev: [
          {
            skill: "Frontend",
            languages: [...tempFrontEndLanguages],
          },
        ],
      },
    };

    setSkillsForm({ ...newSkillsUpdate });
    setAddLanguagesOption([0]);
  };

  const handleLanguageYear = (item, index) => {
    let temp = { ...skillsForm };

    let tempFrontEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (
      tempFrontEndLanguages?.[index] &&
      tempFrontEndLanguages?.[index]?.language
    ) {
      let newEntry = {
        language: tempFrontEndLanguages?.[index]?.language,
        proficiency: item,
        checked: tempFrontEndLanguages?.[index]?.checked ? true : false,
        frameworks: [...(tempFrontEndLanguages?.[index]?.frameworks ?? [])],
      };
      tempFrontEndLanguages[index] = newEntry;
    } else {
      toast("Select a language");
      return;
    }

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Development",
        subfield: "Frontend",
        skillsdev: [
          {
            skill: "Frontend",
            languages: [...tempFrontEndLanguages],
          },
        ],
      },
    };

    setSkillsForm({ ...newSkillsUpdate });
  };

  const handleLanguageChange = (item, index) => {
    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempFrontEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    console.log("temp==>", tempFrontEndLanguages);

    let newEntry = {
      language: item,
      proficiency: "",
      checked: false,
      frameworks: [],
    };
    if (
      tempFrontEndLanguages?.[index] &&
      tempFrontEndLanguages?.[index]?.language
    ) {
      tempFrontEndLanguages[index] = newEntry;
    } else {
      tempFrontEndLanguages.push(newEntry);
    }

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Development",
        subfield: "Frontend",
        skillsdev: [
          {
            skill: "Frontend",
            languages: [...tempFrontEndLanguages],
          },
        ],
      },
    };

    console.log("selectedValues==>", newSkillsUpdate);

    setSkillsForm({ ...newSkillsUpdate });
  };

  const handleShowValueForField = (index) => {
    const val =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.language;
    return val;
  };

  const handleValueForLanguageYear = (index) => {
    const val =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.proficiency;
    return val;
  };

  const handleChooseOptionsForFramweworks = () => {
    let temp = { ...skillsForm };

    let tempFrontEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (tempFrontEndLanguages?.length > 0) {
      setFrameworkOptions1(["Angular JS", "React JS", "Next JS", "JQuery"]);
    } else {
      setFrameworkOptions1([]);
      setAddFrameworksOption1([]);
    }
  };

  const handleSelectFramework = (val, fwkIndex, languageIndex) => {
    console.log("handleSelectFramework==>", val, fwkIndex, languageIndex);

    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempFrontEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (
      tempFrontEndLanguages?.[languageIndex] &&
      tempFrontEndLanguages?.[languageIndex]?.language
    ) {
      let allfwks = [
        ...(tempFrontEndLanguages?.[languageIndex]?.frameworks ?? []),
      ];

      if (allfwks[fwkIndex] && allfwks[fwkIndex].framework) {
        allfwks[fwkIndex].framework = val;
      } else {
        let newFwkEntry = {
          checked: false,
          framework: val,
          proficiency: "",
        };
        allfwks.push(newFwkEntry);
      }

      // Directly update the frameworks property
      if (tempFrontEndLanguages[languageIndex]) {
        tempFrontEndLanguages[languageIndex].frameworks = allfwks;
      }

      let newSkillsUpdate = {
        id: skillsForm?.id,
        skills: {
          field: "Development",
          subfield: "Frontend",
          skillsdev: [
            {
              skill: "Frontend",
              languages: [...tempFrontEndLanguages],
            },
          ],
        },
      };

      console.log("new fwk added==>", newSkillsUpdate);

      setSkillsForm({ ...newSkillsUpdate });
    } else {
      toast("select a language");
    }
  };

  const handleFrameworkYearSelect = (val, fwkIndex, languageIndex) => {
    console.log("handleSelectFramework==>", val, fwkIndex, languageIndex);

    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempFrontEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (
      tempFrontEndLanguages?.[languageIndex] &&
      tempFrontEndLanguages?.[languageIndex]?.language
    ) {
      let allfwks = [
        ...(tempFrontEndLanguages?.[languageIndex]?.frameworks ?? []),
      ];

      if (allfwks[fwkIndex] && allfwks[fwkIndex].framework) {
        allfwks[fwkIndex]["proficiency"] = val;
      } else {
        toast("Selected framework");
      }

      // Directly update the frameworks property
      if (tempFrontEndLanguages[languageIndex]) {
        tempFrontEndLanguages[languageIndex].frameworks = allfwks;
      }

      let newSkillsUpdate = {
        id: skillsForm?.id,
        skills: {
          field: "Development",
          subfield: "Frontend",
          skillsdev: [
            {
              skill: "Frontend",
              languages: [...tempFrontEndLanguages],
            },
          ],
        },
      };

      console.log("new fwk added==>", newSkillsUpdate);

      setSkillsForm({ ...newSkillsUpdate });
    } else {
      toast("select a language");
    }
  };

  const handleDeleteFrameworks = (fwkIndex, languageIndex) => {
    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempFrontEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (
      tempFrontEndLanguages?.[languageIndex] &&
      tempFrontEndLanguages?.[languageIndex]?.language
    ) {
      let allfwks = [
        ...(tempFrontEndLanguages?.[languageIndex]?.frameworks ?? []),
      ];

      const i = fwkIndex; /* your index value here */

      // Check if the index is within the bounds of the array
      if (i >= 0 && i < allfwks.length) {
        allfwks.splice(i, 1); // Removes 1 element at index i
      }

      console.log("length==.", allfwks.length);

      // Directly update the frameworks property
      if (tempFrontEndLanguages[languageIndex]) {
        tempFrontEndLanguages[languageIndex].frameworks = allfwks;
      }

      let newSkillsUpdate = {
        id: skillsForm?.id,
        skills: {
          field: "Development",
          subfield: "Frontend",
          skillsdev: [
            {
              skill: "Frontend",
              languages: [...tempFrontEndLanguages],
            },
          ],
        },
      };

      console.log("new fwk added==>", newSkillsUpdate);

      setSkillsForm({ ...newSkillsUpdate });
      setAddFrameworksOption1([]);

      if (allfwks?.length === 0) {
        setAddFrameworksOption1([]);
      } else if (allfwks?.length === 1) {
        setAddFrameworksOption1([0]);
      } else if (allfwks?.length === 2) {
        setAddFrameworksOption1([0, 1]);
      }
    }
  };

  const handleCheckLanguage = (index) => {
    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempFrontEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    console.log(index);

    for (let i = 0; i < tempFrontEndLanguages.length; i++) {
      if (i === index) {
        if (tempFrontEndLanguages[i].checked) {
          tempFrontEndLanguages[i].checked = false;
        } else {
          tempFrontEndLanguages[i].checked = true;
        }
      } else {
        tempFrontEndLanguages[i].checked = false;
      }
    }

    for (let i = 0; i < tempFrontEndLanguages.length; i++) {
      console.log("updated==>", tempFrontEndLanguages[i].checked);
    }

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Development",
        subfield: "Frontend",
        skillsdev: [
          {
            skill: "Frontend",
            languages: [...tempFrontEndLanguages],
          },
        ],
      },
    };

    console.log("selectedValues==>", newSkillsUpdate);

    setSkillsForm({ ...newSkillsUpdate });
  };

  const languageCheckedValu = (index) => {
    // console.log(
    //   "iindex==>",
    //   index,
    //   skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.language &&
    //     skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.checked
    // );
    const val =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.language &&
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.checked;
    return val;
  };

  const handleDefaultFrameworkAndLanguage = () => {
    // setAddFrameworksOption1;
    // setAddLanguagesOption;
    let temp1 = skillsForm?.skills?.skillsdev?.[0]?.languages?.length;
    let temp2 = [];
    for (let i = 0; i < temp1; i++) {
      temp2.push(0);
    }
    if (temp2.length === 0) {
      setAddLanguagesOption([0]);
    } else {
      setAddLanguagesOption([...temp2]);
    }

    let temp3 =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[0]?.frameworks?.length;
    let temp4 = [];
    for (let i = 0; i < temp3; i++) {
      temp4.push(0);
    }
    setAddFrameworksOption1([...temp4]);
  };

  const allSkillsData = async () => {
    try {
      const res = await getAllSkillsData(
        skillsForm?.skills?.field,
        skillsForm?.skills?.subfield
      );
      setFrontEndSkills({...res?.data?.dev?.[0] || {}});
    } catch (err) {
      console.log("getAllSkillsData error==>", err);
    }
  };

  useEffect(() => {
    handleChooseOptionsForFramweworks();
  }, [skillsForm]);

  useEffect(() => {
    handleLanguages();
  }, [frontEndSkills]);

  useEffect(() => {
    handleDefaultFrameworkAndLanguage();
    allSkillsData();
  }, []);

  return (
    <div className="skill-item">
      <div className="each-skill">
        <div>{frontEndSkills?.["header"]}</div>
        <div>{frontEndSkills?.["subheader"]}</div>

        {addLanguagesOption?.map((item, index) => (
          <div className="language-experience" key={index}>
            <div
              className="language-and-years"
              style={{ alignItems: "center", marginBottom: "12px" }}
            >
              <input
                type="checkbox"
                className="check-item"
                onChange={() =>
                  disableOptions ? "" : handleCheckLanguage(index)
                }
                // value={languageCheckedValu(index)}
                checked={languageCheckedValu(index)}
              />
              <DropDownBox
                options={languagesAvailable}
                placeholder="Language"
                value={handleShowValueForField(index)}
                handleChange={(val) => handleLanguageChange(val, index)}
                disableOptions={disableOptions}
              />
              <DropDownBox
                options={yearsOption}
                placeholder="Relevant Experience"
                value={handleValueForLanguageYear(index)}
                handleChange={(val) => handleLanguageYear(val, index)}
                disableOptions={disableOptions}
              />
              {languageCheckedValu(index) ? (
                <div className="selected">Selected For Assessment</div>
              ) : (
                errorFields.frontend &&
                index === 0 && (
                  <div className="errorField">
                    <div>
                      <img src={exclamationSquare} alt="" />
                    </div>
                    <div>Please select a language for assessment</div>
                  </div>
                )
              )}
              {index > 0 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    disableOptions ? "" : handleAddDeleteLanguage()
                  }
                >
                  {!disableOptions && <img src={deleteIcon} alt="" />}
                </div>
              )}
            </div>
            <div className="framework-experience">
              {addframeworksOption1?.map((framweork, fwkIndex) => (
                <div className="framework-and-years" key={fwkIndex}>
                  {/* <input type="checkbox" className="check-item" /> */}
                  <DropDownBox
                    options={framweworkOptions1}
                    disableOptions={disableOptions}
                    placeholder="Framework"
                    value={
                      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]
                        ?.frameworks?.[fwkIndex]?.framework || ""
                    }
                    handleChange={(val) =>
                      handleSelectFramework(val, fwkIndex, index)
                    }
                  />
                  <DropDownBox
                    options={yearsOption}
                    disableOptions={disableOptions}
                    placeholder="Relevant Experience"
                    value={
                      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]
                        ?.frameworks?.[fwkIndex]?.proficiency || ""
                    }
                    handleChange={(val) =>
                      handleFrameworkYearSelect(val, fwkIndex, index)
                    }
                  />

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      disableOptions
                        ? ""
                        : handleDeleteFrameworks(fwkIndex, index)
                    }
                  >
                    {!disableOptions && <img src={deleteIcon} alt="" />}
                  </div>
                </div>
              ))}
              {addframeworksOption1?.length < 2 && !disableOptions && (
                <div
                  className="add-language"
                  onClick={() =>
                    disableOptions
                      ? ""
                      : setAddFrameworksOption1([...addframeworksOption1, 0])
                  }
                >
                  {" "}
                  {!disableOptions && (
                    <>
                      {" "}
                      <span>+</span> Add Frameworks
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
        {/* {addLanguagesOption?.length < 2 && (
            <div
              className="add-language"
              onClick={() =>
                disableOptions ? "" : setAddLanguagesOption([0, 1])
              }
            >
              {" "}
              <span>+</span> Add Language
            </div>
          )} */}
      </div>
    </div>
  );
};
