import Popup from "../Common/popUp/popUp";
import smiley from "../../Assets/SVG/smileyGreen.svg";
import close from "../../Assets/SVG/Close.svg";
import "./SuccessRoundMessage.scss";
// import { useNavigate } from "react-router";
import laterIcon from "../../Assets/SVG/later.svg";
import rightArrow from "../../Assets/SVG/white-right-arrow.svg";
export const SuccessRoundMessage = ({
  message,
  gotoRound,
  onclose,
  next,
  isRound5 = false,
}) => {
  return (
    <Popup>
      <div className="successWrapper">
        <div className="close">
          <img src={close} alt="" onClick={() => onclose(false)} />
        </div>
        <div className="smiley">
          <img src={message?.icon ? message?.icon : smiley} alt="" />
        </div>
        <div className="congrats">
          {message?.message1 ? message?.message1 : "Congratulations"}
        </div>
        <div className="message">
          {message?.message2 ? message?.message2 : message}
        </div>
        {!isRound5 ? (
          <div className="buttons">
            {/* <button onClick={() => navigate("/assessment/home")}> */}
            <button onClick={() => (window.location.href = "/assessment/home")}>
              <span>Do it Later</span>
              <span>
                <img src={laterIcon} alt="" />
              </span>
            </button>
            <button
              onClick={() => (window.location.href = `/assessment${gotoRound}`)}
            >
              <span>Proceed to {next}</span>
              <span>
                <img src={rightArrow} alt="" />
              </span>
            </button>
          </div>
        ) : (
          <div className="round5">
            <button onClick={() => (window.location.href = "/assessment/home")}>
              <span>Go To Home</span>
              <span>
                <img src={rightArrow} alt="" />
              </span>
            </button>
          </div>
        )}
      </div>
    </Popup>
  );
};
