import "./NoOffers.scss";
import noOffers from "../../Assets/SVG/noOffers.svg";
export const NoOffers = () => {
  return (
    <div className="no-offers-main">
      <div>
        <img src={noOffers} alt="" />
      </div>
      <div className="desc">Whoops.. You have no job offers for now.</div>
    </div>
  );
};
