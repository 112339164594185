import React from "react";
import { useInView } from "react-intersection-observer";
import { Box, Typography } from "@mui/material";
import styles from "./WhatZeeroOffers.module.scss";

export const WhatZeeroOffers = ({ data }) => {
  // Ref for row 1 animation
  const { ref: refRow1, inView: inViewRow1 } = useInView({
    triggerOnce: true,
    threshold: 0.0, // Trigger animation when 30% of row 1 is visible
  });

  return (
    <Box
    className={styles.container}
    sx={{
      backgroundImage: `url(${data?.backgroundImageUrl || ""})`,
      backgroundSize: 'cover', // Keeps the image covering the container
      backgroundPosition: 'left center', // Aligns the image to the left
      backgroundRepeat: 'no-repeat', // No repeating of the image
      width: '100%', // Full width of the container
      minHeight: '100vh', // Full height of the viewport
      padding: {
        lg: '60px 0',
        md: '30px 0',
        sm: '30px 0',
        xs: '30px 0'
      },
      overflow: 'hidden' // Prevents overflow
    }}
  >
  <Typography
 
  sx={{
    fontSize: { lg: '24px', xs: '16px', sm: '16px', md: '20px' }, // Responsive font sizes
    margin: '0px !important', // Set margin
    padding: '0px !important', // Set padding
    color: '#212121', // Set text color
    textAlign: 'center', // Center text
    fontFamily: 'Poppins', // Apply font family
    fontStyle: 'normal', // Normal font style
    fontWeight: '600 !important', // Bold font weight
    lineHeight: 'normal !important', // Line height
    letterSpacing: '0.2px', // Letter spacing
  }}
>
  {data?.title}
</Typography>


      {/* Row 1 */}
      <Box
        ref={refRow1}
        className={styles.offersGrid}
        
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr', // 1 box per row on extra-small (xs) screens
            sm: '1fr', // 1 box per row on small (sm) screens
            md: '1fr 1fr', // 2 boxes per row on medium (md) screens
            lg: '1fr 1fr 1fr', // 3 boxes per row on large (lg) screens and above
          },
          gap: {lg:"50px",sm:'24px',xs:'24px',md:'35px'},
          margin:{
            lg:"40px 90px 40px 90px",
            sm:'20px',
            xs:"20px"
          }
        }}
      >
        {data?.offers?.slice(0, 6).map((offer, index) => (
          <Box
            key={index}
            // className={`${styles.offerBox} ${
            //   inViewRow1 ? styles.animateIn : ""
            // }`}
            className={styles.offerBox}
            sx={{
              height: '310px',
              width: {lg:'340px',sm:'310px',xs:'310px',md:'325px',xl:'380px'},
              margin: '0 auto', // Center the boxes horizontally
              
             textAlign:'center',
              padding:{
                lg:'20px 15px 20px 15px',
                sm:"20px 10px 20px 10px",
                xs:"20px 10px 20px 10px",
              }
            }}
          >
            <Box className={styles.icon}>
              <img src={offer?.icon} alt={offer?.title || "Icon"} />
            </Box>
            <Typography  className={styles.offerTitle} >
              {offer?.title || "Title"}
            </Typography>
            <Typography variant="body1" className={styles.offerDesc} sx={{textAlign:'center',fontSize:{lg:'16px',sm:'14px',xs:'14px',md:'14px'}}}>
              {offer?.desc || "Description"}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
