import React, { useState } from 'react'
import { Grid, Box, Typography, Button, Stack } from '@mui/material';
import tourData from './ClientProductTourData.js';
import starImage from "../../../Assets/client_tour/ClientStar.svg"
import imageMapping from './ClientProductImageMapping.js';
const ClientProductTour = ({handleGetStartedClicked=()=>{}}) => {
  const[index,setIndex]=useState(0);  

  const handleClickNext=()=>{
    setIndex((prev)=>prev+1);
  }

  const handleClickSkip=()=>{
    setIndex((prev)=>3);
  }

  const handleClickGetStart=()=>{
    
    handleGetStartedClicked()
  }
  return (
    <Box >

      <Grid container spacing={2}>
        {/* Left content area - 8 columns */}
        <Grid 
  item 
  xs={12} 
  md={7} 
  
>
  <Stack sx={{ 
    width: '360px', 
      // Ensures the width is 100% of the parent but within maxWidth
    height: '230px',
    display: 'flex',   // Optional, depending on your content layout
    flexDirection: 'column' , // Optional, for better content alignment if needed
    marginLeft:"10px"
  }}>

    <Stack sx={{height:"24px",marginBottom:'10px'}}> 
    <Box
  sx={{
    display: 'flex', // Align items horizontally
    alignItems: 'center', // Vertically center the content
  }}
>
  <Typography
    variant="h6"
    sx={{
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'left',
      color: 'rgba(33, 33, 33, 1)',
    }}
  >
    {tourData[index]?.heading || ""}
  </Typography>

  {/* Conditionally render the image only if index is 0 */}
  {index === 0 && (
    <img
      src={starImage}
      alt="Your Image"
      style={{
        height: '30px',
        width: '36px',
        
      }}
    />
  )}
</Box>

</Stack>

<Stack sx={{height:'104px'}}>
          <Typography
  variant="body1"
  sx={{
    mt: '20px', // for margin-top
   
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'left',
    color: 'rgba(79, 79, 79, 1)'
  }}
>
  {tourData[index]?.content || ""}
  
</Typography>


{ tourData[index]?.content1?.length>0  && <Typography
  variant="body1"
  sx={{
  
   
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'left',
    color: 'rgba(79, 79, 79, 1)'
  }}
>
  
{tourData[index]?.content1 || ""}
</Typography>}
</Stack>


<Box sx={{ display: 'flex', alignItems: 'center',mt:"60px" ,gap:"1px"}}>
{tourData[index]?.next && 


<Button
onClick={handleClickNext}
  variant="contained"
  sx={{
    width: '75px',
    height: '40px',
   
    borderRadius: "8px",
 
    backgroundColor: 'rgba(6, 43, 110, 1)' ,// For background color
    '&:hover': {
      backgroundColor: 'rgba(6, 43, 110, 1)', // Maintain the same background color on hover
    },
  }}
>
  Next
</Button> 


}

  {tourData[index]?.skip &&  <Button onClick={handleClickSkip}variant="text">Skip</Button>}

  {tourData[index]?.getstarted &&  
    <Button
onClick={handleClickGetStart}
  variant="contained"
  sx={{
    width: '130px',
    height: '40px',
   
    borderRadius: "8px",
 
    backgroundColor: 'rgba(6, 43, 110, 1)' ,// For background color
    '&:hover': {
      backgroundColor: 'rgba(6, 43, 110, 1)', // Maintain the same background color on hover
    },
  }}
>
  
  Get Started</Button>}
</Box>

          </Stack>
        </Grid>

        {/* Right image area - 4 columns */}
        <Grid item xs={12} md={5}>
          <Box component="img" 
              //  src="../../Assets/client_tour/ProductTourIllustration1.svg" 
              src={imageMapping[tourData[index]?.illustration]}  
               alt="Illustration" 
               sx={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientProductTour