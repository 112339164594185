import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const Accordion = ({ accordionItems:accordionItemsData, classes,show }) => {
  const [accordionItems,setAccordionItems]=useState([]);
  const [index, setIndex] = useState(show===0 ?show:null);
  const handleHeaderClick = (idx) => {
    idx === index ? setIndex(null) : setIndex(idx);
  };


  useEffect(()=>{
    if(accordionItemsData){
      setAccordionItems(accordionItemsData)
    }
  },[accordionItemsData])

  return (
    <>
      {accordionItems.map((item, idx) => (
        <AccordionItem
          key={idx}
          header={item?.header}
          Body={item?.Body}
          showBody={idx === index}
          handleHeaderClick={() => handleHeaderClick(idx)}
          classes={classes}
        />
      ))}
    </>
  );
};

const AccordionItem = ({
  header,
  Body,
  showBody = false,
  handleHeaderClick = () => {},
  classes,
}) => {
  return (
    <div className={`${classes?.main}`}>
      <div className={`${classes?.header}`} onClick={handleHeaderClick}>
        <span>{header}</span>
        {showBody ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      {showBody && (
        <div className={`${classes?.body}`}>
          {" "}
          <Body />
        </div>
      )}
    </div>
  );
};
