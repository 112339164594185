import axios from "axios";

export const getCandidateOffers = async () => {
  try {
    const endpoint = "/candidate/offers/get";
    const data = {
      name: localStorage.getItem("email"),
    };
    const res = await axios.post(endpoint, data);
    return res.data;
  } catch (err) {
    console.error("getOffers==>", err);
    return [];
  }
};
