import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
// import { useState } from "react";
import axios from "axios";
import { afterLogin } from "../../Common/flowAfterLogin";
export const GoogleSso = (props) => {
  // const navigate = useState();
  const Googlelogin = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        // console.log("Googlelogin ==>", res.data);
        const obj = { email: res.data.email };
        getUser(obj);
      } catch (err) {
        console.log("Googlelogin eror ==>", err);
      }
    },
    onError: async (error) => {
      console.log("Googlelogin error==>", error);
    },
  });

  async function getUser(credentials) {
    try {
      const urlEndpoint = "/candidate/login/gmail";
      const res = await axios
        .post(urlEndpoint, credentials)
        .then((res) => res.data);
      console.log("getUser gmail ==>", res);
      if (res) {
        // sessionStorage.setItem("email", credentials?.email);
        localStorage.setItem("email", credentials?.email);

        afterLogin();
      }
    } catch (err) {
      console.log("getUser error==>", err);
    }
  }

  return (
    <button type="button" onClick={() => Googlelogin()} className="butn ggl">
      <img className="logoGoogle" src={"icons/google.png"} />
      <span>Sign in with Google</span>
    </button>
  );
};
