import round1Success from "../Assets/SVG/successAndFailure/round1Success.svg";
import round1Failure from "../Assets/SVG/successAndFailure/round1Failure.svg";
import round2Success from "../Assets/SVG/successAndFailure/round2Success.svg";
import round2Failure from "../Assets/SVG/successAndFailure/round2Failure.svg";

export const SuccessAndFailureMessage = {
  round1: {
    success: {
      message1: "High fives, champion!",
      message2: "A stellar performance mastering the fundamentals.",
      icon: round1Success,
    },
    failure: {
      message1: "Oopsie-doodle!",
      message2:
        "The test results decided to play hide-and-seek below the passing threshold.",
      icon: round1Failure,
    },
  },
  round2: {
    success: {
      message1: "Code Commander, salute! ",
      message2: "You have passed the coding round with programming prowess. ",
      icon: round2Success,
    },
    failure: {
      message1: "Oops!",
      message2:
        "The code played hide and seek. Better luck in the next challenge.",
      icon: round2Failure,
    },
  },
};
