import React, { useEffect, useRef } from 'react';
import './Testing1.css';
import mask_matched_profiles from '../../CandidateCard/CandidateCardListWithBlur/CandidateCardListData';
import CandidateClientPageCard from '../CandidateClientPageCard';

const BACKGROUNDS = [
  "linear-gradient(177deg, rgba(145, 199, 141, 0.56) -13.49%, rgba(6, 188, 1, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(154, 169, 219, 0.56) -13.49%, rgba(33, 60, 152, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(156, 44, 243, 0.56) -13.49%, rgba(58, 73, 249, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(207, 188, 139, 0.56) -13.49%, rgba(178, 133, 11, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(253, 148, 255, 0.56) -13.49%, rgba(157, 3, 160, 0.56) 109.75%)",
];

const ImageSlider = () => {
  const sliderRef = useRef(null);

  // Create a much larger set of duplicated cards to avoid visible resets
  const candidates = Array(20)
    .fill([...mask_matched_profiles])
    .flat();

  useEffect(() => {
    const slider = sliderRef.current;
    let scrollSpeed = 2; // Adjust the scroll speed
    let requestId;

    const scrollContinuously = () => {
      // Scroll forward continuously
      slider.scrollLeft += scrollSpeed;

      // Reset when it reaches near the end of the list
      if (slider.scrollLeft >= slider.scrollWidth - slider.clientWidth) {
        slider.scrollLeft = 0; // Seamlessly reset to start
      }

      requestId = requestAnimationFrame(scrollContinuously);
    };

    requestId = requestAnimationFrame(scrollContinuously);

    return () => cancelAnimationFrame(requestId); // Cleanup on unmount
  }, []);

  return (
    <div className="slider-container" ref={sliderRef}>
      <div className="slider">
        {candidates.map((candidate, index) => (
          <div key={index} style={{ flex: '0 0 20%' }}>
            <CandidateClientPageCard
              candidate={candidate}
              background={BACKGROUNDS[index % BACKGROUNDS.length]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
