

const tourData = [
  {
    heading: 'Try the 2 minute Product Tour',
    content: 'Zeero is a game changer platform to streamline the hiring process by connecting qualified developers to employers.',
    content1: "Click “Next” to learn more.",
    next: true,
    skip: true,
    getstarted:false,
    illustration: 'ProductTourIllustration1.svg'  
  },
  {
    heading: 'Zeero: Your Strategic Talent Partner',
    content: 'Tell us your development needs, and Zeero will handle the rest. We connect you with top-tier developers tailored to your specific requirements, ensuring a seamless hiring experience.',
    content1:"",
    next: true,
    skip: true,
    getstarted:false,
    illustration: 'ProductTourIllustration2.svg'  
  },
  {
    heading: 'Software Developers at Your Fingertips',
    content: 'Zeero connects you with expert developers to build reliable, scalable software solutions. Our developers  are vetted for excellence in multiple technologies.',
    content1:"",
    next: true,
    skip: false,
    getstarted:false,
    illustration: 'ProductTourIllustration3.svg'  
  },
  {
    heading: 'Meet Zen – Your AI Talent Advisor',
    content: 'Unsure about what you need? Let Zen guide you. From creating ideal developer personas to answering your questions, Zen helps you identify the perfect match.',
    content1:"",
    next: false,
    skip: false,
    getstarted:true,
    illustration: 'ProductTourIllustration4.svg'  
  },
];

export default tourData;
