import "./FailureMessage.scss";
import sad from "../.././Assets/SVG/sadEmoji.svg";
import close from "../../Assets/SVG/Close.svg";
// import { useNavigate } from "react-router";
import Popup from "../Common/popUp/popUp";

const FailureMessageConstant =
  "Thank you for your efforts. But we regret to inform you that you did not meet the passing criteria for this round. You are welcome to apply again after  ";
const FailureTitle = "Sorry!!";

export const FailureMessage = ({ onclose, reapply, message }) => {
  // const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Popup>
      <div className="failureWrapper">
        {/* <div className="close">
          <img src={close} alt="" onClick={() => onclose(false)} />
        </div> */}
        <div className="smiley">
          <img src={message?.icon ? message?.icon : sad} alt="" />
        </div>
        <div className="congrats">
          {message?.message1 ? message?.message1 : FailureTitle}
        </div>
        <div className="message">
          {message?.message2
            ? message?.message2
            : FailureMessageConstant + formatDate(reapply)}
        </div>
        <div className="buttons">
          <button onClick={() => (window.location.href = "/assessment/home")}>
            Go to Home
          </button>
        </div>
      </div>
    </Popup>
  );
};
