export const resolve = async (promise) => {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await promise;
  } catch (e) {
    resolved.error = e;
  }

  return resolved;
};

export const clearAllOldIntervals = () => {
  // Retrieve and parse the intervals from sessionStorage
  let allIntervalsOld = JSON.parse(
    sessionStorage.getItem("allIntervals") || "[]"
  );

  // Clear all stored intervals
  allIntervalsOld.forEach((item) => {
    clearInterval(item);
  });
};
