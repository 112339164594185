import React from 'react';
import { Typography } from '@mui/material';
import starImage from "../../../Assets/client_tour/ClientStar.svg"


const ClientSearchTalentHeading = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center',flexDirection:'column', flexWrap: 'wrap',marginBottom:'60px' }}>
        <div>
        <Typography variant="h6" sx={{
  fontFamily: 'Poppins',
  fontSize: '30px',
  fontWeight: '700',
  lineHeight: '40px',
  textAlign: 'center',
  background: 'linear-gradient(to right, rgba(6, 43, 110, 1), rgba(11, 82, 212, 1))',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  flexBasis: '50%'
}}>
  Let Zen discover the
</Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h6" sx={{
  fontFamily: 'Poppins',
  fontSize: '30px',
  fontWeight: '700',
  lineHeight: '40px',
  textAlign: 'center',
  background: 'linear-gradient(to right, rgba(6, 43, 110, 1), rgba(11, 82, 212, 1))',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  
}}>
 best profile for you
</Typography>
      <img src={starImage} alt="Stars" style={{ marginLeft: '10px' }} />
    </div>
    </div>
  )
}

export default ClientSearchTalentHeading