import "./InstructionsForEachRound.scss";
import crosssIcon from "../../Assets/SVG/Close.svg";
import Popup from "../Common/popUp/popUp";

export const InstructionsUi = ({
  setViewInstructions,
  betweenTestInstructions,
}) => {
  return (
    <Popup>
      <div className="instructions-wrapper">
        <div className="close" onClick={() => setViewInstructions(false)}>
          <img src={crosssIcon} alt="" />
        </div>
        <div className="text1">{betweenTestInstructions?.title}</div>
        <div className="all-desc">
          {betweenTestInstructions?.desc?.map((item, index) => (
            <div key={index}>
              <span>{index + 1}.</span>{" "}
              <div
                className="item"
                dangerouslySetInnerHTML={{ __html: item }}
              />
            </div>
          ))}
        </div>
        <div className="okay">
          <button onClick={() => setViewInstructions(false)}>Okay</button>
        </div>
      </div>
    </Popup>
  );
};
