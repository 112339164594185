import React from "react";
import "./CandidateProfiles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CandidateProfileCard from "../CandidateProfileCard/CandidateProfileCard";
import { useInView } from "react-intersection-observer";
import { Typography,Grid } from "@mui/material";
import mask_matched_profiles from "../CandidateCard/CandidateCardListWithBlur/CandidateCardListData";
import CandidateClientPageCard from "./CandidateClientPageCard"
import { useMediaQuery } from '@mui/material';
import CandidateProfilesCardMobile from "./CandidateProfilesCardMobile";
import ImageSlider from "./ScrollTesting/Testing1";
const BACKGROUNDS = [
  "linear-gradient(177deg, rgba(145, 199, 141, 0.56) -13.49%, rgba(6, 188, 1, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(154, 169, 219, 0.56) -13.49%, rgba(33, 60, 152, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(156, 44, 243, 0.56) -13.49%, rgba(58, 73, 249, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(207, 188, 139, 0.56) -13.49%, rgba(178, 133, 11, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(253, 148, 255, 0.56) -13.49%, rgba(157, 3, 160, 0.56) 109.75%)",
];

export const CandidateProfiles = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    cssEase: "linear",
    arrows: false,
  };
  const settingsMobile = {
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show only one card
    slidesToScroll: 1, // Scroll one card at a time
    autoplay: true,
    autoplaySpeed: 4000, // Adjust autoplay speed for smoother transitions
    arrows: false, // Disable arrows
    lazyLoad: "progressive", // Enable lazy loading for images and content
    cssEase: "ease-out", // Smooth easing function for transitions
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  // Animation for when the component comes into view
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const duplicateCard=[...mask_matched_profiles,...mask_matched_profiles,...mask_matched_profiles]
  const isMobile = useMediaQuery('(max-width:960px)'); // md and below for mobile


  return (
    <div
      className={`candidate-profiles-main`}
     // ref={ref}
    >
      <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
      <Typography
  sx={{
    fontSize: { lg: '24px', sm: '16px', xs: '16px' }, // Responsive font sizes
    color: 'var(--systemGrey-900, #212121)', // Use the custom variable for color
    textAlign: 'center', // Center the text
    fontFamily: 'Poppins', // Font family
    fontStyle: 'normal', // Font style
    fontWeight: 600, // Font weight
    lineHeight: 'normal', // Line height
    letterSpacing: '0.2px', // Letter spacing
    margin:'0 15px 0 15px'
  }}
>
  One global platform of high-performing tech talent
</Typography>
</div>
      <div
        className={`profile-card-container`}
      >
        {/* {isMobile?<CandidateProfilesCardMobile />: */}
       {/* <Slider {... settings}>
          {duplicateCard.map((candidate, idx) => (
            <div key={idx} className="slide">
             
               <Grid item key={idx} lg={4} md={6} sm={12} xs={12} >
            <CandidateClientPageCard candidate={candidate} background={BACKGROUNDS[idx % BACKGROUNDS.length]}  />
          </Grid>
            </div>
          ))}
        </Slider> */}
{/* } */}

{isMobile?<CandidateProfilesCardMobile />:
          <ImageSlider />}
      </div>
    </div>
  );
};
