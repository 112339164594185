import "./FullScreenModeExit.scss";
import crosssIcon from "../../Assets/SVG/Close.svg";
import WarningIcon from "@mui/icons-material/Warning";
import Popup from "../Common/popUp/popUp";

export const FullScreenExited = ({ toggleFullScreen, fullScreenExit }) => {
  return (
    <Popup>
      <div className="fullscreen-wrapper">
        {/* <div className="close" onClick={toggleFullScreen}>
          <img src={crosssIcon} alt="" />
        </div> */}
        <div className="warning">
          <WarningIcon />
          {fullScreenExit?.title}
        </div>
        <div className="text1">{fullScreenExit?.title2}</div>
        <div className="text2">{fullScreenExit?.title3}</div>
        <div className="all-desc">
          {fullScreenExit?.desc?.map((item, index) => (
            <div key={index}>
              <span>{index + 1}.</span>{" "}
              <span
                className="item"
                dangerouslySetInnerHTML={{ __html: item }}
              />
            </div>
          ))}
        </div>
        <div className="resume">
          <button onClick={toggleFullScreen}>Resume Test</button>
        </div>
      </div>
    </Popup>
  );
};
