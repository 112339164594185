import React, { useEffect, useState } from "react";
import "./AssessmentHome.scss";
import { AssessmentHomeData } from "../../../mockData/AssessmentHome";
import dots from "../../../Assets/SVG/assessmentHome/dots.svg";
import doubleArrow from "../../../Assets/SVG/assessmentHome/double_arrow_right.svg";
import { getPassedData, getUserProgress } from "../../../Common/flowAfterLogin";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CandidateHeader } from "../../../Components/CandidateHeader/CandidateHeader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blueArrow from "../../../Assets/SVG/assessmentHome/blueArrowCarousel.svg";
import medal from "../../../Assets/SVG/assessmentHome/medal.svg";
import thumbsUp from "../../../Assets/SVG/assessmentHome/thumbsUp.svg";
import { clearAllOldIntervals } from "../../../Common/resolve";

export const AssessmentHome = () => {
  const navigate = useNavigate();
  const [assessmentPassedData, setAssessmentPassedData] = useState({
    ...AssessmentHomeData,
  });
  const [viewUi, setViewUi] = useState(true);
  const [flowAfterR5, setFlowAfterR5] = useState(false);
  const [candidateRole, setCandidateRole] = useState("");

  const getProfessionalDetails = async () => {
    try {
      const endpoint = "/candidate/details/get/professional";
      const data = { id: localStorage.getItem("email") };
      const res = await axios.post(endpoint, data);
      if (res?.data && res?.data?.subfield === "Data Science Engineer") {
        // console.log("getProfessionalDetails===> data science");
        setCandidateRole("Data_Science");
      }
    } catch (err) {
      console.error("error in getProfessionalDetails==>", err);
    }
  };

  const checkForDetails = async () => {
    try {
      const res = await getUserProgress();
      const data = res?.progress;
      if (
        data.includes("Personal Details") &&
        data.includes("Professional Experience & Technical Skills")
      ) {
        // It has both "Personal Details" and "Professional Experience & Technical Skills"
        // window.location.href = "/assessment/home";
        return;
      } else if (data.includes("Personal Details")) {
        // It has "Personal Details" but not "Professional Experience & Technical Skills"
        navigate("/details/professional");
      } else {
        // It doesn't have either "Personal Details" or "Professional Experience & Technical Skills"
        navigate("/details/personal");
      }
    } catch (err) {
      console.log("checkForDetails error==>", err);
    }
  };

  const checkForPassedRound = async () => {
    // get passed data
    // compare it with assessmentPassedData
    // accordingly add continue and passed options
    try {
      const passedData = await getPassedData();
      const passedArray = passedData?.passed;
      const failedArray = passedData?.failed;
      const terminatedArray = passedData?.terminated;
      console.log("checkForPassedRound==>", passedArray);
      // if (passedArray) {
      // setViewUi(true);
      if (passedArray?.length === 5) {
        setFlowAfterR5(true);
      }
      let notFound = true;
      const roundDetails = assessmentPassedData;

      roundDetails?.assessmentDetails?.forEach((item) => {
        if (passedArray?.includes(item.key)) {
          item["completed"] = true;
        } else if (failedArray?.includes(item?.key)) {
          item["failed"] = true;
          item["dateFailed"] = passedData["dateFailed"];
          item["retestAcceptance"] = passedData["retestAcceptance"];
          notFound = false;
        } else if (terminatedArray?.includes(item?.key)) {
          item["terminated"] = true;
          notFound = false;
        } else if (notFound) {
          item["continue"] = true;
          notFound = false;
        }
      });
      // console.log("roundDetails ==>", roundDetails);
      setAssessmentPassedData({ ...roundDetails });
      // }
    } catch (err) {
      console.log("checkForPassedRound error==>", err);
    }
  };

  useEffect(() => {
    checkForDetails();
    checkForPassedRound();
    clearAllOldIntervals();
    getProfessionalDetails();
  }, []);

  return (
    <>
      <CandidateHeader />
      <div className="assessment-home">
        {flowAfterR5 && <AllClearedUI />}
        <div className="assessment-details-main">
          <AssessmentDetails
            assessmentPassedData={assessmentPassedData}
            viewUi={viewUi}
            candidateRole={candidateRole}
          />
          <SideAnimation />
        </div>
      </div>
    </>
  );
};

const AssessmentDetails = ({ assessmentPassedData, viewUi, candidateRole }) => {
  return (
    <div className="assessment-details">
      <div>{assessmentPassedData?.title}</div>
      <div className="desc-main">
        {assessmentPassedData?.desc?.map((ele, index) => (
          <div key={index}>
            <span>
              {" "}
              <img src={dots} alt="" />
            </span>{" "}
            <div>{ele}</div>{" "}
          </div>
        ))}
      </div>
      {viewUi && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "100px",
          }}
        >
          <div className="assessment-card-wrapper">
            {assessmentPassedData?.assessmentDetails?.map((ele, index) => (
              <React.Fragment key={index}>
                <AssessmentCards
                  data={ele}
                  index={index}
                  candidateRole={candidateRole}
                />
                {index <
                  assessmentPassedData?.assessmentDetails?.length - 1 && (
                  <div
                    style={{
                      borderLeft: ele?.completed ? "1px dashed #34A853" : "",
                    }}
                    className="vertical-line"
                  ></div>
                )}
              </React.Fragment>
            ))}
          </div>
          {/* <SideAnimation /> */}
        </div>
      )}
    </div>
  );
};

const AssessmentCards = ({ data, index, candidateRole }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const IndexColor = {
    background: data.completed ? "#34A853" : data?.continue ? "#062B6E" : "",
    color: data.completed || data?.continue ? "#fff" : "",
    border: data.completed || data?.continue ? "none" : "",
  };

  const ExternalRingColor = {
    padding: data.completed || data?.continue ? "4px" : "",
    background: data?.continue
      ? "rgb(6, 43, 110, 0.3)"
      : data?.completed
      ? "rgb(52, 168, 83, 0.3)"
      : "",
  };

  const AssessmentCardStyle = {
    opacity: data?.continue || data?.failed || data?.terminated ? "1" : "",
  };

  const RoundDetailsStyle = {
    border: data?.failed ? "1px solid #F00" : "",
  };

  return (
    <div className="assessment-cards" style={AssessmentCardStyle}>
      <div className="round-details" style={RoundDetailsStyle}>
        <div className="external-ring" style={ExternalRingColor}>
          <div className="round-number" style={IndexColor}>
            <span> {index + 1}</span>
          </div>
        </div>
        <div className="round-names">
          <div>
            {candidateRole === "Data_Science" && data?.heading2
              ? data?.heading2
              : data?.heading}
          </div>
          <div>
            {candidateRole === "Data_Science" && data?.desc2
              ? data?.desc2
              : data?.desc}
          </div>
          {data?.terminated && (
            <div className="failed">{AssessmentHomeData?.unethicalMessage}</div>
          )}
          {data?.failed && (
            <div className="failed">
              {AssessmentHomeData?.failureMessage + " "}
              {formatDate(data?.retestAcceptance)}
            </div>
          )}
          {data?.completed && <div className="completed">Completed</div>}
          {data?.continue && (
            <div className="continue">
              <button onClick={() => navigate(data?.link)}>Continue</button>
            </div>
          )}
        </div>
      </div>
      <div className="arrow">
        <img src={doubleArrow} alt="" />
      </div>
      <div
        className="round-image"
        style={{
          height:
            data?.continue || data?.failed || data?.terminated ? "120px" : "",
          width:
            data?.continue || data?.failed || data?.terminated ? "180px" : "",
        }}
      >
        <img
          style={{
            width:
              data?.continue || data?.failed || data?.terminated ? "90%" : "",
          }}
          src={data?.image}
          alt=""
        />
      </div>
    </div>
  );
};

const AllClearedUI = () => {
  const [currentStatus, setCurrentStatus] = useState([]);
  const [viewUI1, setViewUI1] = useState(false);
  const [viewUI2, setViewUI2] = useState(false);
  const navigate = useNavigate();

  const handleGetCurrentStatus = async () => {
    try {
      // const email = sessionStorage.getItem("email");
      const email = localStorage.getItem("email");

      const urlEndpoint = "/candidate/callStatus";
      const data = { name: email };
      const res = await axios.post(urlEndpoint, data);
      const res1 = res?.data?.split(".");
      if (res1[0] === "Thanks for connecting with our team") setViewUI2(true);
      if (res1[0] === "You have successfully completed all 5 Rounds")
        setViewUI1(true);
      setCurrentStatus([...res1]);
    } catch (err) {
      console.log("handleGetCurrentStatus==>", err);
    }
  };

  useEffect(() => {
    handleGetCurrentStatus();
  }, []);

  return (
    <>
      {viewUI1 && (
        <div className="assesWrapper">
          {
            <div className="icon1">
              <img src={medal} alt="" />
            </div>
          }
          <div className="first">{currentStatus?.[0]}.</div>
          <div className="second">{currentStatus?.[1]}.</div>
        </div>
      )}
      {viewUI2 && (
        <div className="assesWrapper">
          <div className="first">
            <span>
              <img src={thumbsUp} alt="" />
            </span>
            <span>{currentStatus?.[0]}.</span>{" "}
          </div>
          <div className="second">
            <span>{currentStatus?.[1]}.</span>
            <span
              className="joboffers"
              onClick={() => navigate("/offers/active")}
            >
              View Job Offers
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const SideAnimation = () => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    console.log("next onclick=>", onclick);
    return (
      <div
        // className={className}
        className="arrow1"
        style={{
          ...style,
          display: "block",
          background: "rgb(226 236 254);",
          rotate: "180deg",
        }}
        onClick={onClick}
      >
        <img src={blueArrow} alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    console.log("prev onclick=>", onclick);

    return (
      <div
        // className={className}
        className="arrow2"
        style={{
          ...style,
          display: "block",
          background: "rgb(226 236 254);",
        }}
        onClick={onClick}
      >
        <img src={blueArrow} alt="" />
      </div>
    );
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  return (
    <div className="right-wrapper">
      <div className="text-1">
        {AssessmentHomeData?.rightData?.part1?.text1}
      </div>
      <div className="text-2">
        {AssessmentHomeData?.rightData?.part1?.text2}
      </div>
      {AssessmentHomeData?.rightData?.part1?.img1 && (
        <div className="img1">
          {" "}
          <img src={AssessmentHomeData?.rightData?.part1?.img1} alt="" />{" "}
        </div>
      )}
      <div className="text-3">
        {AssessmentHomeData?.rightData?.part1?.text3}
      </div>
      <div className="divider"></div>
      <div className="slider-main">
        <Slider {...settings}>
          {AssessmentHomeData?.rightData?.part2?.map((item, index) => (
            <div key={index} className="each-item">
              <div className="heading">
                {item?.img && (
                  <div>
                    <img src={item?.img} alt="" />
                  </div>
                )}
                <div>{item?.text}</div>
              </div>
              <div className="desc-main">
                <div className="desc1">{item?.desc}</div>
                <div className="details">
                  {item?.personImg && (
                    <div className="person">
                      <img src={item?.personImg} alt="" />
                    </div>
                  )}
                  <div>{item?.personName}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
