import { CandidateHeader } from "../../Components/CandidateHeader/CandidateHeader";
import "./CandidateIssues.scss";

export const CandidateIssues = () => {
  return (
    <>
      <CandidateHeader />
      <div className="candidate-issue"></div>
    </>
  );
};
