export const round5Instructions = [
  {
    title: "Round 5 Instructions",
    desc: [
      {
        desc: [
          "Punctuality is crucial. Arriving 5 minutes early for the meeting ensures that you can begin with confidence.",
          "Choose a quiet and well-lit space for the interview.",
          "Ensure there's a neutral background to minimize distractions.",
          "Check your webcam, microphone, and internet connection to ensure they are working properly.",
          "Please dress professionally for the interview.",
          "Turn off notifications on your computer and mobile devices to avoid any distractions.",
          "Expect questions that evaluate your technical knowledge, problem-solving abilities, and understanding of relevant concepts.",
          "Be prepared to discuss coding best practices and industry standards.",
          "The interview will include a detailed review of the submission uploaded in the previous round.",
          "Be ready to explain your design decisions, coding approaches, and any challenges faced during the project.",
          "Articulate your thoughts clearly. Effective communication of your ideas and problem-solving process is integral to this interview.",
          "Manage your time efficiently during coding exercises and question responses. Strive for a balance between accuracy and speed.",
        ],
      },
    ],
  },
  {
    title: "General Instructions",
    desc: [
      {
        subtitle: "Before you start the test",
        desc: [
          "Make sure you have a good Internet connection.",
          "Please ensure that you are using the latest version of Chrome or Safari.",
        ],
      },
    ],
  },
];
