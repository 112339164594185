import React from 'react'
import { Stack,Typography,Box } from '@mui/material'
import NoRecords from "../../../Assets/client_tour/shortlistedNoData.svg"
import NoCandidatesFound from "../../../Assets/client_tour/NoCandidatesFound.svg"

const CandidatesNotFound = ({message=""}) => {
  return (
    <>

<Stack alignItems="center" sx={{ margin: '50px' }}>
<Typography
  sx={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: 'normal',
    textAlign: 'center',
    color: '#062B6E',
    fontStyle: 'normal',
  }}
>
  {message.length!==0?message:"Unfortunately, Zen couldn’t find any matching results."}
</Typography>


  {/* Add the image below the Typography */}
  <img
    src={message.length!==0?NoRecords:NoCandidatesFound} // Replace with the actual image URL
    alt="Recommended profiles"
    style={{
      width: '156px', // Adjust width to your preference
      height: '203px', // Maintain aspect ratio
      marginTop: '20px', // Add margin to create space between the text and image
    }}
  />
</Stack>
</>

  )
}

export default CandidatesNotFound