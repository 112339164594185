import { Stack,Grid } from '@mui/material'
import React,{useEffect} from 'react'
import CandidateCard from '../CandidateCard';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { getShortlisted } from '../../../../Common/Client';

const CandidateCardList = ({candidatesList,handleAddShortListToggle}) => {
    const navigate = useNavigate();
    const [candidates,setCandidates]=useState([])
    const [shortlistedCandidates,setShortlistedCandidates]=useState([])
    const handleCardClick = (email,shortlist) => {
        const data={email:email,shortlist}
        navigate("/client/candidate-info", { state: data });
    }
   
    
    
    // useEffect(()=>{
    //   if(candidatesList){
    //     const apiCall=async()=>{
    //     const data={
    //       email:localStorage.getItem("email")
    //     }
    //     const res=await getShortlisted(data)
    //     setShortlistedCandidates(res);
    //     const response =handleAddShortList(candidatesList,res);
    //     setCandidates(response)
    //   }
    //   apiCall();
    //   }
      
      
    // },[candidatesList])
    useEffect(()=>{
      if(candidatesList){
        setCandidates(candidatesList)
      }
    },[candidatesList])

    const getLatestData=async(developerEmail,flag)=>{
      const data={
        email:localStorage.getItem("email")
      }

      const response =await handleAddShortListToggle(candidates,developerEmail,flag);
     
      setCandidates(response || [])
    }

    
  return (
    <div>

        <>
        <Stack sx={{ margin: '28px' }}>
      <Grid container spacing={5}>
        {candidates?.length>0 && candidates?.map((candidate, index) => (
          <Grid item key={index} lg={4} md={6} sm={12} xs={12} >
            <CandidateCard getLatestData={getLatestData} candidate={candidate ||{ }} handleCardClick={handleCardClick} shortlistedCandidates={shortlistedCandidates} />
          </Grid>
        ))}
      </Grid>
    </Stack>
        
        </>
    </div>
  )
}

export default CandidateCardList