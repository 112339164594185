// Function to obfuscate the email
export function obfuscateEmail(email) {
    // Split the email by "@" to separate the local part and the domain
    let [localPart, domain] = email.split('@');
    
    // Shorten the local part by taking the first 4 characters and appending "....."
    let newLocalPart = localPart.slice(0, 4) + '.....';
    
    // Return the modified email with the original domain
    return `${newLocalPart}@${domain}`;
}
