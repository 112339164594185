import Popup from "../popUp/popUp";
import close from "../../../Assets/SVG/Close.svg";
import tick from "../../../Assets/SVG/roundCompleteCheck.svg";
import "./UploadSuccess.scss";
export const UploadSuccessFile = ({ closeSuccessPopUp }) => {
  return (
    <Popup>
      <div className="upload-file-wrapper">
        <div className="close">
          <img onClick={() => closeSuccessPopUp(false)} src={close} alt="" />
        </div>
        <div className="tick">
          <img src={tick} alt="" />
        </div>
        <div className="text1">Upload Complete</div>
        <div className="text2">Your file has been uploaded successfully.</div>
        <div className="okay">
          <button onClick={() => closeSuccessPopUp(false)}>Okay</button>
        </div>
      </div>
    </Popup>
  );
};
