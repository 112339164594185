import { useState } from "react";
import { CandidateHeader } from "../../Components/CandidateHeader/CandidateHeader";
import accountIcon from "../../Assets/SVG/account-setting.svg";
import professionalDetails from "../../Assets/SVG/professional-details.svg";
import connectingArrow from "../../Assets/SVG/connecting-arrow.svg";
import "./CandidateWelcome.scss";
import { Link } from "react-router-dom";

export const CandidateWelcome = () => {
  const [steps, setSteps] = useState([
    {
      title: "Step 1",
      desc: "Personal Details",
      icon: accountIcon, // Use the imported SVG directly in the state
    },
    {
      title: "Step 2",
      desc: "Professional Experience & Technical Skills",
      icon: professionalDetails, // Use the imported SVG directly in the state
    },
  ]);
  return (
    <>
      <CandidateHeader />
      <div className="welcome-page">
        <div className="title">
          Welcome <span> Nishanth KV! </span>
        </div>
        <div className="desc">
          Thank you for signing up. Please complete the following steps to be
          eligible for exciting job opportunities.{" "}
        </div>
        <div className="step-wrapper">
          {steps.map((item, index) => (
            <>
              <StepBox key={index} data={item} />
              {index !== steps.length - 1 && (
                <div>
                  <img src={connectingArrow} alt="->" />
                </div>
              )}
            </>
          ))}
        </div>
        <div className="start">
          <button>
            <Link to="/details/personal"> Start the Journey</Link>
          </button>
        </div>
      </div>
    </>
  );
};

const StepBox = ({ data }) => {
  return (
    <>
      <div className="stepBox">
        <div>
          <div className="title">{data?.title}</div>
          <div className="desc">{data?.desc}</div>
        </div>
        <div>
          <img src={data?.icon} alt="icon" />
        </div>
      </div>
    </>
  );
};
