import { useEffect, useRef, useState } from "react";
import arrowDown from "../../Assets/SVG/dropDownArrow.svg";

export const DropDownBoxCustom = ({
  placeholder,
  options,
  value,
  handleChange,
  disableOptions,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const componentRef = useRef(null);

  const dropDownChange = (item) => {
    setViewOptions(false);
    if (item !== value) handleChange(item);
  };

  const handleValue = () => {
    const possibleValuesToIgnore = ["", null, undefined];
    if (possibleValuesToIgnore.includes(value)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // Check if the element exists
    if (componentRef.current) {
      if (viewOptions) {
        // If viewOptions is true, add the 'clicked' class
        componentRef.current.classList.add("clickedItem");
      } else {
        // If viewOptions is false, remove the 'clicked' class
        componentRef.current.classList.remove("clickedItem");
      }
    }
  }, [viewOptions]);

  return (
    <div className="dropDown-wrapper" ref={componentRef}>
      <div
        className="placeholder"
        onClick={() => (disableOptions ? "" : setViewOptions(!viewOptions))}
        style={{ opacity: handleValue() ? "1" : "0.7" }}
      >
        <div className={handleValue() ? "value" : "value"}>
          {handleValue() ? value : placeholder}
        </div>
        <div>
          <img
            src={arrowDown}
            alt=""
            style={{ rotate: viewOptions ? "180deg" : "0deg" }}
          />
        </div>
      </div>
      {viewOptions && (
        <div className="options-wrapper">
          {options?.map((item, index) => (
            <div key={index} onClick={() => dropDownChange(item)}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
