import React from 'react'
import { formatDateWithYearAndMonth } from '../../../utils/dateUtils';
import CircleIcon from "@mui/icons-material/Circle";
const CandidateInfoBlurEducation = ({education}) => {
    return (
        <div className="education-certifications">
          {
            education.map((item)=>(
              <EducationCertificationItem  education={item}/>
            ))
          }
         
        </div>
      )
    }

    const EducationCertificationItem = ({education}) => {
        return (
          <div className="education-certification-item">
            <div className="name">
              <span className="title">
                {education?.degree}
              </span>
              <div className="dot">
                <CircleIcon style={{ color: "green", fontSize: "10px" }} />
              </div>
              <span>{formatDateWithYearAndMonth(education?.startDate || "")} - {formatDateWithYearAndMonth(education?.endDate || "")}</span>
            </div>
            <div className="place">{education?.institution}</div>
            <div className="location">
              <span>Location: </span>
              <span>{education?.location}</span>
            </div>
          </div>
        );
      };

export default CandidateInfoBlurEducation