import "./Home_Developer.scss";
import { home3Data } from "../../mockData/Home3";
import { Layout } from "../../Components/Common/Layout/Layout";
import { RightImage } from "../../Components/Common/RightImage/RightImage";
import { Faqs } from "../../Components/Common/Faqs/Faqs";
import { WeOfferPage3 } from "../../Components/weOfferpage3/weOfferPage3";
import { VettingNew } from "../../Components/vetting-new/vettingNew";
import { useEffect } from "react";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";

const seoData = {
  title: "Zeero | Opportunities for Software Developers",
  desc: "Join Zeero's developer community & access top projects globally. Flexible work, global opportunities, career growth. Showcase your skills & build your career with us!",
  canonical: "developer",
};

export const Home_Developer = () => {
  useEffect(() => {
    const ele = document.getElementById("main-d");
    ele.scrollIntoView();
  }, []);
  return (
    <>
      <SeoTags seoData={seoData} />
      <div id="main-d">
        <Layout>
          <div className="main-item-developer">
            <RightImage ComponentData={home3Data?.first} background={"#fff"} />
          </div>
          <WeOfferPage3 data={home3Data?.weoffer} />
          <VettingNew data={home3Data?.vetting} />
          <Faqs faqData={home3Data?.faqs} />
        </Layout>
      </div>
    </>
  );
};
