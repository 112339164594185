import React ,{useState,useEffect}from "react";
import { Box, Typography, Button } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

export const ZeeroAIPlatform = ({ data }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers every time it comes into view
    threshold: 0.1, // Animation starts when 10% of the element is in view
  });

  const navigate=useNavigate()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Set initial state based on the current window width

  // Function to detect mobile screen size
  const handleResize = () => {
    const mobileBreakpoint = 768; // Set a threshold for mobile width
    setIsMobile(window.innerWidth <= mobileBreakpoint);
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Check initial window size on load (though we set this above in the initial state)
    handleResize();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
  //   <Box
  //   ref={ref}
  //   sx={{
  //     position: 'relative',
  //     width: '100%',
  //     height: { xs: '100px', sm: '105px', md: '400px', lg: '400px' },
  //     display: 'flex',
  //     flexDirection: 'column',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     boxSizing: 'border-box',
  //     overflow: 'hidden', // Ensure no overflow
  //   }}
  // >
  //   {/* Responsive Background Video */}
  //   <video
  //     autoPlay
  //     muted
  //     loop
  //     playsInline
  //     style={{
  //       position: 'absolute',
  //       top: '50%', 
  //       left: '50%',
  //       width: '100%',
  //       height: '100%',
  //       transform: 'translate(-50%, -50%)', // Center the video
  //       objectFit: 'cover', // Fully cover the container
  //       border: 'none', // Remove any border
  //       outline: 'none', // Remove outline
  //     }}
  //   >
  //     <source
  //       src={isMobile ? data?.backgroundImageUrlMobile : data?.backgroundImageUrl}
  //       type="video/mp4"
  //     />
  //   </video>
  // </Box>

  <Box
        sx={{
          width: { xs: "100%", lg: "100%" }, // Full width on all screens
          height: { xs: "100%", sm: "100%", lg: "100%" }, // Control video height on mobile
          display: "flex", // Flex for centering video
          alignItems: "center", // Vertically center video
          justifyContent: "center", // Horizontally center video
          position: "relative", // For text overlay
         
        }}
      >
        <video
          autoPlay
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures video covers the box
           
          }}
        >
          
          <source
         src={isMobile ? data?.backgroundImageUrlMobile : data?.backgroundImageUrl}
        type="video/mp4"
     />
        </video>
      </Box>
  
  

  );
};
