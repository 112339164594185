
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import SearchIcon from '@mui/icons-material/Search';
export const  sideBarData=[
    {
        header:"Search Talent",
        icon:SearchIcon,
        onCLickRoute:'/client/searchtalent'
    },
    {
        header:"Shortlisted Profiles",
        icon:BookmarkAddOutlinedIcon,
        onCLickRoute:'client/shortlisted'
    }
]