import "./CandidateOffers.scss";
import { OffersHeader } from "../../Components/OffersHeader/OffersHeader";
import { useEffect, useState } from "react";
import { getCandidateOffers } from "../../Common/getAllOffers";
import { NoOffers } from "../../Components/NoOffers/NoOffers";
import { ReferJob } from "../../Components/ReferJob/ReferJob";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import applyCheck from "../../Assets/SVG/applyButton.svg";
import rejectCheck from "../../Assets/SVG/rejectButton.svg";
import axios from "axios";
import onboarded from "../../Assets/SVG/OffersPage/onboarded.svg";
import progress from "../../Assets/SVG/OffersPage/progress.svg";
import reject from "../../Assets/SVG/OffersPage/reject.svg";
import shortlisted from "../../Assets/SVG/OffersPage/shortlisted.svg";

export const CandidateOffersActive = () => {
  const [alloffers, setAllOffers] = useState([]);
  const [viewRefer, setViewRefer] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");

  const handleAllOffers = async () => {
    let res = await getCandidateOffers();

    let temp = [];
    res.forEach((item, index) => {
      if (
        item?.jobDetails?.jobStatus.toLowerCase() !== "closed" &&
        item?.jobDetails?.jobStatus.toLowerCase() !== "expired"
      ) {
        temp.push(item);
      }
    });

    setAllOffers([...temp]);
    console.log("handleAllOffers===>", res);
  };

  const handleRefer = (jobid) => {
    setSelectedJob(jobid);
    setViewRefer(true);
  };

  const handleHideRefer = () => {
    setSelectedJob("");
    setViewRefer(false);
  };

  useEffect(() => {
    handleAllOffers();
  }, []);

  return (
    <>
      <OffersHeader indexActive={0} />
      {alloffers.length === 0 && <NoOffers />}
      {viewRefer && (
        <ReferJob selectedJob={selectedJob} handleHideRefer={handleHideRefer} />
      )}

      <div className="alloffers">
        {alloffers.map((item, index) => (
          <JobsUIActive
            data={item}
            key={index}
            getAllOfferDetails={handleAllOffers}
            handleRefer={handleRefer}
          />
        ))}
      </div>
    </>
  );
};

const JobsUIActive = ({ data, getAllOfferDetails, handleRefer }) => {
  const handleViewJD = async (jobId) => {
    try {
      const endpoint = "/candidate/offers/view";
      const data = {
        jobId: jobId,
      };
      console.log(data);
      const res = await axios
        .post(endpoint, data, { responseType: "arraybuffer" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          // Create a URL for the blob
          const pdfBlobUrl = window.URL.createObjectURL(blob);

          // Open the PDF in a new tab
          window.open(pdfBlobUrl, "_blank");
        })
        .catch((error) => {
          console.error("Error fetching or opening PDF:", error);
        });
      if (res) {
        console.log("pdf opened ");
      }
    } catch (err) {
      console.log("handleViewJD error==>", err);
    }
  };

  const handleApply = async (acceptanceStatus, jobId) => {
    try {
      const endpoint = "/candidate/offers/status/save";
      let data = {
        name: localStorage.getItem("email"),
        uniqueJobID: jobId,
        acceptance: acceptanceStatus,
      };
      const res = await axios.post(endpoint, data);
      if (res) {
        await getAllOfferDetails();
      }
    } catch (err) {
      console.log("handleApply==>", err);
    }
  };

  return (
    <div className="jobContainer">
      <div className="leftSide">
        <div className="typeOfWork">
          <span> {data?.jobDetails?.typeOfEngagement}</span>
          <span>EXPIRES : {data?.jobDetails?.dateOfJoining}</span>
        </div>
        <div>
          Job Position : <span> {data?.jobDetails?.jobPosition}</span>
        </div>
        <div className="details">
          <div>
            Company: <span>{data?.jobDetails?.companyName}</span>
          </div>
          <div>
            {" "}
            Location :<span> {data?.jobDetails?.location}</span>
          </div>
        </div>
        <div>
          DOJ : <span>{data?.jobDetails?.dateOfJoining} </span>
        </div>
        <div className="refer-and-view">
          <div
            className="viewApplication"
            onClick={() => handleViewJD(data?.jobDetails?.uniqueJobID)}
          >
            <span>View Application </span>
            <span>
              <ChevronRightIcon
                style={{ width: "24px", height: "24px", color: "#062B6E" }}
              />
            </span>
          </div>
          <div
            className="refer"
            onClick={() => handleRefer(data?.jobDetails?.uniqueJobID)}
          >
            Refer a Friend
          </div>
        </div>
      </div>
      <div className="rightSide">
        {data?.jobDetails?.inviteStatus?.toLowerCase() === "active" ? (
          <>
            <div className="apply">
              {" "}
              <button
                onClick={() => handleApply(true, data?.jobDetails?.uniqueJobID)}
              >
                Accept <img src={applyCheck} alt="" />
              </button>
            </div>
            <div className="apply">
              {" "}
              <button
                style={{ background: "#DE3A3B", border: "none" }}
                onClick={() =>
                  handleApply(false, data?.jobDetails?.uniqueJobID)
                }
              >
                Reject <img src={rejectCheck} alt="" />
              </button>
            </div>
          </>
        ) : (
          // <div
          //   style={{
          //     color: isTheWordRed(data?.jobDetails?.inviteStatus)
          //       ? "#FF432A"
          //       : "",
          //   }}
          //   className="status"
          // >
          //   {wordMatching[data?.jobDetails?.inviteStatus]
          //     ? wordMatching[data?.jobDetails?.inviteStatus]
          //     : data?.jobDetails?.inviteStatus}
          // </div>
          <WordDisplay word={data?.jobDetails?.inviteStatus} />
        )}
      </div>
    </div>
  );
};

const WordDisplay = ({ word }) => {
  const wordMatching = {
    Accepted: "In-progress",
  };

  // Define a mapping for words to colors and icons
  const wordStyles = {
    "In-progress": { color: "#C79E0D", icon: progress },
    Onboarded: { color: "#0BB762", icon: onboarded },
    Shortlisted: { color: "#0BB762", icon: shortlisted },
    "Not Selected": { color: "#DE3A3B", icon: reject },
    Rejected: { color: "#DE3A3B", icon: reject },
  };

  const style = wordStyles[wordMatching[word] ? wordMatching[word] : word];

  return (
    <div className="status">
      <span style={{ color: style.color }}>
        {" "}
        {wordMatching[word] ? wordMatching[word] : word}
      </span>
      <IconComponent iconName={style.icon} />
    </div>
  );
};

const IconComponent = ({ iconName }) => {
  return (
    <span>
      <img src={iconName} />
    </span>
  );
};
