import { useState } from "react";
import downArrow from "../../Assets/SVG/Dropdown.svg";
import "./RoundInstructions.scss";
export const RoundInstructions = ({ roundInstructions }) => {
  return (
    <div className="instructionsContainer">
      {roundInstructions?.map((item, index) => (
        <InstructionsUi key={index} instructions={item} />
      ))}
    </div>
  );
};

const InstructionsUi = ({ instructions }) => {
  const [openDesc, setOpenDesc] = useState(false);
  return (
    <div className="eachInstruction">
      <div className="instructionTitle" onClick={() => setOpenDesc(!openDesc)}>
        <div>{instructions?.title}</div>
        <div style={{ rotate: openDesc ? "180deg" : "0deg" }}>
          <img src={downArrow} alt="" />
        </div>
      </div>
      {openDesc && (
        <div className="instructionDesc">
          {instructions?.desc?.map((item, index) => (
            <div key={index} className="instructionSubSection">
              {item?.subtitle && (
                <div className="instructionSubtitle">{item?.subtitle}</div>
              )}
              {item?.desc?.map((value, valueIndex) => (
                <div key={valueIndex + value[0]} className="instructionItem">
                  <div>{valueIndex + 1}.</div>
                  <div dangerouslySetInnerHTML={{ __html: value }}></div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
