import React, { useState, useEffect } from "react";
import {
  Stack,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Box,
  Chip,
  useMediaQuery,
} from "@mui/material";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BusinessIcon from "@mui/icons-material/Business";
import { addRemoveShortlist } from "../../../Common/Client";
import withGradient from "../CandidateCard/GradientIcon/withGradient";
const CandidateCardClientProfileCard = ({ candidate, background }) => {
  return (
    <Card
      sx={{
        background: background,
        maxWidth: "480px",
        maxHeight: "304px",
        gap: "0px",
        borderRadius: "8px", // Same border radius as the card
        padding: { lg: "12px", sm: "10px", xs: "10px", md: "10px", xl: "12px" },
        boxShadow: "0px 2px 8px rgba(33, 33, 33, 0.2)", // Subtle black shadow with a bit more emphasis on the bottom

        overflow: "hidden", // Ensures shadow respects the border-radius
      }}
    >
      <Section1 candidate={candidate} />
      <Section2 candidate={candidate} />
      <Section3 candidate={candidate} />
    </Card>
  );
};

const Section1 = ({ candidate }) => {
  return (
    <Grid sx={{ display: "flex", gap: "6px", height: "24px" }}>
      <Grid item>
        <AvailibiltyTab availbility={candidate?.availability || " "} />
      </Grid>
      {/* <Grid item>
            <AvailibiltyTab availbility={candidate?.availability || " "}/>
            </Grid> */}
    </Grid>
  );
};

const Section2 = ({ candidate }) => {
  return (
    <Grid
      sx={{ display: "flex", marginTop: "14px", gap: "20px", height: "143px" }}
    >
      <Grid item>
        <Section2Image candidate={candidate} />
      </Grid>
      <Grid item>
        <Section2Data candidate={candidate} />
      </Grid>
    </Grid>
  );
};

const Section3 = ({ candidate }) => {
  return <CustomChips items={candidate?.skills || candidate?.skills || []} />;
};

const Section2Image = ({ candidate }) => {
  const addOrRemoveShortlist = async (e, developerEmail, bool) => {
    e.preventDefault();
    try {
      const data = {
        clientEmail: localStorage.getItem("email"),
        developerEmail: developerEmail,
        add: bool,
      };
      const res = await addRemoveShortlist(data);
    } catch (e) {}
  };
  return (
    <Grid container justifyContent="center">
      <Box position="relative" display="inline-flex">
        {/* Circular Image */}
        <Avatar
          alt="Profile Image"
          src={candidate?.image || ""} // Replace with your image path
          sx={{ width: "102px", height: "102px" }}
        />
        {/* Bookmark Icon positioned on the border of the image */}
      </Box>
    </Grid>
  );
};

const Section2Data = ({ candidate }) => {
  function maskName(fullName) {
    // Split the full name into first and last names
    const [firstName, lastName] = fullName?.split(" ") || "Anil Kumar";

    // Mask the first name (keep the first letter, replace the rest with '*')
    const maskedFirstName = firstName[0] + "*".repeat(firstName.length - 1);

    // Mask the last name (keep the first letter, replace the rest with '*')
    const maskedLastName = lastName[0] + "*".repeat(lastName.length - 1);

    // Return the masked name
    return `${maskedFirstName} ${maskedLastName}`;
  }

  return (
    <Box onClick={(e) => {}} sx={{ width: "211px" }}>
      {/* Main Title */}
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Poppins",
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "19.5px",

          color: "#fff",
        }}
      >
        {candidate?.industryDomain || "Backend"}
      </Typography>
      {/* Name (Masked) */}
      <Typography
        variant="h5"
        sx={{
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "21px",
          letterSpacing: "0.2px",

          color: "#fff",
        }}
      >
        {candidate?.developerName}
      </Typography>

      {/* Details Grid */}
      <Grid
        container
        direction="column"
        sx={{ marginTop: "-10px" }}
        spacing={2}
      >
        {/* Location Row */}
        <Grid item container alignItems="center">
          <Details icon={LocationOnOutlinedIcon} text={candidate?.location} />
        </Grid>

        {/* Years of Experience Row */}
        <Grid item container alignItems="center">
          <Details icon={WorkOutlineIcon} text={candidate?.experienceInYears} />
        </Grid>

        {/* Industry/Technology Row */}
        <Grid item container alignItems="center">
          <Details icon={BusinessIcon} text={candidate?.industryDomain} />
        </Grid>
      </Grid>
    </Box>
  );
};
const AvailibiltyTab = ({ availbility }) => {
  return (
    <Card
      sx={{
        padding: "0px 4px",
        height: "24px",
        backgroundColor: "rgba(239, 240, 246, 1)",
        borderRadius: "12px",
        display: "flex", // Make the card content a flexbox
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      }}
    >
      <Stack
        sx={{
          textAlign: "center", // Ensure content is centered
          padding: 0,
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Poppins",
            fontSize: "9px",
            fontWeight: 400,
            lineHeight: "13.5px",
            letterSpacing: "0.2px",
            color: "rgba(6, 43, 110, 1)",
            alignSelf: "center",
          }}
        >
          Availability:{" "}
          <Typography
            component="span" // Using span so that it stays inline
            sx={{
              fontSize: "10px", // Increase the font size for availability
              fontWeight: 500, // Optional: adjust weight if needed
              alignSelf: "center",
            }}
          >
            {availbility}
          </Typography>
        </Typography>
      </Stack>
    </Card>
  );
};

const Details = ({ icon: IconComponent, text }) => {
  const GradientIcon = withGradient(IconComponent); // Wrap the passed icon with the gradient

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <IconComponent
        sx={{
          width: "20px",
          height: "20px",
          position: "relative",
          color: "#fff",
        }}
      />
      <Typography
        variant="body1"
        sx={{
          marginLeft: "4px",
          color: "#fff",
          display: "inline-block",
          wordBreak: "break-word",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "19.5px",
          letterSpacing: "0.2px",
          textAlign: "left",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const CustomChips = ({ items }) => {
  const [isLgScreen, setIsLgScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1200px)"); // 1200px is the default 'lg' breakpoint in MUI
    const handleMediaChange = () => setIsLgScreen(mediaQuery.matches);

    // Initial check
    handleMediaChange();

    // Add listener
    mediaQuery.addEventListener("change", handleMediaChange);

    // Cleanup on unmount
    return () => mediaQuery.removeEventListener("change", handleMediaChange);
  }, []);

  // Show 4 items on large screens, otherwise 3 items
  const visibleItems = isLgScreen ? items.slice(0, 4) : items.slice(0, 3);

  const remainingItemsCount = items.length - 3; // Calculate how many items are left

  return (
    <Box sx={{ display: "flex", gap: "8px", marginTop: "16px" }}>
      {/* Render the first 1-3 items */}
      {visibleItems.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "76px",
            height: "30px",
            backgroundColor: "rgba(239, 240, 246, 1)",
            borderRadius: "4px",
            padding: "8px",
            boxSizing: "border-box",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "18px",
              letterSpacing: "0.2px",
              color: "rgba(6, 43, 110, 1)",
              whiteSpace: "nowrap", // Prevent text wrapping
              overflow: "hidden",
              textOverflow: "ellipsis", // Show "..." if text is too long
            }}
          >
            {item.length > 8 ? `${item.slice(0, 8)}...` : item}{" "}
            {/* Truncate to 8 characters */}
          </Typography>
        </Stack>
      ))}

      {/* Render the +X Stack if there are more than 3 items */}
      {remainingItemsCount > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "76px",
            height: "30px",
            backgroundColor: "rgba(239, 240, 246, 1)",
            borderRadius: "4px 0px 0px 0px",
            padding: "0px 8px",
            boxSizing: "border-box",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "18px",
              letterSpacing: "0.2px",
              color: "rgba(6, 43, 110, 1)",
              whiteSpace: "nowrap", // Prevent text wrapping
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            +{remainingItemsCount}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default CandidateCardClientProfileCard;