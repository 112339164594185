import "./CandidateOffers.scss";
import { OffersHeader } from "../../Components/OffersHeader/OffersHeader";
import { getCandidateOffers } from "../../Common/getAllOffers";
import { NoOffers } from "../../Components/NoOffers/NoOffers";
import { useEffect, useState } from "react";

export const CandidateOffersExpired = () => {
  const [alloffers, setAllOffers] = useState([]);
  const [viewloader, setViewLoader] = useState(false);

  const handleAllOffers = async () => {
    let res = await getCandidateOffers();

    let temp = [];
    res.forEach((item, index) => {
      if (
        item?.jobDetails?.jobStatus.toLowerCase() === "closed" ||
        item?.jobDetails?.jobStatus.toLowerCase() === "expired"
      ) {
        temp.push(item);
      }
    });

    setAllOffers([...temp]);
    console.log("handleAllOffers===>", res);
  };

  useEffect(() => {
    handleAllOffers();
  }, []);

  return (
    <>
      <OffersHeader indexActive={1} />
      {alloffers.length === 0 && <NoOffers />}
      <div className="alloffers">
        {alloffers.map((item, index) => (
          <JobsUiInActive data={item} key={index} />
        ))}
      </div>
    </>
  );
};

const JobsUiInActive = ({ data }) => {
  return (
    <div className="jobContainerExpired">
      <div className="typeOfWork">
        <span className="type"> {data?.jobDetails?.typeOfEngagement}</span>
        <span className="exp">
          EXPIRED ON : {data?.jobDetails?.dateOfJoining}
        </span>
      </div>
      <div className="pos">
        Job Position : <span>{data?.jobDetails?.jobPosition}</span>
      </div>
      <div className="details">
        <div className="company">
          Company : <span>{data?.jobDetails?.companyName}</span>
        </div>
        <div className="loc">
          {" "}
          Location :<span> {data?.jobDetails?.location}</span>
        </div>
      </div>
      <div className="doj">
        DOJ : <span>{data?.jobDetails?.dateOfJoining} </span>
      </div>
    </div>
  );
};
