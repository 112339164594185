import axios from "axios";

export const getCandidateCardInfo = async (data) => {
  try {
    const config = {};
    const res = await axios
      .post("/candidate/detailInfo/card", data, config)
      .then((response) => response?.data);

     
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }
};


export const getCandidateAllInfo=async(data)=>{

  try {
    const config = {};
    const res = await axios
    .post("/candidate/detailInfo/profile/get/save", data, config)
    .then((response) => response?.data);
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }


}

export const getCandidateAllInfoMask=async(data)=>{

  try {
    const config = {};
    const res = await axios
    .post("/candidate/detailInfo/profile/get/save/mask", data, config)
    .then((response) => response?.data);
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }


}