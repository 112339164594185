import React from "react";
import { useInView } from "react-intersection-observer";
import styles from "./SeamlessPartnership.module.scss"; // Import styles
import { Button ,Grid,Stack,Box,Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const SeamlessPartnership = ({ data }) => {
  const navigate=useNavigate()
  // Animation hooks for all parts
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.0,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.0,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.0,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true,
    threshold: 0.0,
  });

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        {/* Part 1: Title */}
        <div
          ref={ref1}
          className={`${styles.part1Title} ${inView1 ? styles.animateIn : ""}`}
        >
       <Typography
  sx={{
    fontSize: { lg: '24px', sm: '16px', xs: '16px' }, // Responsive font sizes
    color: '#212121', // Text color
    textAlign: 'center', // Center align text
    fontFamily: 'Poppins', // Font family
    fontWeight: 600, // Font weight
    fontStyle: 'normal', // Font style
    lineHeight: '24px', // Line height
    letterSpacing: '0.2px' // Letter spacing
  }}
>
  {data.part1Title}
</Typography>

        </div>

        {/* Part 2: Four Items Grid - Row-wise Layout */}
        <Grid ref={ref2} container   rowSpacing={{ lg: 24, md: 12, sm: 4, xs: 3 }} sx={{margin:{lg:'50px 0 0 120px',sm:'4px 0 0 0',xs:'4px 0 0 0',md:'4px 0 0 0'}}}>
  {data?.part2Items?.map((item, index) => (
    <Grid
      item
      key={item.id} // Assuming `item.id` is unique; replace if needed
      ref={ref2}
      className={` ${inView2 ? styles.animateIn : ""}`}
      xs={12} // Full width on extra-small and small screens (1 item per row)
      lg={6} // Half width on large screens (2 items per row)
     sx={{
        marginBottom: { lg: '40px', sm: '0px', xs: '0px', md: '0px' }, // Add margin bottom for row spacing
      }}
    >
      <Stack direction="row" spacing={2}>
        {/* Image Section */}
        <Box className={styles.imageWrapper} >
          <img src={item.image} alt={item.title}  />
        </Box>

        {/* Text Section */}
        <Box className={styles.textWrapper}>
          <Typography
            variant="h6"
            className={styles.part2Title}
            sx={{
              height: {lg:'40px',sm:'30px',xs:'30px',md:'35px'},
              display: 'flex',
              alignItems: 'center',
              color: '#062B6E',
              fontSize:{lg:'20px',sm:'16px',xs:'16px'}
            }}
          >
            {item.title}
          </Typography>
          <Typography
  variant="body1"
  
  sx={{
    color: '#212121',
    fontFamily: 'Poppins',
   
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    fontSize:{lg:'16px',sm:'13px',xs:'13px'},
    maxWidth:'328px'
  }}
>
  {item.desc}
</Typography>

        </Box>
      </Stack>
    </Grid>
  ))}
</Grid>


        {/* Part 3: Four Images in a Single Row */}
        <Grid sx={{margin:{lg:'10px 0 0 120px',sm:'10px 0 0 0',xs:'10px 0 0 0',md:'10px 0 0 0'}}}>
        <div
          ref={ref3}
          className={inView3 ? styles.animateIn : styles.part3Grid}
        >
          <div className={styles.part3Row}>
            {data?.part3Images?.map((imgItem, index) => (
              <div key={index} className={styles.part3Item}>
                <div className={styles.part3ImageContainer}>
                  <img
                    src={imgItem.image}
                    alt={imgItem.title}
                    className={styles.part3Image}
                  />
<Typography
  sx={{
    color: '#062b6e',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: { lg: '16px', xs: '12px', sm: '12px', md: '12px' },
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    marginTop: '10px',
  }}
>
  {imgItem?.title.split(' ').length === 2 ? (
    <>
      {imgItem?.title.split(' ')[0]} <br /> {imgItem?.title.split(' ')[1]}
    </>
  ) : (
    imgItem?.title
  )}
</Typography>


                </div>
              </div>
            ))}
          </div>
        </div>
        </Grid>


        {/* Part 4: CTA */}
        <div
          ref={ref4}
          className={inView4 ? styles.animateIn : styles.part4Container}
        >
          <div className={styles.part4Title}>
            
            <Typography
  sx={{
    color: 'black', // Text color
    textAlign: 'center', // Center align text
    fontFamily: 'Poppins', // Font family
    fontWeight: 600, // Font weight
    fontStyle: 'normal', // Font style
    lineHeight: '24px', // Line height
    letterSpacing: '0.2px', // Letter spacing
    marginTop:"10px"

  }}
>
  {data?.part4Title}
</Typography>
</div>
          <div className={styles.buttonContainer}>
            <Button  sx={{marginBottom:{sm:'30px',xs:'30px',lg:'32px'},height:{lg:"54px",sm:'40px',xs:'40px',md:'40px'}}}className={styles.part4Button} onClick={()=>{
              navigate("/client-login")
            }}>
              {data?.part4ButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
