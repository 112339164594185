import fileErrorDefault from "../Assets/SVG/upload-error.svg";
import fileSizeError from "../Assets/SVG/fileErrors/fileSize.svg";
import fileFormatError from "../Assets/SVG/fileErrors/fileFormat.svg";

export const fileUploadErrors = {
  fileSize: {
    message1: "Upload Error!",
    message2: "File's on a size diet - trim it to 5 MB.",
    icon: fileSizeError,
  },
  fileFormat: {
    message1: "Upload Error!",
    message2: "File's in the wrong outfit—only pdf file is allowed.",
    icon: fileFormatError,
  },
  fileCount: {
    message1: "Upload Error!",
    message2: "Maximum 3 files are allowed",
    icon: fileErrorDefault,
  },
  fileEmpty: {
    message1: "Upload Error!",
    message2: "Uploaded file is empty",
    icon: fileErrorDefault,
  },
};

export const fileUploadErrorsProject = {
  fileSize: {
    message1: "Upload Error!",
    message2: "File's on a size diet - trim it to 10 MB.",
    icon: fileSizeError,
  },
  fileFormat: {
    message1: "Upload Error!",
    message2: "File's in the wrong outfit—only zip file is allowed.",
    icon: fileFormatError,
  },
  fileCount: {
    message1: "Upload Error!",
    message2: "Maximum 3 files are allowed",
    icon: fileErrorDefault,
  },
  fileEmpty: {
    message1: "Upload Error!",
    message2: "Uploaded file is empty",
    icon: fileErrorDefault,
  },
};
