import { devQues } from "./devQuestions";
import { clientQues } from "./clientQuestions";
// import HomeMain from "../Assets/home/home_1.webp";
import HomeMain from "../Assets/home/first.webp";
import HomeServices1 from "../Assets/home/services_2.webp";
import HomeServices2 from "../Assets/home/services_1.webp";
import whatWeDo1 from "../Assets/home/what_we_do_1.webp";
import whatWeDoBackground from "../Assets/SVG/whatWeDo/BG_image.svg";
import whatWeDoIlusstration from "../Assets/SVG/whatWeDo/what_we_do_ills.svg";
import rightFiller from "../Assets/SVG/whatWeDo/right_filler.svg";
import rightFillerMobile from "../Assets/SVG/whatWeDo/right_filler_mobile.svg";
import ourServices1 from "../Assets/home/ourServices/1.webp";
import ourServices2 from "../Assets/home/ourServices/2.webp";
import ourServices3 from "../Assets/home/ourServices/3.webp";
import whatweOffer1 from "../Assets/home/offer/whatweoffer1.png";
import whatweOffer2 from "../Assets/home/offer/whatweoffer2.png";
import whatweOffer3 from "../Assets/home/offer/whatweoffer3.png";
import whatweOffer4 from "../Assets/home/offer/whatweoffer4.png";
import step1 from "../Assets/home/hiring/step1.svg";
import step2 from "../Assets/home/hiring/step2.svg";
import step3 from "../Assets/home/hiring/step3.svg";
import step4 from "../Assets/home/hiring/step4.svg";
import blog1 from "../Assets/home/blogs/first.webp";
import blog2 from "../Assets/home/blogs/second.webp";
import blog3 from "../Assets/home/blogs/third.webp";
import blog4 from "../Assets/home/blogs/fourth.webp";

export const homeData = {
  breadCrumbs: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Client",
      link: "/client",
    },
  ],
  first: {
    heading: "",
    data: [
      {
        // about: "WE BELIVE IN <span>QUALITY</span> OVER QUANTITY",
        title:
          "<div>CONNECT</div> <div class='item'> </div> <div>INNOVATE</div> <div class='item'></div> <div>SCALE</div>",
        desc: "Experience the future of on-demand talent with Zeero.",
        buttons: [
          {
            title: "Explore Opportunities",
            link: "/login",
          },
          {
            title: "Access Top Talent",
            // link: "/login",
            link: "/contact/client",
          },
        ],
        imgLink: HomeMain,
      },
    ],
  },
  third: {
    heading: "Our Services",
    data: [
      {
        title: "Access Top Talent for Your Projects",
        desc: "With Zeero, companies gain access to a diverse talent pool of highly skilled and experienced software developers from around the world. Our fast and rigorous vetting process ensures that only top talent makes it to our platform, so you can be confident in the quality of developers and the team you hire.",
        buttons: [
          {
            title: "Learn More",
            link: "/client",
          },
        ],
        boxUrl: "/client",
        imgLink: HomeServices1,
      },
      {
        title: "Empowering Software Developers Globally",
        desc: "At Zeero, we believe in the power of talented software developers. Join as Developer to showcase your skills, connect with businesses, and explore opportunities in your preferred environment. Our platform provides a supportive for software developers to thrive and succeed in their careers.",
        buttons: [
          {
            title: "Learn More",
            link: "/developer",
          },
        ],
        boxUrl: "/developer",
        imgLink: HomeServices2,
      },
    ],
  },
  ClientConfession: {
    heading: "what our clients say",
    desc: "Neque, pulvinar vestibulum non aliquam.",
    data: [
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
    ],
  },
  DeveloperConfession: {
    heading: "what our Developers says",
    desc: "Neque, pulvinar vestibulum non aliquam.",
    data: [
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
    ],
  },
  faqs: {
    title: "Frequently Asked Questions",
    // desc: "Pellentesque cras adipiscing tempus libero vel nullam mauris tellus. Aliquam ultrices tellus consequat amet, lectus aliquam est in neque.",
    mainData: [
      {
        header: "For Client",
        questions: clientQues,
      },
      {
        header: "For Developers",
        questions: devQues,
      },
    ],
  },
  whatwedo: {
    heading: "",
    data: [
      {
        imgLink: whatWeDo1,
      },
    ],
  },
  whatWeDoNew: {
    heading: "What we do?",
    desc: "Zeero serves as a catalyst, connecting businesses with top-tier talent and providing developers with opportunities to thrive and excel.",
    desc2:
      "Zeero is a platform designed to help organizations achieve growth and scale through the onboarding and management of exceptional on-demand talent. We provide the flexibility and agility you need to rapidly adapt and scale your tech teams, be it remote, hybrid, or on-site. Our platform cuts through the noise of talent acquisition, focusing on matching your specific needs with the perfect skillsets, and going beyond mere connections to serve as your strategic partner in realizing your product vision and delivering high-impact solutions, faster. ",
    backgroundImage: whatWeDoBackground,
    illustration: whatWeDoIlusstration,
    rightFiller: rightFiller,
    rightFillerMobile: rightFillerMobile,
  },
  ourServicesNew: {
    heading: "Why Choose Zeero ?",
    cards: [
      {
        image: ourServices1,
        title: "Customized Solutions for Your Projects",
        desc: "Zeero offers personalized solutions tailored to the unique needs of each project. Whether you require on-site, hybrid or remote developers, our platform matches you with the right talent to ensure project success. With Zeero, you can expect customized solutions that align with your project requirements and objectives.  ",
      },
      {
        image: ourServices2,
        title: "Global Opportunities for Developers",
        desc: "Zeero provides developers with access to a diverse range of projects and clients, fostering continuous learning and skill development. Joining Zeero offers opportunities to showcase your skills, expand your portfolio, and advance your career in a supportive and collaborative environment. ",
      },
      {
        image: ourServices3,
        title: "End-to-End Partnership",
        desc: "Zeero provides a supportive environment where both clients and developers can thrive, offering ongoing guidance, support, and resources to maximize project success and career growth. With Zeero, you can rely on us to be your dedicated partner every step of the way. ",
      },
    ],
  },
  features: {
    title: "What we Offer?",
    data: [
      {
        title: "Access to Top Talent",
        description:
          "Access top-notch software developers on-demand for your projects. Our developers undergo extensive tests, project submissions, and interviews, ensuring their expertise in the industry.",
        image: whatweOffer1,
      },
      {
        title: "Cost-Effective Solutions",
        description:
          "Save time and resources with Zeero's efficient recruitment and onboarding process allowing businesses to focus on driving innovation and growth.",
        image: whatweOffer2,
      },
      {
        title: "Strategic Partnership",
        description:
          "We go beyond simply connecting you with talent. We're your strategic partner, supporting you as a solution to the challenges you face while resource planning.",
        image: whatweOffer3,
      },
      {
        title: "Diversity and Inclusion",
        description:
          "Zeero prioritizes diversity, tapping into global talent to build inclusive teams. We believe that diversity and inclusion drive creativity and innovation leading to richer, more effective solutions and a stronger competitive edge.",
        image: whatweOffer4,
      },
    ],
  },
  hiring: {
    title: "Hiring Made Easy",
    data: [
      {
        title: "Define Your Needs",
        description:
          "Share your project goals, timeline, and skill  requirements.",
        image: step1,
      },
      {
        title: "Match with Top Talent",
        description:
          "We curate profiles from our vetted pool to find the best fit.",
        image: step2,
      },
      {
        title: "Review and Select",
        description: "You review and select the most suitable candidates.",
        image: step3,
      },
      {
        title: "Smooth Onboarding",
        description: "Zeero handles the onboarding for a seamless transition.",
        image: step4,
      },
    ],
  },
  blogs: {
    title: "Explore Our Blogs",
    data: [
      {
        image: blog1,
        title: "How Zeero Ensures Fair and Reliable Developer Assessments?",
        description:
          "As the digital transformation accelerates, the demand for skilled software developers continues to grow. To ensure the selection of top-tier talent, careful assessment methods are crucial.",
        date: "June 5, 2024",
        readTime: "5 min read",
        link: "https://medium.com/@zeero.inc/best-practices-for-proctoring-how-zeero-ensures-fair-and-reliable-developer-assessments-f5d2f298fcc8",
      },
      {
        image: blog2,
        title: "Challenges and Solutions of Hiring Software Developers",
        description:
          "Due to the rapid advancement of digital transformation, alongside the evolution of AI and other technologies, the demand for skilled software ",
        date: "May 12, 2024",
        readTime: "5 min read",
        link: "https://medium.com/@zeero.inc/challenges-and-solutions-of-hiring-developers-in-2024-a53e188e327b",
      },
      {
        image: blog3,
        title: "How to Build a Successful Career as a Freelance Developer?",
        description:
          "Freelancing as a developer offers a unique and rewarding career path filled with opportunities for growth, creativity, and autonomy.",
        date: "July 11, 2024",
        readTime: "5 min read",
        link: "https://medium.com/@zeero.inc/how-to-build-a-successful-career-as-a-freelance-developer-cd3644549379",
      },
      {
        //link change
        image: blog4,
        title: "How Zeero is Changing Software Development World?",
        description:
          "Digital transformation is a strategic necessity for businesses striving to stay competitive. At the core of this transformation lies the integration of advanced technological solutions",
        date: "May 23, 2024",
        readTime: "5 min read",
        link: "https://www.linkedin.com/pulse/how-zeero-changing-software-development-world-bzeero-gckqc/?trackingId=bN2r8oxnnGljnssPDW8m9g%3D%3D",
      },
    ],
  },
};