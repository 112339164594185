import Popup from "../popUp/popUp";
import close from "../../../Assets/SVG/Close.svg";
export const FilesError = ({ setviewFileError, fileError }) => {
  return (
    <Popup>
      <div className="FilesErrorMain">
        <div className="close" onClick={() => setviewFileError()}>
          <img src={close} alt="" />
        </div>
        <div className="errorSign">
          <img src={fileError?.icon} alt="" />
        </div>
        <div className="title1">{fileError?.message1}</div>
        <div className="title2">{fileError?.message2}</div>
        <button className="okay" onClick={() => setviewFileError()}>
          Okay
        </button>
      </div>
    </Popup>
  );
};
