import { useState } from "react";
import { BackToHome } from "../BackToHome/BackToHome";
import { CandidateHeader } from "../CandidateHeader/CandidateHeader";
import "./OffersHeader.scss";
import { Link } from "react-router-dom";

export const OffersHeader = ({ indexActive }) => {
  const [titles, setTitles] = useState([
    {
      title: "Active Offers",
      link: "/offers/active",
    },
    {
      title: "Expired Offers",
      link: "/offers/expired",
    },
  ]);

  return (
    <>
      <CandidateHeader />
      <div className="candidate-offers-header">
        <div>
          <BackToHome />
          <div className="title1">Job Offers</div>
          <div className="desc1">
            Here you can keep an eye for upcoming job requirement for you.
          </div>
        </div>
        <div className="offers-title">
          {titles.map((item, index) => (
            <Link
              className={indexActive === index ? "active" : ""}
              key={index}
              to={item?.link}
            >
              <span>{item?.title}</span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
