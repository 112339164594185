import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, IconButton } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
// import Badge from "@mui/material/Badge";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useState } from "react";
// import { logout } from "../API/SignInApi";
import "./ClientHeader.scss";
import { useNavigate } from "react-router-dom";
import customerSupport from "../../../Assets/SVG/customer-support.svg";
import axios from "axios";

export const ClientHeader = ({isNoSidebarRoute}) => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [icon, setIcon] = useState(
    sessionStorage.profiledata != null &&
      JSON.parse(sessionStorage.profiledata).firstName
      ? JSON.parse(sessionStorage.profiledata).firstName[0] +
      JSON.parse(sessionStorage.profiledata).lastName[0]
      : null
  );
  // Set an initial notification count for notification
  // const [notificationCount, setNotificationCount] = useState(4);

  const dropDownContent = [
    {
      title: "Profile",
      function: () => showProfile(),
    },
    // {
    //   title: "Assesment Summary",
    //   function: () => showAssessmentPage(),
    // },
    // {
    //   title: "Issue Tracker",
    //   function: () => showIssueTracker(),
    // },
    // {
    //   title: "Offers",
    //   function: () => ViewOffers(),
    // },
    {
      title: "Logout",
      function: () => onClickLogout(),
    },
  ];

  const showAssessmentPage = () => {
    // navigate("/assessment/home");
    window.location.href = "/assessment/home";
  };

  const showProfile = () => {
    // console.log("show profile");
    navigate("/client/personaldetails");
    setAnchorEl(null);
  };

  const showIssueTracker = () => {
    // console.log("showIssueTracker");
    navigate("/issue-tracker");
  };

  const ViewOffers = () => {
    navigate("/offers/active");
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function onClickLogout() {
    try {
      const endpoint = "/candidate/logout/candidate";
      const data = {
        // email: sessionStorage.getItem("email"),
        email: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      if (res) {
        // sessionStorage.clear();
        localStorage.clear();
        navigate("/client-login");
      }
    } catch (err) {
      console.error("error in logout", err);
      // sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  }

  // async function goToHome=()=>{

  // }

  return (
    <>
   <Box sx={{ flexGrow: 1 }} className="candidate-header">
  <AppBar position="static" className="header-wrapper">
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: isNoSidebarRoute ? "space-between" : "flex-end",
        alignItems: "center", // vertically align items
      }}
    >
      {/* Left side (Logo) */}
      {isNoSidebarRoute && (
        <div>
          <a href="/client/searchtalent">
            <img
              style={{ width: "50px", height: "50px" }}
              src={process.env.PUBLIC_URL + "/images/zeero-logo.svg"}
            />
          </a>
        </div>
      )}

      {/* Right side (Account icon and notifications) */}
      {auth && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Uncomment and add Badge for notification if needed */}
          {/* <Badge
            badgeContent={notificationCount}
            color="primary"
            title="notification"
            className="notification-icon"
            sx={{
              ml: "30px",
              cursor: "pointer",
              "@media (max-width: 600px)": {
                ml: "10px",
              },
              "@media (max-width: 400px)": {
                ml: "5px",
              },
            }}
          >
            <NotificationsIcon />
          </Badge> */}

          {/* Account icon and dropdown */}
          <IconButton
            size="small"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            title="account"
            onClick={handleMenu}
            color="inherit"
            className="account-icon"
            sx={{
              ml: "30px",
              "@media (max-width: 600px)": {
                ml: "10px",
              },
              "@media (max-width: 400px)": {
                ml: "5px",
              },
            }}
          >
            {icon != null ? (
              <Avatar sx={{ bgcolor: "#3f51b5" }}>{icon} </Avatar>
            ) : (
              <AccountCircle />
            )}
          </IconButton>
          <Menu
            sx={{ mt: "25px" }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {dropDownContent.map((item) => (
              <MenuItem key={item.title} onClick={item.function}>
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </Toolbar>
  </AppBar>
</Box>


    </>
  );
};
