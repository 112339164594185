export const proctoringInstructions = {
    "instructions": [
        "It is a proctored screening, so please make sure that you do not change tabs or refresh while the screening is taking place.",
        "Please make sure your device isn’t connected to any other screens during the test.",
        "This test requires permission to <b>access Webcamera</b>. Kindly grant access when prompted.",
        "Make sure that your <b>face is visible</b> within the webcamera frame.",
        "Make sure to <b>sit in a well-lit room</b>.",
        "The test requires permission for <b>screen recording</b>.",
        `Please select <b>Entire screen</b> when prompted to allow full screen recording `,
        "You will be disqualified if any cheating is detected.",
        "Once the test begins, it must be completed in one session without the option to pause or resume later option."
    ]
}