import { useNavigate } from "react-router-dom";
import "./VettingNew.scss";

export const VettingNew = ({ data }) => {
  const navigate = useNavigate();

  const handleRedirect = (link) => {
    navigate(link);
  };
  return (
    <div className="new-vetting">
      <div className="wrapper">
        <div className="heading">{data?.heading}</div>
        <div className="process">
          <img src={data?.imgLink} alt="" />
        </div>
        <div className="process-mobile">
          <img src={data?.vettingMobile} alt="" />
        </div>
        <div className="main-button">
          {data?.button && (
            <button onClick={() => handleRedirect(data?.button?.link)}>
              {data?.button?.title}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
