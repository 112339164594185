import "./CandidateSubHeader.scss";
import { candidateSubHeaderData } from "../../mockData/candidateSubHeader";
import greyDot from "../../Assets/SVG/greyDot.svg";
import halfBlueDot from "../../Assets/SVG/halfBlueDot.svg";
import greenCheck from "../../Assets/SVG/roundCompleteCheck.svg";
import { useEffect, useState } from "react";
import { BackToHome } from "../BackToHome/BackToHome";
import axios from "axios";
export const CandidateSubHeader = ({ passedData }) => {
  console.log("passedData==>", passedData);

  const [candidateRole, setCandidateRole] = useState("");

  const getProfessionalDetails = async () => {
    try {
      const endpoint = "/candidate/details/get/professional";
      const data = { id: localStorage.getItem("email") };
      const res = await axios.post(endpoint, data);
      if (res?.data && res?.data?.subfield === "Data Science Engineer") {
        // console.log("getProfessionalDetails===> data science");
        setCandidateRole("Data_Science");
      }
    } catch (err) {
      console.error("error in getProfessionalDetails==>", err);
    }
  };

  useEffect(() => {
    // console.log("Passed data==>", passedData);
    getProfessionalDetails();
  }, []);

  return (
    <div className="subeader-container">
      <BackToHome />
      <RoundTitles
        roundTitles={candidateSubHeaderData?.topHeader}
        passedData={passedData}
        candidateRole={candidateRole}
      />
    </div>
  );
};

const RoundTitles = ({ roundTitles, passedData, candidateRole }) => {
  // Find the index of the first title whose key is not in passedData
  const [firstNotFoundIndex, setfirstNotFoundIndex] = useState("");

  useEffect(() => {
    const notFoundIndex = roundTitles?.findIndex(
      (item) => !passedData?.includes(item?.key)
    );
    setfirstNotFoundIndex(notFoundIndex);
  }, [roundTitles, passedData]);

  return (
    <div className="titles-container">
      {roundTitles?.map((item, index) => {
        // Determine the class name based on the presence in passedData
        // and whether it's the first item not found
        const isCompleted = passedData?.includes(item?.key);
        const isFirstNotFound =
          index === firstNotFoundIndex && firstNotFoundIndex !== -1;

        const className = isCompleted
          ? "title-wrapper-completed"
          : isFirstNotFound
          ? "title-wrapper-first-not-found"
          : "title-wrapper-normal";

        const imageSrc = isCompleted
          ? greenCheck
          : isFirstNotFound
          ? halfBlueDot
          : greyDot;
        return (
          <div key={index} className={className}>
            <div>
              <img src={imageSrc} alt="" />
            </div>
            <div>
              {" "}
              {candidateRole === "Data_Science" && item?.value2
                ? item?.value2
                : item?.value}
            </div>
          </div>
        );
      })}
    </div>
  );
};
