export const devQues = [
  {
    ques: "What is Zeero?",
    ans: "Zeero is a platform that enables developers to have an opportunity to work on quality projects by going through an extensive evaluation process.",
  },
  {
    ques: "What is the vetting process?",
    ans: "Vetting process includes technical and interpersonal skills evaluation using automated tests and one on one interview.",
  },
  {
    ques: "How much time does the vetting process take?",
    ans: "Vetting process usually takes a week for completion.",
  },
  {
    ques: "How will I get paid?",
    ans: "Zeero developers are paid depending on the type of engagement.",
  },
];
