import axios from "axios";

export const getAllSkillsData = async (field, subField) => {
  try {
    const endpoint = "/candidate/get/role/skills";
    const data = {
      fieldName: field,
      subFieldName: subField,
    };
    return await axios.post(endpoint, data);
  } catch (err) {
    console.log("getAllSkillsData error==>", err);
  }
};
