import axios from "axios";

export const getClientPersonalDetails = async (data) => {
    try {
      const config = {};
      const res = await axios
        .post("/client/detail/client/get/personaldetails", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  export const getClientFirstLoginFlag = async (data) => {
    try {
      const config = {};
      const res = await axios
        .post("/client/flag/user/get/useronboarding", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  };


export const saveClientData = async (data) => {
    try {
      const config = {};
    
      const res = await axios
        .post("/client/detail/client/save/personaldetails", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      console.error(err,"1233");
      return null;
    }
  };

  export const saveUserOnboarding = async (data) => {
    try {
      const config = {};
      console.log(data,"dataa")
      const res = await axios
        .post("/client/flag/user/set/useronboarding", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
     
      return null;
    }
  };


  export const chatBotMapSkillsFromMessage = async (data) => {
    try {
      const config = {};
      console.log(data,"dataa")
      const res = await axios
        .post("/client/chatbot/zeerogpt/chatbot", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      
      return null;
    }
  };
  export const chatBotMapSkillsFromMessageNew = async (data) => {
    try {
      const config = {};
      console.log(data,"dataa")
      const res = await axios
        .post("/client/chatbot/zeerogpt/chatbot/new", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      
      return null;
    }
  };

  export const getCandidateListFromBot = async (data) => {
    try {
      const config = {};
     
      const res = await axios
        .post("/client/chatbot/zeerogpt/csv/response", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      
      return null;
    }
  };

  export const getCandidateListFromTEXT = async (data) => {
    try {
      const config = {};
      
      const res = await axios
        .post("/client/chatbot/zeerogpt/match-profiles", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      
      return null;
    }
  };

  export const getResponseFromJD = async (data) => {
    try {
      const config = {};
      console.log(data,"dataa")
      const res = await axios
        .post("/client/candidate/zeerogpt/process/jd/response", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      
      return null;
    }
  };

  export const addRemoveShortlist = async (data) => {
    try {
      const config = {};
      console.log(data,"dataa")
      const res = await axios
        .post("/client/candidate/client/add/remove/shortlist/developer", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      
      return null;
    }
  };

  export const getShortlisted = async (data) => {
    try {
      const config = {};
      console.log(data,"dataa")
      const res = await axios
        .post("/client/candidate/client/get/shortlist/developer", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      
      return null;
    }
  };

  export const getDetailList = async (data) => {
    try {
      const config = {};
      console.log(data,"dataa")
      const res = await axios
        .post("/client/candidate/dev/profile/get/dev/details/list", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      
      return null;
    }
  };

  export const checkUserEmail = async (data) => {
    try {
      const config = {};
      const res = await axios
        .post("/client/signup/email/validate", data, config)
        .then((response) => response?.data);
  
       
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  export const checkUserPaidStatus = async (data) => {
    try {
      const config = {};
      const res = await axios
        .post("/client/flag/get/client/paidUser/status", data, config)
        .then((response) => response?.data);
       
       
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  