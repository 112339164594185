import React ,{useState,useEffect}from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ClientDataForm from '../ClientDataForm/ClientDataForm'
import ClientFirstLoginTour from '../../Components/ClientPage/ClientFirstLoginTour/ClientFirstLoginTour'
import ClientSearchBar from '../../Components/ClientPage/ClientSearchBar/ClientSearchBar'
import { Stack, Typography ,Box} from '@mui/material'
import ClientSearchTalentHeading from '../../Components/ClientPage/ClientSearchtalentHeading/ClientSearchTalentHeading'
import ClientSuggestionCards from '../../Components/ClientPage/ClientSuggestionCards/ClientSuggestionCards'
import CandidateProfiles from './CandidateProfiles/CandidateProfiles'
import CandidateCard from '../../Components/ClientPage/CandidateCard/CandidateCard'
import CandidateCardList from '../../Components/ClientPage/CandidateCard/CandidateCardList/CandidateCardList'
import CandidateCardListWithBlur from '../../Components/ClientPage/CandidateCard/CandidateCardListWithBlur/CandidateCardListWithBlur'
import ChatBot from '../../Components/ClientPage/ChatBot/ChatBot';
import { getCandidateListFromBot, getResponseFromJD ,getShortlisted,chatBotMapSkillsFromMessageNew, checkUserPaidStatus, getCandidateListFromTEXT} from '../../Common/Client'
import CandidatesNotFound from '../../Components/ClientPage/CandidatesNotFound/CandidatesNotFound'
import { GlobalLoaderZenIcon } from '../../Components/Common/GlobalLoader/GlobalLoaderZenIcon'


const SearchTalent = () => {
  const [isChatBotVisible,setIsChatBotVisible]=useState(true)
  const navigate=useNavigate()
  const [loder,setLoader]=useState(false)
const [searchItem,setSearchItem]=useState("");
const [openChatBot,setChatBotOpen]=useState(false);
const [showCandidates,setShowCandidates]=useState("");
const [showSuggestionCards,setShowSuggestionCards]=useState(true)
const [candidates,setCandidates]=useState([]);
const [userPaidStatus,setUserPaidStatus]=useState(false)

const location = useLocation();

const { api_message } = location.state || {};

const handleAddShortList = (candidateList , shortlisted) => {
  // Iterate over the candidateList and update the `shortlist` property based on whether email is in the shortlisted array
  const updatedList = candidateList?.map(candidate => {
    if (shortlisted?.includes(candidate?.email)) {
      return { ...candidate, shortlist: true }; // Set shortlist to true if email is in shortlisted
    }
    return { ...candidate, shortlist: false }; // Set shortlist to false otherwise
  });

  return updatedList; // Return the updated candidates list
};

useEffect(() => {
  if (api_message) {
   
    const minLoaderDuration = 2000;
  const startTime = Date.now();
   const apiCall=async()=>{
    setLoader(true);
    const apiPayload={
      api_message:api_message,
      client_id:localStorage.getItem("email")
    }
    const res=await getCandidateListFromBot(apiPayload); // Call API with the message
    if(!(res?.error)){
      const responsedata={
        email:localStorage.getItem("email")
      }
      const shortlistedResponse= await getShortlisted(responsedata)
      const data=await handleAddShortList(res?.matched_profiles,shortlistedResponse);
      const endTime = Date.now();
    const elapsedTime = endTime - startTime;

    if (elapsedTime < minLoaderDuration) {
      // If the API call finishes in less than 2 seconds, wait the remaining time
      setTimeout(() => {
        setLoader(false); // Turn off the loader after the remaining time
      }, minLoaderDuration - elapsedTime);
    } else {
      // If the API call took more than 2 seconds, turn off the loader immediately
      setLoader(false);
    }
      setCandidates(data);
      setShowCandidates("candidates")
      setShowSuggestionCards(false);
    }else{
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      setShowCandidates("noCandidates");
      setShowSuggestionCards(false);
      if (elapsedTime < minLoaderDuration) {
        // If the API call finishes in less than 2 seconds, wait the remaining time
        setTimeout(() => {
          setLoader(false); // Turn off the loader after the remaining time
        }, minLoaderDuration - elapsedTime);
      } else {
        // If the API call took more than 2 seconds, turn off the loader immediately
        setLoader(false);
      }
    }
    location.state = null;
   } 

   navigate("/client/searchtalent", { state: null });
   location.state = null;
   apiCall();
  }
}, [api_message]); // Dependency on api_message

useEffect(()=>{
const apiCall=async()=>{
const obj={
  id:localStorage.getItem("email")
}
 const status= await checkUserPaidStatus(obj);
 setUserPaidStatus(status);
}
 apiCall();
},[])

const handleJDResponse=async(formData)=>{
setLoader(true)
  // Start timing to ensure a minimum loader duration of 2 seconds
  const minLoaderDuration = 2000;
  const startTime = Date.now();
  try{
  
    const res=await getResponseFromJD(formData)
    const responsedata={
      email:localStorage.getItem("email")
    }
    const shortlistedResponse= await getShortlisted(responsedata)
    const data=await handleAddShortList(res?.matched_profiles || [],shortlistedResponse);
    const endTime = Date.now();
    const elapsedTime = endTime - startTime;

    if (elapsedTime < minLoaderDuration) {
      // If the API call finishes in less than 2 seconds, wait the remaining time
      setTimeout(() => {
        setLoader(false); // Turn off the loader after the remaining time
      }, minLoaderDuration - elapsedTime);
    } else {
      // If the API call took more than 2 seconds, turn off the loader immediately
      setLoader(false);
    }
    setCandidates( data);
    setShowCandidates("candidates")
    setShowSuggestionCards(false);
    setLoader(false)

  }
  catch(e){

  }
}

const handleAddShortListToggle = (candidatesList, developerEmail,flag) => {
  // Iterate over the candidatesList and update the `shortlist` property
  const updatedList = candidatesList.map(candidate => {
    if (candidate.email === developerEmail) {
      return { ...candidate, shortlist: flag }; // Update shortlist to false
    }
    return candidate; // Return the candidate as is if email doesn't match
  });

  return updatedList; // Return the updated candidates list
};
  
const handleSearchGetResponse=async(search)=>{
  setSearchItem(search)
  setLoader(true);
  const obj={
   
    api_message:search.trim(),
    client_id:localStorage.getItem("email")
   
}
const responsedata={
  email:localStorage.getItem("email")
}
const res=await getCandidateListFromTEXT(obj);
const shortlistedResponse= await getShortlisted(responsedata)
const data=await handleAddShortList((Array.isArray(res?.matched_profiles))?res?.matched_profiles :[] ,shortlistedResponse);
setCandidates(data);
setShowCandidates("candidates")
setShowSuggestionCards(false);

setLoader(false);

}

const handleJDCancelled=()=>{

  setCandidates([]);
  setShowSuggestionCards(true)
  setShowCandidates("")

}
const handleisChatBotVisible=(flag)=>{
  setIsChatBotVisible(flag);
}

  return (
    <div style={{ backgroundColor: 'rgba(243, 245, 247, 1)', minHeight: '100vh' }}>
     {loder && <GlobalLoaderZenIcon />}
    <ClientFirstLoginTour handleisChatBotVisible={handleisChatBotVisible}/>
    <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 2000 }}>
   { isChatBotVisible && <ChatBot openChatBot={openChatBot}  />}
  </Box>
  
    {/* Sticky ClientSearchBar aligned to the left */}
    <Box sx={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: 'rgba(243, 245, 247, 1)', width: '100%', padding: '20px 0' }}>
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', width: '100%', paddingLeft: '20px' ,display:'flex',alignItems:'center',flexDirection:'column'}}> 
        {/* Adjust padding/margin as necessary */}
        <ClientSearchTalentHeading />
        <ClientSearchBar handleJDCancelled={handleJDCancelled} serachItem={searchItem} handleJDResponse={handleJDResponse}  handleSearchGetResponse={handleSearchGetResponse}/>
        {showSuggestionCards && <ClientSuggestionCards  handleSearchGetResponse={handleSearchGetResponse}/>}
      </Box>
    </Box>

 

  
   
  
    {showCandidates==="candidates" ? (
      <Stack>
        {candidates?.length>0? 
        <Stack alignItems="center" sx={{ margin: '50px' }}>
          <Typography 
            sx={{
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '28px',
              textAlign: 'center',
              color: 'rgba(6, 43, 110, 1)'
            }}
          >
            These are the recommended profiles for you:
          </Typography>
        </Stack>:<CandidatesNotFound />}
       
  
        {/* CandidateCardList with scrolling */}
        <CandidateCardList 
  handleAddShortListToggle={handleAddShortListToggle} 
  candidatesList={userPaidStatus ? candidates || [] : candidates.slice(0, 6)} 
/>
      </Stack>
      
    ):showCandidates==="noCandidates"?<CandidatesNotFound/>:null}
    {(candidates?.length>6 && !userPaidStatus) && <Stack>
       <CandidateCardListWithBlur candidateList={candidates.slice(6,candidates.length) || []}/>
      </Stack>}
  </div>
  

  
  )
}

export default SearchTalent