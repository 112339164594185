import { useEffect, useState } from "react";
import { CandidateHeader } from "../../../Components/CandidateHeader/CandidateHeader";
import { CandidateSubHeader } from "../../../Components/CandidateSubHeader/CandidateSubHeader";
import "./AssessmentRound5.scss";
import { getPassedData } from "../../../Common/flowAfterLogin";
import { SuccessRoundMessage } from "../../../Components/SuccessRoundMessage/SuccessRoundMessage";
import { FailureMessage } from "../../../Components/FailureMessage/FailureMessage";
import { round5Instructions } from "../../../mockData/roundInstructions/round5Instructions";
import { RoundInstructions } from "../../../Components/RoundInstructions/RoundInstructions";
import axios from "axios";
import Popup from "../../../Components/Common/popUp/popUp";
import crosssIcon from "../../../Assets/SVG/Close.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import calendarIcon from "../../../Assets/SVG/calendar.svg";
import clockIcon from "../../../Assets/SVG/clock.svg";
import greenCalendarIcon from "../../../Assets/SVG/greenCalendar.svg";
import blueClock from "../../../Assets/SVG/blueClock.svg";
import emailCheck from ".././../../Assets/SVG/email-check.svg";
import { GlobalLoader } from "../../../Components/Common/GlobalLoader/GlobalLoader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import editCalendarIcon from "../../../Assets/SVG/edit_calendar.svg";

export const AssessmentRound5 = () => {
  const [passedData, setPassedData] = useState([]);
  const [isRound5Clear, setIsRoundClear] = useState(false);
  const [viewSuccess, setViewSuccess] = useState(false);
  const [isRound5Failed, setIsRound5Failed] = useState({
    status: false,
    reApply: "",
  });
  const [viewFail, setViewFail] = useState(false);
  const [reapplydate, setreApplydate] = useState("");
  const [viewButton, setViewButton] = useState(true);
  const [viewMettingSuccess, setViewMettingSuccess] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [mettingDetails, setMettingDetails] = useState({
    startTime: null,
    endTime: null,
    date: null,
    join_url: null,
    meetingId: null,
    status: null,
  });

  const handleGetRound5Status = async (loader = false) => {
    if (loader) {
      setViewLoader(true);
    }
    try {
      const endpoint = "/candidate/round5/schedule/details";
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      console.log("r3 status==>", res?.data);
      if (res?.data) {
        setMettingDetails({ ...res.data });
      }
    } catch (err) {
      console.error("handleGetRound5Status error==>", err);
    } finally {
      if (loader) {
        setViewLoader(false);
      }
    }
  };

  const checkForPassedRound = async () => {
    setViewLoader(true);
    try {
      const res = await getPassedData();
      const passedArray = res?.passed ? res.passed : [];
      setPassedData([...passedArray]);
      console.log("checkForPassedRound4==>", passedArray);
      if (
        passedArray?.includes("Round1") &&
        passedArray?.includes("Round2") &&
        passedArray?.includes("Round3") &&
        passedArray?.includes("Round4")
      ) {
        if (passedArray && passedArray?.includes("Round5")) {
          setIsRoundClear(true);
          setViewSuccess(true);
        } else if (res?.failed?.includes("Round5")) {
          setIsRound5Failed({
            status: true,
            reApply: res?.retestAcceptance,
          });
          setViewFail(true);
          setreApplydate(res?.retestAcceptance);
        } else {
          await handleGetRound5Status();
        }
      } else {
        setViewButton(false);
      }
    } catch (err) {
      console.log("checkForPassedRound error==>", err);
    } finally {
      setViewLoader(false);
    }
  };

  useEffect(() => {
    checkForPassedRound();
  }, []);

  return (
    <>
      {viewLoader && <GlobalLoader />}
      {viewSuccess && (
        <SuccessRoundMessage
          message={"You have successfully completed Round 5."}
          gotoRound={"/home"}
          onclose={setViewSuccess}
          next={"Home"}
          isRound5={true}
        />
      )}
      {viewFail && (
        <FailureMessage reapply={reapplydate} onclose={setViewFail} />
      )}
      <CandidateHeader />
      <CandidateSubHeader passedData={passedData} />
      <RoundStartOrComplete
        viewButton={viewButton}
        isRound5Clear={isRound5Clear}
        isRound5Failed={isRound5Failed}
        mettingDetails={mettingDetails}
        setViewMettingSuccess={setViewMettingSuccess}
        setViewLoader={setViewLoader}
      />
      <RoundInstructions roundInstructions={round5Instructions} />{" "}
      {viewMettingSuccess && (
        <SuccessMetting
          setViewMettingSuccess={setViewMettingSuccess}
          handleGetRound5Status={handleGetRound5Status}
        />
      )}
    </>
  );
};

const RoundStartOrComplete = ({
  viewButton,
  isRound5Clear,
  isRound5Failed,
  mettingDetails,
  setViewMettingSuccess,
  setViewLoader,
}) => {
  const [viewSchedule, setViewSchedule] = useState(false);
  const [reschedule, setReschedule] = useState(false);

  return (
    <>
      <div className="round3StartOrComplete">
        <div className="status-main">
          <div>
            <div className="text-1">45-60 MINS</div>
          </div>
        </div>
        {viewButton ? (
          isRound5Failed?.status ? null : isRound5Clear ? (
            <div className="schedule">
              <div className="text-2">Expert Discussion</div>
              <div className="completed">Completed</div>
            </div>
          ) : mettingDetails?.date === null ? (
            <div className="schedule">
              <div className="text-2">Expert Discussion</div>
              <div className="text-3">
                A holistic assessment where we intricately examine your uploaded
                project, evaluate your technical expertise, and recognise your
                adept coding and problem-solving skills.
              </div>
              {/* <button onClick={() => setViewSchedule(true)}>Schedule</button> */}
              <Disclaimer />
            </div>
          ) : (
            <ShowMeetDetails
              mettingDetails={mettingDetails}
              viewSchedule={viewSchedule}
              setViewSchedule={setViewSchedule}
              setReschedule={setReschedule}
            />

            // <></>
          )
        ) : null}
      </div>
      {viewSchedule && (
        <ScheduleMeeting
          setViewSchedule={setViewSchedule}
          setViewMettingSuccess={setViewMettingSuccess}
          reschedule={reschedule}
          mettingDetails={mettingDetails}
          setViewLoader={setViewLoader}
        />
      )}
    </>
  );
};

const Disclaimer = () => {
  return (
    <div className="metting-diaclaimer">
      <div>
        <img src={editCalendarIcon} alt="icon" />
      </div>
      <div>Our team will connect & schedule a discussion with you.</div>
    </div>
  );
};

const TodaysDate = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1); // This modifies currentDate

  // Check the day of the week (0 = Sunday, 6 = Saturday)
  let dayOfWeek = currentDate.getDay();

  // If it's Saturday (6), add 2 days to get to Monday
  // If it's Sunday (0), add 1 day to get to Monday
  if (dayOfWeek === 6) {
    currentDate.setDate(currentDate.getDate() + 2);
  } else if (dayOfWeek === 0) {
    currentDate.setDate(currentDate.getDate() + 1);
  } else {
    // If it's not Saturday or Sunday, just add one day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Now you can extract the day, month, and year from currentDate
  let day = currentDate.getDate();
  let month = currentDate.getMonth(); // Remember, months are 0-indexed
  let year = currentDate.getFullYear();

  // Format the date as a string
  let date = `${year}-${month < 9 ? "0" + (month + 1) : month + 1}-${
    day < 10 ? "0" + day : day
  }`;

  return date;
};

const ShowMeetDetails = ({
  mettingDetails,
  viewSchedule,
  setViewSchedule,
  setReschedule,
}) => {
  const [openBeforeTime, setOpenBeforeTime] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function parseDate(input) {
    // Split the date and time parts
    const parts = input.match(
      /(\d{4})-(\d{2})-(\d{2}) (\d{1,2}):(\d{2}) (AM|PM)/
    );

    // Extract the components
    const year = parseInt(parts[1], 10);
    const month = parseInt(parts[2], 10) - 1; // Month is 0-indexed in JavaScript
    const day = parseInt(parts[3], 10);
    let hours = parseInt(parts[4], 10);
    const minutes = parseInt(parts[5], 10);
    const ampm = parts[6];

    // Convert 12-hour to 24-hour format
    if (ampm === "PM" && hours < 12) {
      hours += 12;
    }
    if (ampm === "AM" && hours === 12) {
      hours = 0;
    }

    return new Date(year, month, day, hours, minutes);
  }

  function isFiveMinutesToEvent(startTime) {
    // Parse the start time into a Date object
    const eventStartTime = parseDate(startTime);

    // Get the current time
    const currentTime = new Date();

    // Calculate the difference in milliseconds
    const timeDifference = eventStartTime - currentTime;

    return timeDifference;
  }

  const openInNewTab = (url) => {
    // Use window.open method to open a new tab with the specified URL

    const timeToWait = 10 * 60 * 1000;

    const timeReaming = isFiveMinutesToEvent(mettingDetails?.startTime);
    console.log(timeToWait, timeReaming);
    if (timeToWait >= timeReaming) {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else {
      setOpenBeforeTime(true);
      setError(true);
      setErrorMessage("Join before 10 min");
    }
  };

  const handleSchedule = () => {
    const timeToWait = 24 * 60 * 60 * 1000;

    const timeReaming = isFiveMinutesToEvent(mettingDetails?.startTime);

    console.log(timeToWait, timeReaming);

    if (timeToWait <= timeReaming) {
      setReschedule(true);
      setViewSchedule(true);
    } else {
      setError(true);
      setErrorMessage("reschedule only before 24 hours");
    }
  };

  return (
    <div className="meetDetails">
      <div className="non-buttons">
        <div className="text-2">Expert Discussion</div>
        <div className="text-3">Please join the meeting from below.</div>
        <div className="items-main">
          <div className="item">
            <div>
              <img src={emailCheck} alt="" />
            </div>
            <div>{mettingDetails?.date}</div>
          </div>
          <div className="item">
            <div>
              <img src={blueClock} alt="" />
            </div>
            <div>
              {mettingDetails?.startTime.split(" ")[1]}{" "}
              {mettingDetails?.startTime.split(" ")[2]}
            </div>
          </div>
        </div>
      </div>
      <div className="side-buttons">
        <button
          style={{ visibility: "hidden" }} // comment this code when reschedule is to be enabled
          // onClick={() => handleSchedule()} // removing to disable rescheduling uncomment when to be enalbled
          className="Reschedule"
        >
          Reschedule
        </button>
        <button
          className="join"
          onClick={() => openInNewTab(mettingDetails?.join_url)}
        >
          Join the Meeting
        </button>
        {error && (
          <div
            style={{
              fontFamily: "Poppins",
              color: "#d90000",
              maxWidth: "183px",
            }}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

const ScheduleMeeting = ({
  setViewSchedule,
  setViewMettingSuccess,
  reschedule,
  mettingDetails,
  setViewLoader,
}) => {
  const [date, setDate] = useState(() => TodaysDate());
  const [selectedSlot, setSelectedSlot] = useState(-1);
  const [allSlots, setAllSlots] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSlots = (data) => {
    let temp = [];
    data.forEach((element) => {
      const startDate = new Date(element.startTime);
      const startTimeString = startDate.toLocaleTimeString([], {
        timeStyle: "short",
      });
      temp.push({
        ...element,
        time: startTimeString,
      });
    });
    setAllSlots([...temp]);
  };

  const formatBookSlotData = (data, reschedule) => {
    console.log("data for format==>", data);
    let formattedData = {
      // id: sessionStorage.getItem("email"),
      id: localStorage.getItem("email"),
      // hrId: "dev.wissda@gmail.com",
      date: "",
      startTime: "",
      endTime: "",
    };
    if (reschedule) {
      formattedData["meetingId"] = mettingDetails?.meetingId;
    }

    const startDate = new Date(data["startTime"]);
    // Format the date using the specified options
    const day = startDate.getDate().toString().padStart(2, "0");
    const month = (startDate.getMonth() + 1).toString().padStart(2, "0");
    const year = startDate.getFullYear();

    const dateString = `${day}-${month}-${year}`;

    formattedData["date"] = dateString;
    formattedData["startTime"] = data["startTime"];
    formattedData["endTime"] = data["endTime"];

    return formattedData;
  };

  const getAvailableSlots = async () => {
    setLoading(true);

    try {
      let data = {
        date: `${date}`,
        id: localStorage.getItem("email"),
      };
      const endpoint = "/candidate/round5/schedule/available_slots";
      let res = await axios.post(endpoint, data);
      console.log("get slots", res);
      handleSlots(res?.data);
      setLoading(false);
    } catch (err) {
      console.log("error in slots", err);
      setLoading(false);
    }
  };

  const handleChangeCalender = (e) => {
    let day = e.getDate();
    let month = e.getMonth();
    let year = e.getFullYear();
    let date = `${year}-${month < 10 ? "0" + (month + 1) : month + 1}-${
      day < 10 ? "0" + day : day
    }`;

    setDate(date);
  };

  const tileDisabled = ({ date, view }) => {
    // Disable Saturdays
    // console.log("date and day", date.getDay());
    if (view === "month") {
      if (date.getDay() === 0 || date.getDay() === 6) {
        return true;
      } else return false;
    }

    return false;
  };

  const handleBookSlot = async () => {
    setViewLoader(true);
    try {
      const slotData = formatBookSlotData(allSlots[selectedSlot], reschedule);
      const endpoint = !reschedule
        ? "/candidate/round5/schedule/create_metting"
        : "/candidate/round5/schedule/update_metting";
      const res = await axios.post(endpoint, slotData);
      if (res) {
        console.log("slot book success", res);
        setViewSchedule(false);
        setViewMettingSuccess(true);
      }
    } catch (err) {
      console.log("error in slot booking", err);
    } finally {
      setViewLoader(false);
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const after7Days = new Date();
  after7Days.setDate(tomorrow.getDate() + 7);

  useEffect(() => {
    setSelectedSlot(-1);
    getAvailableSlots();
  }, [date]);
  return (
    <Popup>
      <div className="scheduleMeeting">
        <div className="close">
          <img
            src={crosssIcon}
            alt="close"
            onClick={() => setViewSchedule(false)}
          />
        </div>
        <div className="text-1">Expert Discussion</div>
        <div className="calendar-wrapper">
          <div className="left">
            <div className="icons-main">
              <div>
                <img src={calendarIcon} alt="" />
              </div>
              <div>Select Date</div>
            </div>
            <div>
              <Calendar
                onChange={(e) => handleChangeCalender(e)}
                value={date}
                minDate={tomorrow}
                maxDate={after7Days}
                tileDisabled={tileDisabled}
              />
            </div>
          </div>
          <div className="right">
            <div className="icons-main">
              <div>
                <img src={clockIcon} alt="" />
              </div>
              <div>Select Time</div>
            </div>

            <div className="slots-main">
              {loading ? (
                <div className={"loaderMain"}>loading..</div>
              ) : (
                <>
                  <TimeSlots
                    calenderValue={date}
                    setSelectedSlot={setSelectedSlot}
                    selectedSlot={selectedSlot}
                    allSlots={allSlots}
                  />
                  <div className="info-item">
                    <span className="icon" style={{ color: "red" }}>
                      <InfoOutlinedIcon />
                    </span>
                    <span className="text2">
                      All times are in <span>{allSlots?.[0]?.utcFormat}</span>
                      <span> {allSlots?.[0]?.timeZone}</span>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="bookslot">
          <button
            onClick={handleBookSlot}
            disabled={selectedSlot > -1 ? false : true}
            style={{ opacity: selectedSlot > -1 ? "1" : ".6" }}
          >
            Book the Slot
          </button>
        </div>
      </div>
    </Popup>
  );
};

const TimeSlots = ({ setSelectedSlot, selectedSlot, allSlots }) => {
  return (
    <div className={"gridContainer"}>
      {allSlots.map((element, index) => (
        <button
          className={`${"gridItem"} ${
            selectedSlot === index ? "selectedItem" : ""
          }`}
          key={index}
          disabled={!element.availability}
          onClick={() => {
            console.log(index);
            index === selectedSlot
              ? setSelectedSlot(-1)
              : setSelectedSlot(index);
          }}
        >
          {element.time}
        </button>
      ))}
    </div>
  );
};

const SuccessMetting = ({ setViewMettingSuccess, handleGetRound5Status }) => {
  return (
    <Popup>
      <div className="successMetting">
        <div className="close">
          <img
            src={crosssIcon}
            alt="close"
            onClick={() => {
              handleGetRound5Status(true);
              setViewMettingSuccess(false);
            }}
          />
        </div>
        <div className="icon-1">
          <img src={greenCalendarIcon} alt="" />
        </div>
        <div className="text-1">Your interview has been scheduled.</div>
        <div className="text-2">Please check your email for more details.</div>
        <div className="button-1">
          <button
            onClick={() => {
              handleGetRound5Status(true);
              setViewMettingSuccess(false);
            }}
          >
            Okay
          </button>
        </div>
      </div>
    </Popup>
  );
};
