import React, { useCallback, useEffect, useState } from 'react'
import ClientProductTour from '../ClientProductTour/ClientProductTour';
import SearchTalent from '../../../Pages/SearchTalent/SearchTalent'
import ClientDataForm from '../../../Pages/ClientDataForm/ClientDataForm'
import { TextField, Button, Box, Grid, Autocomplete, MenuItem, InputAdornment, Dialog, Typography } from '@mui/material';
import './ClientFirstLoginTour.scss'
import { getClientPersonalDetails ,getClientFirstLoginFlag,saveClientData, saveUserOnboarding} from '../../../Common/Client';

const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '1040px',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
  
};

const ClientFirstLoginTour = ({handleisChatBotVisible}) => {

  const [open,setOpen]=useState(false);
  const [isClientDataPresent,setIsClientDataPresent]=useState(false);
  const [tourFlag,setTourFlag]=useState(false);

  const handleSubmitForm=async(data)=>{
    try{
 
    const obj={...data,id:localStorage.getItem("email")};
 
   const res=await saveClientData(obj);
   setIsClientDataPresent(true);
    }
    catch(e){
  
    }
   
  }

  const handleGetStartedClicked=async ()=>{
    try{
      const data={
            "name":localStorage.getItem("email"),
            "userOnboardingFlag":true
        }
      const res=await saveUserOnboarding(data)
      setTourFlag(true);
    setOpen(false)
    handleisChatBotVisible(true)
    }
    catch{

    }
    
  }


  useEffect(()=>{

    const checkUserDetails=async()=>{
      const data={
        id:localStorage.getItem("email")
      }
      const data1={
        name:localStorage.getItem("email")
      }
      const isClientDataPresent=await getClientPersonalDetails(data);
      const userFlag=await getClientFirstLoginFlag(data1);
      
      setIsClientDataPresent(()=>{
        if( isClientDataPresent!==null && Object.keys(isClientDataPresent)?.length===0) return false;
        return true;
      })
      
      
      setTourFlag(userFlag);

      

      if( isClientDataPresent!==null  && Object.keys(isClientDataPresent).length===0 || !userFlag){
        handleisChatBotVisible(false)
        setOpen(true);
      }
    }
   

    checkUserDetails()
  },[])

  return (
<Dialog
  open={open}
  classes={{ paper: (isClientDataPresent && !tourFlag) ? 'dialog-pulse' : undefined }}
  sx={{
    '& .MuiDialog-paper': {
      maxHeight: '640px',
      maxWidth: '1200px',
      minWidth: '1000px',
      padding: '50px',
      borderRadius: '16px',
      
    }
  }}
  BackdropProps={{
    style: {
      backdropFilter: 'blur(8px)', // Apply blur to the backdrop
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly darken the backdrop
    }
  }}
>



  {!isClientDataPresent ? (
    <div>
      
      <ClientDataForm handleSubmitForm={handleSubmitForm} />
    </div>
  ) : (
    (isClientDataPresent && !tourFlag )&& <ClientProductTour handleGetStartedClicked={handleGetStartedClicked} />
  )}
  
</Dialog>

  
  
  
  )
}

export default ClientFirstLoginTour