import { DropDownBox } from "./ProfessionalDetails2";
import "./ProfessionalDetails.scss";
import { useEffect, useState } from "react";
import deleteIcon from "../../../Assets/SVG/delete.svg";
import { toast } from "react-toastify";
import exclamationSquare from "../../../Assets/SVG/exclamation-square.svg";
import axios from "axios";
import { getAllSkillsData } from "../../../Common/getAllSkillsData";

export const DataScience = ({
  disableOptions,
  setSkillsForm,
  skillsForm,
  skillPageData,
  yearsOption,
  errorFields,
}) => {
  const [backendSkills, setBackendSkills] = useState([
    // ...skillPageData?.[1]?.subfield?.[0]?.dev,
  ]);

  const [languagesAvailable, setLanguagesAvailable] = useState([]);
  const [databasesAvailable, setDatabasesAvailable] = useState([]);

  const [addLanguagesOption, setAddLanguagesOption] = useState([0]);
  const [framweworkOptions1, setFrameworkOptions1] = useState([]);
  const [addframeworksOption1, setAddFrameworksOption1] = useState([]);
  const [framweworkOptions2, setFrameworkOptions2] = useState([]);
  const [addframeworksOption2, setAddFrameworksOption2] = useState([]);

  const [addDatabasesOption, setAddDatabasesOption] = useState([0]);

  const handleAvailableLanguages = () => {
    let temp = backendSkills?.[0]?.languages?.map((item) => item.languages) || [];
    // console.log("temp==>", temp);
    setLanguagesAvailable([...temp]);
  };

  const handleAvailableDatabases = () => {
    let temp = backendSkills?.[1]?.languages?.map((item) => item.languages) || [];
    setDatabasesAvailable([...temp]);
  };

  const handleLanguageChange = (item, index) => {
    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempBackEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    console.log("temp==>", tempBackEndLanguages);

    let newEntry = {
      language: item,
      proficiency: "",
      checked: false,
      frameworks: [],
    };
    if (
      tempBackEndLanguages?.[index] &&
      tempBackEndLanguages?.[index]?.language
    ) {
      tempBackEndLanguages[index] = newEntry;
    } else {
      tempBackEndLanguages.push(newEntry);
    }

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Data Science",
        subfield: "Data Science Engineer",
        skillsdev: [
          {
            skill: "Data Science",
            languages: [...tempBackEndLanguages],
          },
          ...skillsForm.skills.skillsdev.slice(1),
        ],
      },
    };

    console.log("selectedValues==>", newSkillsUpdate);

    setSkillsForm({ ...newSkillsUpdate });
  };

  const handleShowValueForField = (index) => {
    const val =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.language;
    return val;
  };

  const handleAddDeleteLanguage = () => {
    let temp = { ...skillsForm };

    let tempBackEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];
    if (tempBackEndLanguages?.length > 1) tempBackEndLanguages.pop();

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Data Science",
        subfield: "Data Science Engineer",
        skillsdev: [
          {
            skill: "Data Science",
            languages: [...tempBackEndLanguages],
          },
          ...skillsForm.skills.skillsdev.slice(1),
        ],
      },
    };

    setSkillsForm({ ...newSkillsUpdate });
    setAddLanguagesOption([0]);
  };

  const handleLanguageYear = (item, index) => {
    let temp = { ...skillsForm };

    let tempBackEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (
      tempBackEndLanguages?.[index] &&
      tempBackEndLanguages?.[index]?.language
    ) {
      let newEntry = {
        language: tempBackEndLanguages?.[index]?.language,
        proficiency: item,
        checked: tempBackEndLanguages?.[index]?.checked ? true : false,
        frameworks: [...(tempBackEndLanguages?.[index]?.frameworks ?? [])],
      };
      tempBackEndLanguages[index] = newEntry;
    } else {
      toast("Select a language");
      return;
    }

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Data Science",
        subfield: "Data Science Engineer",
        skillsdev: [
          {
            skill: "Data Science",
            languages: [...tempBackEndLanguages],
          },
          ...skillsForm.skills.skillsdev.slice(1),
        ],
      },
    };

    setSkillsForm({ ...newSkillsUpdate });
  };

  const handleValueForLanguageYear = (index) => {
    const val =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.proficiency;
    return val;
  };

  const handleCheckLanguage = (index) => {
    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempBackEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    for (let i = 0; i < tempBackEndLanguages.length; i++) {
      if (i === index) {
        if (tempBackEndLanguages[i].checked) {
          tempBackEndLanguages[i].checked = false;
        } else {
          tempBackEndLanguages[i].checked = true;
        }
      } else {
        tempBackEndLanguages[i].checked = false;
      }
    }

    // for (let i = 0; i < tempBackEndLanguages.length; i++) {
    //   console.log("updated==>", tempBackEndLanguages[i].checked);
    // }

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Data Science",
        subfield: "Data Science Engineer",
        skillsdev: [
          {
            skill: "Data Science",
            languages: [...tempBackEndLanguages],
          },
          ...skillsForm.skills.skillsdev.slice(1),
        ],
      },
    };

    console.log("selectedValues==>", newSkillsUpdate);

    setSkillsForm({ ...newSkillsUpdate });
  };

  const languageCheckedValu = (index) => {
    const val =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.language &&
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]?.checked;
    return val;
  };

  const handleChooseOptionsForFramweworks = () => {
    let temp = { ...skillsForm };

    let tempBackEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (tempBackEndLanguages?.length > 0) {
      if (tempBackEndLanguages[0] && tempBackEndLanguages[0].language) {
        const tempLanguage = tempBackEndLanguages?.[0]?.language;
        // Find the language object that matches tempLanguage
        // console.log("for 0==>", backendSkills?.[0]?.languages.);

        const languageObject = backendSkills?.[0]?.languages.find(
          (lang) => lang.languages === tempLanguage
        );
        // If a match is found, console the frameworks
        if (languageObject) {
          // console.log(languageObject.frameworks);
          setFrameworkOptions1([...languageObject.framework]);
        } else {
          console.log("No matching language found");
        }
      }
      if (tempBackEndLanguages[1] && tempBackEndLanguages[1].language) {
        // console.log("for 0==>", tempBackEndLanguages?.[0]?.language);
        const tempLanguage = tempBackEndLanguages?.[1]?.language;
        // Find the language object that matches tempLanguage
        const languageObject = backendSkills?.[0]?.languages.find(
          (lang) => lang.languages === tempLanguage
        );
        // If a match is found, console the frameworks
        if (languageObject) {
          // console.log(languageObject.frameworks);
          setFrameworkOptions2([...languageObject.framework]);
        } else {
          console.log("No matching language found");
        }
      }
    } else {
      setFrameworkOptions1([]);
      setAddFrameworksOption1([]);
      setAddFrameworksOption2([]);
      setAddFrameworksOption2([]);
    }
  };

  const handleDeleteFrameworks = (fwkIndex, languageIndex) => {
    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempBackEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (
      tempBackEndLanguages?.[languageIndex] &&
      tempBackEndLanguages?.[languageIndex]?.language
    ) {
      let allfwks = [
        ...(tempBackEndLanguages?.[languageIndex]?.frameworks ?? []),
      ];

      const i = fwkIndex; /* your index value here */

      // Check if the index is within the bounds of the array
      if (i >= 0 && i < allfwks.length) {
        allfwks.splice(i, 1); // Removes 1 element at index i
      }

      // console.log("length==.", allfwks.length);

      // Directly update the frameworks property
      if (tempBackEndLanguages[languageIndex]) {
        tempBackEndLanguages[languageIndex].frameworks = allfwks;
      }

      let newSkillsUpdate = {
        id: skillsForm?.id,
        skills: {
          field: "Data Science",
          subfield: "Data Science Engineer",
          skillsdev: [
            {
              skill: "Data Science",
              languages: [...tempBackEndLanguages],
            },
            ...skillsForm.skills.skillsdev.slice(1),
          ],
        },
      };

      // console.log("new fwk added==>", newSkillsUpdate);

      setSkillsForm({ ...newSkillsUpdate });

      //   console.log("languageIndex==>", languageIndex);
      //   console.log("allfwks==>", allfwks);

      if (languageIndex === 0) {
        // console.log("inside first");
        if (allfwks.length === 0) {
          setAddFrameworksOption1([]);
        } else if (allfwks.length === 1) {
          setAddFrameworksOption1([0]);
        } else if (allfwks.length === 2) {
          setAddFrameworksOption1([0, 1]);
        }
      } else if (languageIndex === 1) {
        // console.log("inside second");
        if (allfwks.length === 0) {
          setAddFrameworksOption2([]);
        } else if (allfwks.length === 1) {
          setAddFrameworksOption2([0]);
        } else if (allfwks.length === 2) {
          setAddFrameworksOption2([0, 1]);
        }
      }
    }
  };

  const handleSelectFramework = (val, fwkIndex, languageIndex) => {
    console.log("handleSelectFramework==>", val, fwkIndex, languageIndex);

    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempBackEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (
      tempBackEndLanguages?.[languageIndex] &&
      tempBackEndLanguages?.[languageIndex]?.language
    ) {
      let allfwks = [
        ...(tempBackEndLanguages?.[languageIndex]?.frameworks ?? []),
      ];

      if (allfwks[fwkIndex] && allfwks[fwkIndex].framework) {
        allfwks[fwkIndex].framework = val;
      } else {
        let newFwkEntry = {
          checked: false,
          framework: val,
          proficiency: "",
        };
        allfwks.push(newFwkEntry);
      }

      // Directly update the frameworks property
      if (tempBackEndLanguages[languageIndex]) {
        tempBackEndLanguages[languageIndex].frameworks = allfwks;
      }

      let newSkillsUpdate = {
        id: skillsForm?.id,
        skills: {
          field: "Data Science",
          subfield: "Data Science Engineer",
          skillsdev: [
            {
              skill: "Data Science",
              languages: [...tempBackEndLanguages],
            },
            ...skillsForm.skills.skillsdev.slice(1),
          ],
        },
      };

      console.log("new fwk added==>", newSkillsUpdate);

      setSkillsForm({ ...newSkillsUpdate });
    } else {
      toast("select a language");
    }
  };

  const handleFrameworkYearSelect = (val, fwkIndex, languageIndex) => {
    console.log("handleSelectFramework==>", val, fwkIndex, languageIndex);

    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempBackEndLanguages = [
      ...(temp?.skills?.skillsdev?.[0]?.languages ?? []),
    ];

    if (
      tempBackEndLanguages?.[languageIndex] &&
      tempBackEndLanguages?.[languageIndex]?.language
    ) {
      let allfwks = [
        ...(tempBackEndLanguages?.[languageIndex]?.frameworks ?? []),
      ];

      if (allfwks[fwkIndex] && allfwks[fwkIndex].framework) {
        allfwks[fwkIndex]["proficiency"] = val;
      } else {
        toast("Selected framework");
      }

      // Directly update the frameworks property
      if (tempBackEndLanguages[languageIndex]) {
        tempBackEndLanguages[languageIndex].frameworks = allfwks;
      }

      let newSkillsUpdate = {
        id: skillsForm?.id,
        skills: {
          field: "Data Science",
          subfield: "Data Science Engineer",
          skillsdev: [
            {
              skill: "Data Science",
              languages: [...tempBackEndLanguages],
            },
            ...skillsForm.skills.skillsdev.slice(1),
          ],
        },
      };

      console.log("new fwk added==>", newSkillsUpdate);

      setSkillsForm({ ...newSkillsUpdate });
    } else {
      toast("select a language");
    }
  };

  const handleDatabaseSelect = (item, index) => {
    // console.log("language==>", item, index);
    let temp = { ...skillsForm };

    let tempDataBases = [...(temp?.skills?.skillsdev?.[1]?.languages ?? [])];

    // console.log("temp==>", tempDataBases);

    let newEntry = {
      language: item,
      proficiency: "",
      checked: false,
    };

    if (tempDataBases?.[index] && tempDataBases?.[index]?.language) {
      tempDataBases[index] = newEntry;
    } else {
      tempDataBases.push(newEntry);
    }

    let newSkillsUpdate = {
      id: skillsForm?.id,
      skills: {
        field: "Data Science",
        subfield: "Data Science Engineer",
        skillsdev: [
          skillsForm.skills.skillsdev[0],
          {
            skill: "Database",
            languages: [...tempDataBases],
          },
          ...skillsForm.skills.skillsdev.slice(2),
        ],
      },
    };

    console.log("selectedValues==>", newSkillsUpdate);

    setSkillsForm({ ...newSkillsUpdate });
  };

  const handleDatabaseYears = (item, index) => {
    let temp = { ...skillsForm };

    let tempDataBases = [...(temp?.skills?.skillsdev?.[1]?.languages ?? [])];

    if (tempDataBases?.[index] && tempDataBases?.[index]?.language) {
      let newEntry = {
        language: tempDataBases?.[index]?.language,
        proficiency: item,
        checked: tempDataBases?.[index]?.checked ? true : false,
      };
      tempDataBases[index] = newEntry;
    } else {
      toast("Select a database");
      return;
    }

    let newSkillsUpdate = {
      id: skillsForm?.id,

      skills: {
        field: "Data Science",
        subfield: "Data Science Engineer",
        skillsdev: [
          skillsForm.skills.skillsdev[0],
          {
            skill: "Database",
            languages: [...tempDataBases],
          },
          ...skillsForm.skills.skillsdev.slice(2),
        ],
      },
    };

    setSkillsForm({ ...newSkillsUpdate });
  };

  const handleAddDeleteDatabase = () => {
    let temp = { ...skillsForm };

    let tempDataBases = [...(temp?.skills?.skillsdev?.[1]?.languages ?? [])];

    if (tempDataBases?.length > 1) tempDataBases.pop();

    let newSkillsUpdate = {
      id: skillsForm?.id,

      skills: {
        field: "Data Science",
        subfield: "Data Science Engineer",
        skillsdev: [
          skillsForm.skills.skillsdev[0],
          {
            skill: "Database",
            languages: [...tempDataBases],
          },
          ...skillsForm.skills.skillsdev.slice(2),
        ],
      },
    };

    setSkillsForm({ ...newSkillsUpdate });
    setAddDatabasesOption([0]);
  };

  const handleDataBaseCheck = (index) => {
    let temp = { ...skillsForm };

    let tempDataBases = [...(temp?.skills?.skillsdev?.[1]?.languages ?? [])];

    for (let i = 0; i < tempDataBases.length; i++) {
      if (i === index) {
        if (tempDataBases[i].checked) {
          tempDataBases[i].checked = false;
        } else {
          tempDataBases[i].checked = true;
        }
      } else {
        tempDataBases[i].checked = false;
      }
    }

    let newSkillsUpdate = {
      id: skillsForm?.id,

      skills: {
        field: "Data Science",
        subfield: "Data Science Engineer",
        skillsdev: [
          skillsForm.skills.skillsdev[0],
          {
            skill: "Database",
            languages: [...tempDataBases],
          },
          ...skillsForm.skills.skillsdev.slice(2),
        ],
      },
    };

    setSkillsForm({ ...newSkillsUpdate });
  };

  const handleDatabaseCheckVal = (index) => {
    const val =
      skillsForm?.skills?.skillsdev?.[1]?.languages?.[index]?.language &&
      skillsForm?.skills?.skillsdev?.[1]?.languages?.[index]?.checked;
    return val;
  };

  const handleDefaultFrameworkAndLanguage = () => {
    let temp1 = skillsForm?.skills?.skillsdev?.[0]?.languages?.length;
    let temp2 = [];
    for (let i = 0; i < temp1; i++) {
      temp2.push(0);
    }

    if (temp2.length === 0) {
      setAddLanguagesOption([0]);
    } else {
      setAddLanguagesOption([...temp2]);
    }

    let temp3 =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[0]?.frameworks?.length;
    let temp4 = [];
    for (let i = 0; i < temp3; i++) {
      temp4.push(0);
    }
    setAddFrameworksOption1([...temp4]);

    let temp5 =
      skillsForm?.skills?.skillsdev?.[0]?.languages?.[1]?.frameworks?.length;
    let temp6 = [];
    for (let i = 0; i < temp5; i++) {
      temp6.push(0);
    }
    setAddFrameworksOption2([...temp6]);

    let temp7 = skillsForm?.skills?.skillsdev?.[1]?.languages?.length;
    let temp8 = [];
    for (let i = 0; i < temp7; i++) {
      temp8.push(0);
    }

    if (temp8.length === 0) {
      setAddDatabasesOption([0]);
    } else {
      setAddDatabasesOption([...temp8]);
    }
  };

  const allSkillsData = async () => {
    try {
      const res = await getAllSkillsData(
        skillsForm?.skills?.field,
        skillsForm?.skills?.subfield
      );
      setBackendSkills([...res?.data?.dev]);
    } catch (err) {
      console.log("getAllSkillsData error==>", err);
    }
  };

  useEffect(() => {
    handleChooseOptionsForFramweworks();
  }, [skillsForm]);

  useEffect(() => {
    handleAvailableLanguages();
    handleAvailableDatabases();
  }, [backendSkills]);

  useEffect(() => {
    handleDefaultFrameworkAndLanguage();
    allSkillsData()
  }, []);

  return (
    <div className="skill-item">
      <div className="each-skill">
        <div>{backendSkills?.[0]?.["header"]}</div>
        <div>{backendSkills?.[0]?.["subheader"]}</div>
        {addLanguagesOption?.map((item, index) => (
          <div className="language-experience" key={index}>
            <div
              className="language-and-years"
              style={{ alignItems: "center", marginBottom: "12px" }}
            >
              <input
                type="checkbox"
                className="check-item"
                onChange={() =>
                  disableOptions ? "" : handleCheckLanguage(index)
                }
                // value={languageCheckedValu(index)}
                checked={languageCheckedValu(index)}
              />
              <DropDownBox
                options={languagesAvailable}
                placeholder="Language"
                value={handleShowValueForField(index)}
                handleChange={(val) => handleLanguageChange(val, index)}
                disableOptions={disableOptions}
              />
              <DropDownBox
                options={yearsOption}
                placeholder="Relevant Experience"
                value={handleValueForLanguageYear(index)}
                handleChange={(val) => handleLanguageYear(val, index)}
                disableOptions={disableOptions}
              />

              {index > 0 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    disableOptions ? "" : handleAddDeleteLanguage()
                  }
                >
                  {!disableOptions && <img src={deleteIcon} alt="" />}
                </div>
              )}
              {languageCheckedValu(index) ? (
                <div className="selected">Selected For Assessment</div>
              ) : (
                errorFields.dataScience &&
                index === 0 && (
                  <div className="errorField">
                    <div>
                      <img src={exclamationSquare} alt="" />
                    </div>
                    <div>Please select a language for assessment</div>
                  </div>
                )
              )}
            </div>

            {index === 0 && (
              <div className="framework-experience">
                {addframeworksOption1?.map((framweork, fwkIndex) => (
                  <div className="framework-and-years" key={fwkIndex}>
                    {/* <input type="checkbox" className="check-item" /> */}
                    <DropDownBox
                      options={framweworkOptions1}
                      disableOptions={disableOptions}
                      placeholder="Framework"
                      value={
                        skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]
                          ?.frameworks?.[fwkIndex]?.framework || ""
                      }
                      handleChange={(val) =>
                        handleSelectFramework(val, fwkIndex, index)
                      }
                    />
                    <DropDownBox
                      options={yearsOption}
                      disableOptions={disableOptions}
                      placeholder="Relevant Experience"
                      value={
                        skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]
                          ?.frameworks?.[fwkIndex]?.proficiency || ""
                      }
                      handleChange={(val) =>
                        handleFrameworkYearSelect(val, fwkIndex, index)
                      }
                    />

                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        disableOptions
                          ? ""
                          : handleDeleteFrameworks(fwkIndex, index)
                      }
                    >
                      {!disableOptions && <img src={deleteIcon} alt="" />}
                    </div>
                  </div>
                ))}
                {addframeworksOption1?.length < 2 && !disableOptions && (
                  <div
                    className="add-language"
                    onClick={() =>
                      disableOptions
                        ? ""
                        : setAddFrameworksOption1([...addframeworksOption1, 0])
                    }
                  >
                    {" "}
                    {!disableOptions && (
                      <>
                        {" "}
                        <span>+</span> Add Frameworks
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            {index === 1 && (
              <div className="framework-experience">
                {addframeworksOption2?.map((framweork, fwkIndex) => (
                  <div className="framework-and-years" key={fwkIndex}>
                    {/* <input type="checkbox" className="check-item" /> */}
                    <DropDownBox
                      options={framweworkOptions2}
                      disableOptions={disableOptions}
                      placeholder="Framework"
                      value={
                        skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]
                          ?.frameworks?.[fwkIndex]?.framework || ""
                      }
                      handleChange={(val) =>
                        handleSelectFramework(val, fwkIndex, index)
                      }
                    />
                    <DropDownBox
                      options={yearsOption}
                      disableOptions={disableOptions}
                      placeholder="Relevant Experience"
                      value={
                        skillsForm?.skills?.skillsdev?.[0]?.languages?.[index]
                          ?.frameworks?.[fwkIndex]?.proficiency || ""
                      }
                      handleChange={(val) =>
                        handleFrameworkYearSelect(val, fwkIndex, index)
                      }
                    />

                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        disableOptions
                          ? ""
                          : handleDeleteFrameworks(fwkIndex, index)
                      }
                    >
                      {!disableOptions && <img src={deleteIcon} alt="" />}
                    </div>
                  </div>
                ))}
                {addframeworksOption2?.length < 2 && !disableOptions && (
                  <div
                    className="add-language"
                    onClick={() =>
                      disableOptions
                        ? ""
                        : setAddFrameworksOption2([...addframeworksOption2, 0])
                    }
                  >
                    {" "}
                    <span>+</span> Add Frameworks
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
        {/* {addLanguagesOption?.length < 2 && (
          <div
            className="add-language"
            onClick={() =>
              disableOptions ? "" : setAddLanguagesOption([0, 1])
            }
          >
            {" "}
            {!disableOptions && (
              <>
                {" "}
                <span>+</span> Add Language
              </>
            )}
          </div>
        )} */}
      </div>
      {/* databases */}
      <div className="each-skill">
        <div>{backendSkills?.[1]?.["header"]}</div>
        <div>{backendSkills?.[1]?.["subheader"]}</div>
        {addDatabasesOption?.map((item, index) => (
          <div className="language-experience" key={index}>
            <div
              className="language-and-years"
              style={{ alignItems: "center", marginBottom: "12px" }}
            >
              <input
                type="checkbox"
                className="check-item"
                onChange={() =>
                  disableOptions ? "" : handleDataBaseCheck(index)
                }
                checked={handleDatabaseCheckVal(index)}
              />
              <DropDownBox
                options={databasesAvailable}
                disableOptions={disableOptions}
                placeholder="Database"
                value={
                  skillsForm?.skills?.skillsdev?.[1]?.languages?.[index]
                    ?.language
                }
                handleChange={(val) => handleDatabaseSelect(val, index)}
              />
              <DropDownBox
                options={yearsOption}
                disableOptions={disableOptions}
                placeholder="Relevant Experience"
                value={
                  skillsForm?.skills?.skillsdev?.[1]?.languages?.[index]
                    ?.proficiency
                }
                handleChange={(val) => handleDatabaseYears(val, index)}
              />

              {index > 0 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    disableOptions ? "" : handleAddDeleteDatabase()
                  }
                >
                  {!disableOptions && <img src={deleteIcon} alt="" />}
                </div>
              )}

              {handleDatabaseCheckVal(index) ? (
                <div className="selected">Selected For Assessment</div>
              ) : (
                errorFields.dataScienceDatabase &&
                index === 0 && (
                  <div className="errorField">
                    <div>
                      <img src={exclamationSquare} alt="" />
                    </div>
                    <div>Please select a database for assessment</div>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
        {/* {addDatabasesOption?.length < 2 && !disableOptions && (
          <div
            className="add-language"
            onClick={() =>
              disableOptions ? "" : setAddDatabasesOption([0, 1])
            }
          >
            {" "}
            <span>+</span> Add Database
          </div>
        )} */}
      </div>
    </div>
  );
};
