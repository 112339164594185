export const round1Instructions = [
  {
    title: "Round 1 Instructions",
    desc: [
      {
        desc: [
          "This is an MCQ (Multiple Choice Questions) based test.",
          "The test consists of Data structure analysis and tech-stack fundamentals.",
          "It is mandatory to attempt all the questions. There is no negative marking for wrong answers.",
          // "Time to complete the test can vary from 45-60 minutes depending on the skills selected.",
          "Once the test begins, it must be completed in one session without the option to pause and resume later.",
          "Each MCQ has 4 options with one correct answer.",
          "Please revise before submitting the answer. The answers can not be changed once you have submitted them.",
        ],
      },
    ],
  },
  {
    title: "General Instructions",
    desc: [
      {
        subtitle: "Before you start the test",
        desc: [
          "Make sure you have a good Internet connection.",
          "Requirement Use Latest version of Chrome/Safari.",
          // "Use Next and Back to move between the questions. Use Submit submitting of all answers for review",
          // "Once each test round is started, it must be completed in one sitting, you cannot abort and resume later ",
        ],
      },
      // {
      //   subtitle: "Regarding all Rounds",
      //   desc: [
      //     "All rounds can be attended in different sitting or together.",
      //     "Questions would be dynamic based on how you answer.",
      //   ],
      // },
      {
        subtitle: "During the test",
        desc: [
          "Click <span>'Next'</span> to move to the next question.",
          "You can’t go back to the previous question once the answer is submitted.",
          "Click on the <span>'Submit'</span> button at the end of the test to submit all the answers.",
          "If you encounter any problem accessing or submitting the test, please record the screen.",
          "Send the recording to <span> assessment@zeero.us</span> if you need assistance.",
        ],
      },
    ],
  },
];


export const round1InstructionsDataScience = [
  {
    title: "Round 1 Instructions",
    desc: [
      {
        desc: [
          "This is an MCQ (Multiple Choice Questions) based test.",
          "The test consists of Data Science and  Database fundamentals.",
          "It is mandatory to attempt all the questions. There is no negative marking for wrong answers.",
          "Once the test begins, it must be completed in one session without the option to pause and resume later.",
          "Each MCQ has 4 options with one correct answer.",
          "Please revise before submitting the answer. The answers can not be changed once you have submitted them.",
        ],
      },
    ],
  },
  {
    title: "General Instructions",
    desc: [
      {
        subtitle: "Before you start the test",
        desc: [
          "Make sure you have a good Internet connection.",
          "Requirement Use Latest version of Chrome/Safari.",
        ],
      },

      {
        subtitle: "During the test",
        desc: [
          "Click <span>'Next'</span> to move to the next question.",
          "You can’t go back to the previous question once the answer is submitted.",
          "Click on the <span>'Submit'</span> button at the end of the test to submit all the answers.",
          "If you encounter any problem accessing or submitting the test, please record the screen.",
          "Send the recording to <span> assessment@zeero.us</span> if you need assistance.",
        ],
      },
    ],
  },
];

export const betweenTestInstructions = {
  title: "Instructions",
  desc: [
    `Click "Next" to move to the next question.`,
    "You can’t go back to the previous question once the answer is submitted.",
    `Click on the "Submit" at the end of the test to submit all the answers.`,
    "If you encounter any problem accessing or submitting the test, please record the screen and send it to <span>assessment@zeero.us.</span>",
  ],
};

export const fullScreenExit = {
  title: "Warning",
  title2: "You have broken either of the following rules of the test.",
  title3: "Rules:",
  desc: [
    "You can’t move from one screen to another while the test is going on.",
    "You cannot refresh the screen while the test is going on.",
    "You cannot copy or paste anything in the screen section.",
    "You cannot exit full screen.",
    "You cannot leave the test window.",
  ],
};

export const multipleTabSwitch = {
  title: "Termination",
  desc: "You seemed to have gone out of the our view multiple times Contact support team for a comeback plan.",
  button: "Okay",
};

export const finalQuestion = {
  title: "Are you sure you want to submit the test? ",
  button1: "Cancel",
  button2: "Submit",
};
