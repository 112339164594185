import React from 'react'
import { Stack,Card, CardContent, Grid, Typography,Avatar,Box ,Chip} from '@mui/material';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import BusinessIcon from '@mui/icons-material/Business';
import { addRemoveShortlist } from '../../../Common/Client';
import withGradient from './GradientIcon/withGradient';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    //backgroundColor: theme.palette.common.black,
    backgroundColor:'#062B6E'
  },
}));
const CandidateCard = ({handleCardClick,candidate,shortlistedCandidates,getLatestData}) => {


  return (
    <Card
   
    sx={{
      background: 'rgba(255, 255, 255, 1)',
      maxWidth: '340px',
      height: '250px',
      cursor:'pointer',
      gap: '0px',
      borderRadius: '8px',
      padding:{lg:'12px',sm:'10px',xs:'10px',md:'10px',xl:'12px'},
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)', // Shadow in all 4 directions
    }}
  >
   <Section1 candidate={candidate}/>
   <Section2  getLatestData={getLatestData} candidate={candidate} handleCardClick={handleCardClick} shortlistedCandidates={shortlistedCandidates}/>
   <Section3 candidate={candidate} />
   
  </Card>
  )
}

const Section1=({candidate})=>{
    return(
        <Grid sx={{display:'flex',gap:'6px',height:'20px'}}> 
            <Grid item>
            <AvailibiltyTab availbility={candidate?.availability || " "}/>
            </Grid>
            {/* <Grid item>
            <AvailibiltyTab availbility={candidate?.availability || " "}/>
            </Grid> */}
        </Grid>
    )
}

const Section2=({candidate,handleCardClick,shortlistedCandidates,getLatestData})=>{
    return(
        <Grid sx={{display:'flex',marginTop:'14px',gap:'20px',height:'143px'}}>
            <Grid item>
            <Section2Image candidate={candidate} shortlistedCandidates={shortlistedCandidates} getLatestData={getLatestData}/>
            </Grid>
            <Grid item>
                <Section2Data  candidate={candidate} handleCardClick={handleCardClick}/>
            </Grid>

        </Grid>
    )
}

const Section3=({candidate})=>{
  return(
<CustomChips items={candidate?.skills || candidate?.skills || []} />
  )
}

const Section2Image=({candidate,shortlistedCandidates,getLatestData})=>{
  const addOrRemoveShortlist=async(e,developerEmail,bool)=>{
    e.preventDefault()
    try{
      const data={
        "clientEmail" : localStorage.getItem("email"),
        "developerEmail" : developerEmail,
        "add" : bool
    }
      const res=await addRemoveShortlist(data);
      getLatestData(developerEmail,bool);
    }
    catch(e){

    }
  }
    return(
        <Grid container justifyContent="center">
      <Box position="relative" display="inline-flex">
        {/* Circular Image */}
        <Avatar
          alt="Profile Image"
          src="path_to_image.jpg" // Replace with your image path
          sx={{ width: "102px", height: "102px" }}
        />
        {/* Bookmark Icon positioned on the border of the image */}
     
{candidate?.shortlist ? (
  <BootstrapTooltip title="Remove Shortlist">
  <Box
  position="absolute"
  sx={{
    right: '32px', // Slight adjustment to place the icon on the border
    bottom: '-17px', // Slight adjustment to place the icon on the border
    backgroundColor: 'blue', // You can adjust the color to match your design
    borderRadius: '50%',
    padding: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    height:'36px',
    width:'36px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  }}
>
  <BookmarkOutlinedIcon
  onClick={(e) => {
    addOrRemoveShortlist(e, candidate?.email, false); // Show first icon when candidate is not shortlisted
  }}
  sx={{ fontSize: 30, color: 'white', height: '24px', width: '24px' }}
/>
</Box>
</BootstrapTooltip>
) : (
  <BootstrapTooltip title="Shortlist Profile">
  <Box
          position="absolute"
          sx={{
            right: '32px', // Slight adjustment to place the icon on the border
            bottom: '-17px', // Slight adjustment to place the icon on the border
            backgroundColor: 'rgba(141, 141, 143, 1)', // You can adjust the color to match your design
            borderRadius: '50%',
            padding: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            height:'36px',
            width:'36px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}
        >
 
  <BookmarkOutlinedIcon
  onClick={(e) => {
    addOrRemoveShortlist(e, candidate?.email, true); // Show second icon when candidate is already shortlisted
  }}
  sx={{ fontSize: 30, color: 'white', height: '24px', width: '24px' }}
/>
</Box>
</BootstrapTooltip >
)}



      
      </Box>
    </Grid>
    )
}

const Section2Data=({candidate,handleCardClick})=>{
  function maskName(fullName) {
    
    // Split the full name into first and last names
    const [firstName, lastName] = fullName?.split(" ") || "Anil Kumar";
  
    // Mask the first name (keep the first letter, replace the rest with '*')
    const maskedFirstName = firstName?.[0] + '*'.repeat(firstName?.length - 1);
  
    // Mask the last name (keep the first letter, replace the rest with '*')
    const maskedLastName = lastName?.[0] + '*'.repeat(lastName?.length - 1);
  
    // Return the masked name
    return `${maskedFirstName} ${maskedLastName}`;
  }

    return(
        <Box  onClick={(e)=>{
          
          handleCardClick(candidate?.email,candidate.shortlist)
        }}
        sx={{ }}>
      {/* Main Title */}
      <Typography
  variant="h6"
  sx={{
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '19.5px',
    
    color: 'rgba(6, 43, 110, 1)',
  }}
>
{candidate?.industryDomain|| candidate?.role}
</Typography>
      {/* Name (Masked) */}
      <Typography
  variant="h5"
  sx={{
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '0.2px',
    
    background: 'linear-gradient(110.71deg, #062B6E 15.87%, #0B52D4 60.65%, #4859F3 89.14%)',
    WebkitBackgroundClip: 'text', // Ensures gradient is applied only to text
    WebkitTextFillColor: 'transparent', // Makes text transparent so gradient shows
  }}
>
  {candidate?.developerName || ""}
{/* {maskName(candidate?.developerName || "Candidate")} */}
</Typography>


      {/* Details Grid */}
      <Grid container direction="column" sx={{marginTop:'-10px'}}  spacing={2} >
        {/* Location Row */}
        <Grid item container alignItems="center">
          <Details icon={LocationOnOutlinedIcon} text={candidate?.location} />
       
        </Grid>

        {/* Years of Experience Row */}
        <Grid item container alignItems="center">
        <Details icon={WorkOutlineIcon} text={candidate?.experienceInYears} />
          
        </Grid>

        {/* Industry/Technology Row */}
        <Grid item container alignItems="center">
        <Details icon={BusinessIcon} text={candidate?.role} />
         
          
        </Grid>
       
      </Grid>
    </Box>
    )

}
const AvailibiltyTab=({availbility})=>{
    return(

<Card
  sx={{
    padding:'4px',
    height: '24px',
    backgroundColor: "rgba(239, 240, 246, 1)",
    borderRadius: '12px',
    display: 'flex', // Make the card content a flexbox
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
  }}
>
  <Stack 
    sx={{ 
     
      textAlign: 'center', // Ensure content is centered
      padding:0,
      display: 'flex', 
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
    }}
  >
    <Typography
      variant="body2"
      sx={{
        fontFamily: 'Poppins',
        fontSize: '9px',
        fontWeight: 400,
        lineHeight: '13.5px',
        letterSpacing: '0.2px',
        color: 'rgba(6, 43, 110, 1)',
        alignSelf:'center'
      }}
    >
      Availability: {" "}
      <Typography
        component="span" // Using span so that it stays inline
        sx={{
          fontSize: '10px', // Increase the font size for availability
          fontWeight: 500,  // Optional: adjust weight if needed
           alignSelf:'center'
        }}
      >
        {availbility}
      </Typography>
    </Typography>
  </Stack>
</Card>

    )
}

const Details = ({ icon: IconComponent, text }) => {
  const GradientIcon = withGradient(IconComponent); // Wrap the passed icon with the gradient

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <GradientIcon
        sx={{
          width: '20px',
          height: '20px',
          position: 'relative',
        }}
      />
      <Typography
        variant="body1"
        sx={{
          marginLeft: '4px',
          color: 'rgba(6, 43, 110, 1)',
          display: 'inline-block',
          wordBreak: 'break-word',
          fontFamily: 'Poppins',
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: '19.5px',
          letterSpacing: '0.2px',
          textAlign: 'left',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const CustomChips = ({ items }) => {
 

  const visibleItems = items.slice(0, 3); // Show up to 3 items
  const remainingItemsCount = items.length - 3; // Calculate how many items are left

  return (
    <Box sx={{ display: 'flex', gap: '8px',marginTop:'16px' }}>
      {/* Render the first 1-3 items */}
      {visibleItems.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '76px',
            height: '30px',
            backgroundColor: 'rgba(239, 240, 246, 1)',
            borderRadius: '4px',
            padding: '8px',
            boxSizing: 'border-box',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0.2px',
              color: 'rgba(6, 43, 110, 1)',
              whiteSpace: 'nowrap', // Prevent text wrapping
              overflow: 'hidden',
              textOverflow: 'ellipsis', // Show "..." if text is too long
            }}
          >
            {item.length > 8 ? `${item.slice(0, 8)}...` : item} {/* Truncate to 8 characters */}
          </Typography>
        </Stack>
      ))}

      {/* Render the +X Stack if there are more than 3 items */}
      {remainingItemsCount > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '76px',
            height: '30px',
            backgroundColor: 'rgba(239, 240, 246, 1)',
            borderRadius: '4px 0px 0px 0px',
            padding: '0px 8px',
            boxSizing: 'border-box',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0.2px',
              color: 'rgba(6, 43, 110, 1)',
              whiteSpace: 'nowrap', // Prevent text wrapping
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            +{remainingItemsCount}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};




export default CandidateCard