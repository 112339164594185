import round1Image from "../Assets/SVG/assessmentHome/round1.svg";
import round2Image from "../Assets/SVG/assessmentHome/round2.svg";
import round3Image from "../Assets/SVG/assessmentHome/round3.svg";
import round4Image from "../Assets/SVG/assessmentHome/round4.svg";
import round5Image from "../Assets/SVG/assessmentHome/round5.svg";
import rightSideMainImage from "../Assets/assessment-home/assessment-1.webp";
import content1 from "../Assets/SVG/assessmentHome/work-freedom.svg";
import content2 from "../Assets/SVG/assessmentHome/work-balance.svg";
import content3 from "../Assets/SVG/assessmentHome/quality.svg";
import person1 from "../Assets/assessment-home/person1.png";
import person2 from "../Assets/assessment-home/person2.png";
import person3 from "../Assets/assessment-home/person3.png";

export const AssessmentHomeData = {
  title: "Navigate Your Vetting Journey",
  desc: [
    "Explore your progress and upcoming steps as you embark on the vetting process.",
    "Take the test, solve coding challenges and showcase your expertise to unlock exciting projects.",
    "These tests aim to evaluate your technical expertise, problem solving skills & interpersonal abilities.",
    "Assessment rounds can be attempted either separately or in a single session.",
  ],
  assessmentDetails: [
    {
      heading: "Technical Fundamental Test (TFT)",
      desc: "Multiple choice questions & coding snippets.",
      image: round1Image,
      key: "Round1",
      link: "/assessment/round1",
    },
    {
      heading: "Coding Challenge",
      desc: "Solve a series of coding problems and showcase your coding aptitude.",
      image: round2Image,
      key: "Round2",
      link: "/assessment/gotoRound2",
    },
    {
      heading: "HR Discussion",
      desc: "Demonstrate your cultural fit and interpersonal strengths in the HR Assessment Interview.",
      image: round3Image,
      key: "Round3",
      link: "/assessment/round3",
    },
    {
      heading: "Practical Assignment",
      heading2: "Case Study",
      desc: "Showcase your coding proficiency through a hands-on  project assessment that assesses your problem-solving skills and  capabilities.",
      desc2: "Exhibit your data science proficiency with practical case studies, evaluating problem-solving and coding competencies.",
      image: round4Image,
      key: "Round4",
      link: "/assessment/round4",
    },
    {
      heading: "Expert Discussion",
      desc: "A holistic assessment where we intricately examine your  uploaded project, evaluate your technical expertise, and recognise your coding and problem-solving skills.",
      image: round5Image,
      key: "Round5",
      link: "/assessment/round5",
    },
  ],
  rightData: {
    part1: {
      text1: "Hey there,",
      text2: "You are half way to your dream job.",
      text3:
        "Embrace this journey with determination and unwavering ambition!!",
      img1: rightSideMainImage,
    },
    part2: [
      {
        img: content1,
        text: "Get the Freedom to work",
        desc: `“Embrace autonomy and unleash productivity with the freedom to work flexibly and efficiently, empowering innovation and fulfillment.”`,
        // personImg: person1,
        // personName: "Alex Neil",
      },
      {
        img: content2,
        text: "Work Life Balance",
        desc: `“Strike harmony between career and 
personal fulfillment with a balanced 
approach to work and life, nurturing 
well-being and productivity in equal 
measure.”`,
        // personImg: person2,
        // personName: "Sarah Jones",
      },
      {
        img: content3,
        text: "Quality of Work",
        desc: `“Embrace a culture of excellence where 
the quality of work is paramount, driving 
continuous improvement and lasting 
success.”`,
        // personImg: person3,
        // personName: "Robert Edward",
      },
      // {
      //   img: content2,
      //   text: "Work Life Balance part2",
      //   desc: "“Lorem ipsum is a long established fact that a reader will be distracted the readable content of a page when looking at its layout.”",
      //   personImg: person1,
      //   personName: "Alex Neil4",
      // },
    ],
  },
  failureMessage:
    "Well, shucks! Your current attempt missed the qualification cut. No sweat—practice your moves and reapply after",
  unethicalMessage:
    "Pause alert! Unethical antics crashed the assessment party. Contact support for a quick comeback plan.",
};

// const ar1 = [1, 2];

// const ar2 = [
//   { val: 1 },
//   { val: 2 },
//   { val: 3 },
//   { val: 4 },
//   { val: 5 }
// ];

// let notFound = true;

// ar2.forEach((item) => {
//   if (ar1.includes(item.val)) {
//     item.present = "yes";
//   } else if (notFound) {
//     item.present = "no";
//     notFound = false;
//   }
// });
