export const round3Instructions = [
  {
    title: "Round 3 Instructions",
    desc: [
      {
        desc: [
          "Please ensure that your latest <span>resume</span> is uploaded in the profile section before the interview.",
          "Please log in 5 minutes early for the meeting.",
          "Choose a quiet and well-lit space for the interview.",
          "Ensure there's a neutral background to minimize distractions.",
          "Check your webcam, microphone, and internet connection to ensure they are working properly.",
          "Please dress appropriately for the interview.",
          "Turn off notifications on your computer and mobile devices to avoid any distractions.",
        ],
      },
    ],
  },
  {
    title: "General Instructions",
    desc: [
      {
        subtitle: "Before you start the test",
        desc: [
          "Make sure you have a good Internet connection.",
          "Requirement Use Latest version of Chrome/Safari.",
          // "Use Next and Back to move between the questions. Use Submit submitting of all answers for review",
          // "Once each test round is started, it must be completed in one sitting, you cannot abort and resume later ",
        ],
      },
    ],
  },
];
