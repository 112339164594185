import React, { useEffect, useState } from 'react'
import { getDetailList, getShortlisted } from '../../Common/Client';
import CandidateCardList from '../../Components/ClientPage/CandidateCard/CandidateCardList/CandidateCardList';
import CandidatesNotFound from '../../Components/ClientPage/CandidatesNotFound/CandidatesNotFound';
import { Typography } from '@mui/material';
import ChatBot from '../../Components/ClientPage/ChatBot/ChatBot';
const ShortlistCandidates = () => {
  const [candidateList,setCandidateList]=useState([]);
  const getList=async(data)=>{
    let ans=[]
    for(let item of data){
      ans.push({
        name:item
      })
    }
    return ans
  }
  const handleAddShortList = (candidateList, shortlisted) => {
    // Iterate over the candidateList and update the `shortlist` property based on whether email is in the shortlisted array
    const updatedList = candidateList.map(candidate => {
      if (shortlisted.includes(candidate.email)) {
        return { ...candidate, shortlist: true }; // Set shortlist to true if email is in shortlisted
      }
      return { ...candidate, shortlist: false }; // Set shortlist to false otherwise
    });
  
    return updatedList; // Return the updated candidates list
  };
  useEffect(()=>{
    const apiCall=async()=>{
      try{
        const data={
          email:localStorage.getItem("email")
        }
        const res= await getShortlisted(data)
       let obj=await getList(res)
       const resShortlisted=await getShortlisted(data)
       const r={
        requests:obj,
        clientId:localStorage.getItem("email")
       }
       const details=await getDetailList(r)
       const finalData=await handleAddShortList(details,res);
       
        setCandidateList(finalData || [])

      }catch(e){

      }
    }

    apiCall();
  },[])
  const handleAddShortListToggle = (candidatesList, developerEmail) => {
    // Filter out the candidate whose email matches the developerEmail
    const updatedList = candidatesList.filter(candidate => candidate.email !== developerEmail);
    setCandidateList(updatedList)
  
    return updatedList; // Return the updated candidates list with the matched candidate removed
  };
  return (
    <>
    <ChatBot   />
    <Typography
  sx={{
    color: '#212121',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop:'50px',
    marginLeft:'30px'
  }}
>
  Shortlisted Profiles
</Typography>

   {candidateList?.length!==0?<CandidateCardList  handleAddShortListToggle={handleAddShortListToggle} candidatesList={candidateList ||[]}/>:<div><CandidatesNotFound  message={"Unfortunately, you have not shortlisted any profile."}/></div>}
   </>
  )
}

export default ShortlistCandidates