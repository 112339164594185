import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, CircularProgress } from '@mui/material';

function CalendlyDialog({ calendaropen, hanldeClose }) {
  const [open, setOpen] = useState(false);
  const [showIframe, setShowIframe] = useState(false); // Controls the visibility of the iframe

  useEffect(() => {
    setOpen(calendaropen);
    if (calendaropen) {
      setShowIframe(false); // Initially hide the iframe

      // Set a timer to show the iframe after 2 seconds
      const timer = setTimeout(() => {
        setShowIframe(true);
      }, 2000);

      // Clear timer on component unmount or dialog close
      return () => clearTimeout(timer);
    }
  }, [calendaropen]);

  return (
    <div>
      {/* MUI Dialog */}
      <Dialog open={open} onClose={hanldeClose} fullWidth sx={{ padding: '0px' }} maxWidth="md">
       
        <DialogContent sx={{ padding: '0px' }}>
         
            <iframe
              src="https://calendly.com/anil-kumar-zeero/30min"
              width="100%"
              height="600px"
              frameBorder="0"
              style={{ border: 'none', overflow: 'hidden', height: '500px' }}
              title="Calendly Scheduling"
            ></iframe>
          
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CalendlyDialog;
