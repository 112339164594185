import { useEffect } from "react";
import "./GlobalLoader.scss";
import loaderIcon from "../../../Assets/home/loaderBot.gif"

export const GlobalLoaderZenIcon = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  return (
    <div className="global-loader">
      {/* Replace CircularProgress with your GIF */}
      <img 
        src={loaderIcon}
        alt="Loading..."
        style={{ width: "100px", height: "100px" }} // Adjust size as necessary
      />
    </div>
  );
};