// src/Features.js
import React from "react";
import "./WhatWeOffer.scss";

export const WhatWeOfferHome = ({ features }) => {
  return (
    <div className="features-container">
      <div className="title">{features?.title}</div>
      <div className="data-container">
        {features?.data?.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="text-content">
              <div className="feature-title">{feature.title}</div>
              <div className="feature-description">{feature.description}</div>
            </div>
            <div className="image-content">
              <img
                src={feature.image}
                alt={feature.title}
                className="feature-image"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
